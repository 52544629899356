import { Inject, Injectable } from "@angular/core";
import { HfwFilterPillData } from "@gms-flex/controls";
import { EventFilter, TextEntry } from "@gms-flex/services";
import { AppContextService, TraceService } from "@gms-flex/services-common";
import { enumColumnType, EventListSettingsServiceBase, HeaderData } from "@gms-flex/snapin-common";
import { TranslateService } from "@ngx-translate/core";
import { TreeItem } from "@simpl/element-ng";
import { Observable, of, Subscription } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

@Injectable()
export class EventListSettingsBxSubstituteService extends EventListSettingsServiceBase {
  private displayParams: any;

  // Filter pill label
  private searchTextFilterLabel = ''; // "Search text"

  // column name variables
  private columnNameCause = ''; //  "Event Cause",
  private columnNameCategory = ''; // "Category",
  private columnNameDateTime = ''; // "DateTime",
  private columnNameCommands = ''; // "Commands",
  private columnNameEventStatus = ''; // "Event Status",
  private columnNamePath = ''; // "Path",
  private columnNameSource = ''; // "Source",
  private columnNameBelongsTo = ''; // "Belongs to",
  private columnNameSrcSystemName = ''; // "System Name",
  private columnNameBuildingName = ''; // "Building Name",

  // event state filter variables
  private eventStateUnprocessed = ""; // "Unprocessed";
  private eventStateClosed = ""; // "Closed";

  // source state filter variables
  private sourceStateActive = ""; // "Active";
  private sourceStateQuiet = ""; // "Quiet";

  private readonly userLang: string;

  private readonly subscriptions: Subscription[] = [];

  public constructor(
    @Inject(TranslateService) private readonly translateService: TranslateService,
    private readonly appContextService: AppContextService,
    private readonly traceService: TraceService
  ) {
    super();

    // user culture
    this.userLang = this.translateService.getBrowserLang() ?? this.translateService.defaultLang;

    this.subscriptions.push(
      this.appContextService.defaultCulture.subscribe(
        (defaultCulture: string) => {
          if (defaultCulture !== null) {
            this.translateService.setDefaultLang(defaultCulture);
          } else {
            this.traceService.warn(
              TraceModules.eventListSettings,
              "No default Culture for appContextService"
            );
            this.translateService.setDefaultLang(this.userLang);
          }
        }
      )
    );

    this.subscriptions.push(
      this.appContextService.userCulture.subscribe((userCulture: string) => {
        if (userCulture !== null) {
          this.translateService.use(userCulture).subscribe((res: any) => {
            this.traceService.info(
              TraceModules.eventListSettings,
              "use  user Culture"
            );
          });
        } else {
          this.traceService.warn(
            TraceModules.eventListSettings,
            "No user Culture for appContextService"
          );
        }
      })
    );

    this.subscriptions.push(
      this.translateService
        .get([
          // #region string list
          "EVENTS.SEARCH-TEXT-FILTER-LABEL",
          "EVENTS.DATE-TIME-FILTER-LABEL",
          "EVENTS.TIME-EMPTY-FILTER-LABEL",
          "EVENTS.TIME-LAST-QUARTER-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HALF-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-HOUR-FILTER-LABEL",
          "EVENTS.TIME-LAST-NIGHT-FILTER-LABEL",
          "EVENTS.TIME-YESTERDAY-FILTER-LABEL",
          "EVENTS.TIME-TODAY-FILTER-LABEL",
          "EVENTS.DISCIPLINE-FILTER-LABEL",
          "EVENTS.CATEGORY-FILTER-LABEL",
          "EVENTS.STATE-FILTER-LABEL",
          "EVENTS.SRC-STATE-FILTER-LABEL",
          "EVENTS.SRC-SYSTEM-FILTER-LABEL",
          "EVENTS.HIDDEN-EVENTS-FILTER-LABEL",
          "EVENTS.CONTENT-ACTION-FILTER-LABEL",
          "EVENTS.EVENT-STATE-UNPROCESSED",
          "EVENTS.HIDDEN-EVENTS-SHOW-LABEL",
          "EVENTS.EVENT-STATE-CLOSED",
          "EVENTS.SOURCE-STATE-ACTIVE",
          "EVENTS.SOURCE-STATE-QUIET",
          'EVENTS.COLUMN-NAME-CAUSE',
          'EVENTS.COLUMN-NAME-DESCRIPTION',
          'EVENTS.COLUMN-NAME-NAME',
          'EVENTS.COLUMN-NAME-LOCATION',
          'EVENTS.COLUMN-NAME-DATE-TIME',
          'EVENTS.COLUMN-NAME-COMMANDS',
          'EVENTS.COLUMN-NAME-EVENT-STATUS',
          'EVENTS.COLUMN-NAME-SOURCE-STATUS',
          'EVENTS.COLUMN-CUSTOMIZE-TITLE',
          'EVENTS.COLUMN-CUSTOMIZE-DESCRIPTION',
          'EVENTS.COLUMN-NAME-CATEGORY',
          'EVENTS.COLUMN-NAME-SOURCE',
          'EVENTS.COLUMN-NAME-BELONGSTO',
          'EVENTS.COLUMN-NAME-PATH',
          'EVENTS.COLUMN-NAME-SYSTEM-NAME',
          'EVENTS.COLUMN-NAME-BUILDING-NAME'
        ])
        .subscribe(value => {
          this.searchTextFilterLabel = value['EVENTS.SEARCH-TEXT-FILTER-LABEL'];
          this.eventStateUnprocessed = value["EVENTS.EVENT-STATE-UNPROCESSED"];
          this.eventStateClosed = value["EVENTS.EVENT-STATE-CLOSED"];
          this.sourceStateActive = value["EVENTS.SOURCE-STATE-ACTIVE"];
          this.sourceStateQuiet = value["EVENTS.SOURCE-STATE-QUIET"];
          this.columnNameCause = value['EVENTS.COLUMN-NAME-CAUSE'];
          this.columnNameCategory = value['EVENTS.COLUMN-NAME-CATEGORY'];
          this.columnNameDateTime = value['EVENTS.COLUMN-NAME-DATE-TIME'];
          this.columnNameCommands = value['EVENTS.COLUMN-NAME-COMMANDS'];
          this.columnNameEventStatus = value['EVENTS.COLUMN-NAME-EVENT-STATUS'];
          this.columnNameBelongsTo = value['EVENTS.COLUMN-NAME-BELONGSTO'];
          this.columnNamePath = value['EVENTS.COLUMN-NAME-PATH'];
          this.columnNameSource = value['EVENTS.COLUMN-NAME-SOURCE'];
          this.columnNameSrcSystemName = value['EVENTS.COLUMN-NAME-SYSTEM-NAME'];
          this.columnNameBuildingName = value['EVENTS.COLUMN-NAME-BUILDING-NAME'];
        })
    );

    this.traceService.info(TraceModules.eventListSettings, 'EventListSettingsBxSubstituteService created.');
  }

  public getStateFilter(): any {
    // Event State Tree
    let item: TreeItem;
    const stateTree: TreeItem[] = [];

    item = {
      label: this.eventStateUnprocessed,
      state: "leaf",
      customData: ["Unprocessed"]
    };
    stateTree.push(item);

    item = {
      label: this.eventStateClosed,
      state: "leaf",
      customData: ["Closed"]
    };
    stateTree.push(item);

    return stateTree;
  }

  public getSourceFilter(): any {
    // Event Source State
    let item: TreeItem;
    const srcStateTree: TreeItem[] = [];

    item = {
      label: this.sourceStateActive,
      state: "leaf",
      customData: ["Active"]
    };
    srcStateTree.push(item);

    item = {
      label: this.sourceStateQuiet,
      state: "leaf",
      customData: ["Quiet"]
    };
    srcStateTree.push(item);

    return srcStateTree;
  }

  public getHiddenFilter(): any {
    // Hidden Events
    const hiddenEventsTree: TreeItem[] = [];

    return hiddenEventsTree;
  }

  public getDisciplines(): Observable<TextEntry[]> {
    return of([]);
  }

  public getFilterCriteria(eventFilter: EventFilter, pillDataArr: HfwFilterPillData[]): HfwFilterPillData[] {
    if (eventFilter == null) {
      return pillDataArr;
    }

    if (eventFilter.searchText !== undefined && eventFilter.searchText.length > 0) {
      const values: string[] = [];
      values.push(eventFilter.searchText);
      pillDataArr.push(new HfwFilterPillData(12, this.searchTextFilterLabel, values));
    }
    return pillDataArr;
  }

  public getAllowedFilters(): any {
    const allowedFilterPillData: string[] = [];
    return allowedFilterPillData;
  }

  public getInvestigativeSettings(): any {
    return {
      showAckButton: false,
      showSecondaryAction: false
    }
  }

  public getAvailableColumns(displayParams: any): HeaderData[] {

    this.displayParams = displayParams;
    const hdrData: HeaderData[] = [];

    let newHeaderEntry: HeaderData = {
      id: 'eventIcon',
      label: this.columnNameCategory,
      columnType: enumColumnType.EventIcon,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: 90,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'cause',
      label: this.columnNameCause,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('eventIcon', true),
      minColWidth: displayParams.compactMode ? 80 : 230,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'state',
      label: this.columnNameEventStatus,
      columnType: enumColumnType.State,
      columnVisible: this.setVisibility('state', true),
      minColWidth: displayParams?.compactMode ? 28 : 120,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'creationTime',
      label: this.columnNameDateTime,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('creationTime', true),
      minColWidth: displayParams?.compactMode ? 90 : 160,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);
    
    newHeaderEntry = {
      id: 'buildingName',
      label: this.columnNameBuildingName,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('buildingName', false),
      minColWidth: displayParams?.compactMode ? 90 : 160,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcSource',
      label: this.columnNameSource,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcSource', true),
      minColWidth: displayParams?.isULC ? 250 : 100,
      isFixedSize: displayParams?.isULC ? true : false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'belongsTo',
      label: this.columnNameBelongsTo,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('belongsTo', true),
      minColWidth: 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcPath',
      label: this.columnNamePath,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcPath', false),
      minColWidth: displayParams?.isULC ? 300 : 100,
      isFixedSize: false,
      widthPercentage: 10,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'commands',
      label: this.columnNameCommands,
      columnType: enumColumnType.Buttons,
      columnVisible: this.setVisibility('commands', false),
      minColWidth: 180,
      isFixedSize: true,
      widthPercentage: 10,
      allowSorting: false
    };
    hdrData.push(newHeaderEntry);

    newHeaderEntry = {
      id: 'srcSystemName',
      label: this.columnNameSrcSystemName,
      columnType: enumColumnType.Text,
      columnVisible: this.setVisibility('srcSystemName', false),
      minColWidth: 75,
      isFixedSize: false,
      widthPercentage: 5,
      allowSorting: true
    };
    hdrData.push(newHeaderEntry);

    return hdrData;
  }

  private setVisibility(name: string, defaultVal: boolean): boolean {
    return this.displayParams?.visibleColumns?.length > 0 ? this.displayParams.visibleColumns.includes(name) : defaultVal;
  }
}
