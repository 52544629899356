import { Injectable } from '@angular/core';
import { LicencseOptions, LicenseOptionsProxyServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled } from 'rxjs';

@Injectable()
export class LicenseOptionsBxSubstituteProxyService implements LicenseOptionsProxyServiceBase {

  public constructor(
    private readonly traceService: TraceService) {
    this.traceService.info(TraceModules.license, 'LicenseOptionsBxSubstituteProxyService created.');
  }

  /**
   * Gets all the license-options for the authenticated user.
   */
  public getLicenseOptionsRightsAll(): Observable<LicencseOptions[]> {
    this.traceService.debug(TraceModules.license, 'LicenseOptionsBxSubstituteProxyService.getLicenseOptionsRightsAll() called');

    // TODO
    return scheduled([[
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      { Id: 'sbt_gms_opt_trend', Available: -1, Description: 'Trend Application', Remaining: -1, Required: 0 },
      /* eslint-disable-next-line @typescript-eslint/naming-convention */
      { Id: 'sbt_gms_opt_schedule', Available: -1, Description: 'Schedule Application', Remaining: -1, Required: 0 }
    ]], asapScheduler);

    // return scheduled([[]], asapScheduler);
  }
}
