import { Injectable } from "@angular/core";

import { FullSnapInId, IPreselectionService } from "@gms-flex/core";
import { isNullOrUndefined, TraceService } from "@gms-flex/services-common";
import { asapScheduler, forkJoin, Observable, of as observableOf } from "rxjs";
import { ApplicationRight, AppRightsService, BrowserObject, GmsMessageData, LicenseOptionsService } from "@gms-flex/services";

export const traceModule: string = "gmsSnapins_TrendServices";
const trendSnapinId: number = 54;
const trendShowOptId: number = 1728;  // Show Operation ID from WSI

@Injectable()
export class TrendPreselectService implements IPreselectionService {

  public typeId: string = "TrendSnapInType";

  public preselectionReply: boolean = true;

  public preselectionReplyTime: number = 100; // ms

  private appRightsTrend: ApplicationRight;

  constructor(private traceService: TraceService,
              private appRightsService: AppRightsService,
              private licenseOptionsServices: LicenseOptionsService
              ) {
    this.traceService.info(traceModule, "TrendPreselectService created");
  }

  /*
   * This method is called by HFW in order to allow snapins evaluate if it can handle the corresponding message.
   * The snapin shall return an observable 'immediately'!
   * On the returned observable, the snapin shall push the evaluated result; true if it can handle the message, else false.
   * In order to support good user experience that timespan used for evaluation shall be < 100ms.
   * HLDL defines a timeout of 5000ms (configurable). After that time, the result of the callback is not considered anymore.
   * Important: The returned observable must support 'teardownLogic'!!
   * => Any client subscribing to the observable can call 'unsubscribe' on the correponding subscription. This causes the disposal of all underlying resources.
   *
   * @param {Array<string>} messageTypes, the messageTypes.
   * @param {*} messageBody, the messageBody.
   * @param {FullSnapInId} fullId, the snapinId for which the preselection is invoked.
   * @returns {Observable<boolean>}, true if the specified snapin can handle the message, else false.
   *
   * @memberOf TrendPreselectService
   */
  public receivePreSelection(messageTypes: Array<string>, messageBody: any, fullId: FullSnapInId): Observable<boolean> {
    this.traceService.info(traceModule, "receivePreSelection() called from HFW:\nmessageTypes=%s; messageBody=%s; fullId=%s",
      messageTypes.join("-"), JSON.stringify(messageBody), fullId.fullId());
    // return this.getShowRights();

    // Don't show snapin when multiple BrowserObject instances are selected
    let messageData: BrowserObject[] = (messageBody as GmsMessageData).data;
    let managedType: any = this.getManagedTypeName(messageData);
    if(managedType != null) {
      let permissionVisible: boolean;
      forkJoin({
        requestOne: this.getShowRights(),
        requestTwo: this.getLicenseOptionsRight()
      })
        .subscribe(({ requestOne, requestTwo }) => {
          permissionVisible = requestOne && requestTwo;
        });
      return observableOf(permissionVisible);
    } else {
      this.traceService.info(traceModule, "receivePreSelection() for multiple selection. Returning false");
      return observableOf(false, asapScheduler);
    }
   
  }

  private getShowRights(): Observable<boolean> {
    this.appRightsTrend = this.appRightsService.getAppRights(trendSnapinId);
    // check if show rights are available
    return (this.appRightsTrend != null && this.appRightsTrend.Operations.find(appRight => appRight.Id === trendShowOptId))
    ?  observableOf(true)
    :  observableOf(false);
  }

  private getLicenseOptionsRight(): Observable<boolean> {
    const licensOptionsDocument = this.licenseOptionsServices.getLicenseOptionsRights('sbt_gms_opt_trend');
    if (!isNullOrUndefined(licensOptionsDocument)) {
      if (licensOptionsDocument.Available === -1) {
        return observableOf(true);
      } else if (licensOptionsDocument!.Available === 0) {
        return observableOf(false);
      } else {
      // required <= (assigned)
        return licensOptionsDocument.Required <= (licensOptionsDocument.Available) ?  observableOf(true) : observableOf(false);
      }
    }
      return observableOf(false);
  }

   /**
     *
     * @param messageData
     * // Check if multiple browser objects (nodes) have been selected from the system's browser interface.
     */
   private getManagedTypeName(messageData: BrowserObject[]): string | null {
    // for more than one messageData received, its considered as multi selection
    if (messageData != null && messageData.length === 1) {
        return messageData[0].Attributes.ManagedTypeName;
    }
    return null;
 }
}
