import { Injectable } from '@angular/core';
import { AppRights, AppRightsServiceProxyBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asapScheduler, Observable, scheduled } from 'rxjs';

// TODO: This API needs to get refactored to fit on-premise and cloud deployments

@Injectable()
export class AppRightsBxSubstituteProxyService implements AppRightsServiceProxyBase {

  public constructor(
    private readonly traceService: TraceService) {
    this.traceService.info(TraceModules.appRights, 'AppRightsBxSubstituteProxyService created.');
  }

  /**
   * Gets all the application rights for the authenticated user.
   * See also WSI API specification.
   */
  public getAppRightsAll(): Observable<AppRights> {
    this.traceService.debug(TraceModules.appRights, 'AppRightsBxSubstituteProxyService.getAppRightsAll() called');

    // TODO: remove this workaround and consume cloud permissions!
    /* eslint-disable @typescript-eslint/naming-convention */
    return scheduled([{ ApplicationRights: [{
      Name: 'Trends',
      Id: 54,
      Operations: [{ Name: 'Show', Id: 1728 }, { Name: 'Configure', Id: 1730 }]
    },
    {
      Name: 'Schedules',
      Id: 46,
      Operations: [{ Name: 'Show', Id: 1472 }, { Name: 'Configure', Id: 1472 }]
    },
    {
      Name: 'Graphics',
      Id: 18,
      Operations: [{ Name: 'Show', Id: 576 }]
    }] }], asapScheduler);
    /* eslint-enable @typescript-eslint/naming-convention */

    // {
    //   Name: 'Notification',
    //   Id: 1000,
    //   Operations: [{ Name: 'Reno', Id: 32000}, { Name: "Reno Plus", Id: 32001 }]
    // }
  }
}
