import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@building-x/common-ui-ng';
import { Observable, from as observableFrom, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { config } from '../../../assets/app-config';
import { environment } from '../../../environments/environment';
export interface AccountInfo {
  title?: string;
  image?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  protected initialized$ = new ReplaySubject<boolean>(1);
  private readonly signSignOnUrl = config.singleSignOnURL;
  private readonly whiteListURLs = ['login', 'logout'];

  constructor(
    private readonly routerObj: Router,
    @Inject(AuthenticationService) private readonly authService: AuthenticationService
  ) { }

  public initialize(): void {
    const redirectUrl = document.baseURI;
    observableFrom(
      this.authService.init(
        environment.auth.clientId,
        environment.auth.issuer,
        '#/login',
        redirectUrl,
        environment.auth.audience,
        environment.auth.siemensIdBaseUrl
      )
    ).subscribe(() => {
      this.setURLParams();
      if (this.authService.isAuthenticated()) {
        this.initialized$.next(true);
        if (window.location.href.toString().includes('?code=') && !sessionStorage.getItem('entryPointUrl')) {
          this.routerObj.navigate(['/']);
        }
        this.navigateToParam();
      } else if (window.location.href.toString().includes(this.signSignOnUrl)) {
        this.handleSSO();
      } else {
        this.initialized$.next(false);
      }
    });
  }

  public get initialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }

  public requireLogin(): Observable<true> {
    return this.initialized.pipe(
      map<boolean, true>(signedIn => {
        if (!signedIn) {
          throw new Error('User not authenticated');
        }
        return signedIn;
      })
    );
  }

  public get authToken(): Observable<string> {
    return this.requireLogin().pipe(
      map(() => this.authService.accessToken)
    );
  }

  public get accountInfo(): Observable<AccountInfo> {
    return this.requireLogin().pipe(
      map(() => ({
        title: this.authService.identityClaims?.name,
        image: this.authService.identityClaims?.picture
      }))
    );
  }

  public login(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signIn())
    );
  }

  public logout(): Observable<void> {
    return this.initialized.pipe(
      map(() => this.authService.signOut())
    );
  }

  private handleSSO(): void {
    this.authService.loginSSO().subscribe(res => {
      if (res) {
        // logic to sign in user
        this.initialized.pipe(
          filter(signedIn => !!signedIn)).subscribe();
        this.initialized$.next(true);
        this.navigateToParam();
      }
    });
  }

  private setURLParams(): void {
    let param = window.location.hash;
    if (param.length > 1 && !this.whiteListURLs.find(ele => param.includes(`#/${ele}`))) {
      if (param.includes(`#${this.signSignOnUrl}`)) {
        param = param.replace(`#${this.signSignOnUrl}`, '');
      } 
      // else if (param.startsWith('#/')) {
      //   param = param.substring(2);
      // }
      sessionStorage.setItem('entryPointUrl', param);
    }
  }

  private navigateToParam(): void {
    const param = sessionStorage.getItem('entryPointUrl');
    if (param) {
      sessionStorage.removeItem('entryPointUrl');
      this.routerObj.navigateByUrl(param);
    } else {
      this.routerObj.navigate(['/']);
    }

  }
}

@Injectable({
  providedIn: 'root'
})
export class MockSessionService extends SessionService {
  public initialize(): void {
    this.initialized$.next(true);
  }
}
