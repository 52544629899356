import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScheduleServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Observable, of } from 'rxjs';

import { ontology, ScheduleService } from '../../bx-services/schedule';
import { SystemBrowserMapperBxToGmsService } from '../system-browser/system-browser-mapper-bx-to-gms.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleBxSubstituteService extends ScheduleServiceBase {

  /**
   * Constructor
   * @param {TraceService } traceService The trace service
   * @param {HttpClient } httpClient  The http service
   * @param {WsiEndpointService } wsiEndpoint The WSI endpoint service.
   */
  public constructor(
    public traceService: TraceService,
    protected httpClient: HttpClient,
    private readonly scheduleService: ScheduleService,
    private readonly systemBrowserMapper: SystemBrowserMapperBxToGmsService) {
    super();
    this.traceService.info(TraceModules.scheduler, 'scheduler service created.');
  }

  public getSchedulesList(objectId: string): Observable<any> {
    this.traceService.info(TraceModules.scheduler, 'getSchedulesList() called for: ' + objectId);
    const substrings = objectId.split(':');
    const partitionId = substrings[0]

    // return this.scheduleService.getSchedules(partitionId, entityId);
    return of([]);
  }

  public getSchedules(objectId: string): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getSchedules() called for: ' + objectId);

    const substrings = objectId.split(':');
    return this.scheduleService.getSchedule(substrings[0], substrings[1]);
  }

  public getCalendarList(objectId: string): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getCalendarList() called for: ' + objectId);

    return of([]);
  }

  public getCalendarExceptions(calendarObjectIds: string[]): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'getCalendarExceptions() called', calendarObjectIds);
    return of([]);
  }

  public saveSchedule(schedule: any): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'saveSchedule() called for: ' + schedule.Name);
    return of([]);
  }

  public saveExceptions(schedule: any): Observable<any> {

    this.traceService.info(TraceModules.scheduler, 'saveExceptions() called for: ' + schedule.Name);
    return of([]);
  }

  public saveScheduleOptions(schedule: any): Observable<any> {
    this.traceService.info(TraceModules.scheduler, 'saveScheduleOptions() called for: ' + schedule.Name);
    return of([]);
  }
}
