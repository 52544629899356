import { SelectOption } from "@simpl/element-ng";
import { DccDataType, PointTypeService } from "src/app/bx-gms-mapper/properties/point-type.service";
import { PointBxWithValue } from "src/app/bx-services/point/point.model";

const binaryValOrder: Map<number, string> = new Map([
  [0, "false"],
  [1, "true"]
]);

export const getStatesOptions = (pointTypeService: PointTypeService, point: PointBxWithValue): SelectOption[] => {
  const currentType = pointTypeService.getDccType(point.attributes);
  // get type to distinguish multistate (ExtendedEnum) from binary (setting true or false from binaryValOrder)

  if (point?.attributes?.enum) {
    const entries = Object.entries(point?.attributes?.enum);
    const options: SelectOption[] = [];
    entries.forEach(([key, value], index) => {
      options.push({
        id: currentType === DccDataType.ExtendedEnum ? key : binaryValOrder.get(index)!,
        title: value?.label
      });
    });
    return options;
  }

  if (currentType !== DccDataType.ExtendedEnum) {
    return [
      { id: "true", title: "True" },
      { id: "false", title: "False" }
    ];
  }

  return [];
}
