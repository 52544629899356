
import { asapScheduler, asyncScheduler, firstValueFrom, fromEvent, interval, Observable, of as observableOf, observeOn, Subscription, take } from "rxjs";
import { PlatformLocation } from '@angular/common';
import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, input,
  NgZone, OnDestroy, OnInit, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren
} from "@angular/core";
import { DomSanitizer, SafeStyle, SafeUrl } from "@angular/platform-browser";
import { AppContextService, isNullOrUndefined, NotifConfiguration, NotificationServiceBase, SessionCookieStorage } from "@gms-flex/services-common";
import { FrameInfo, FullPaneId, FullQParamId, FullSnapInId, IHfwMessage, ISnapInConfig, MessageParameters, MobileNavigationService, QParam, SnapInBase } from "@gms-flex/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";
import {
  AppSettings, AppSettingsService, ProductInfo, ProductService,
  SettingsServiceBase, TraceService
} from "@gms-flex/services-common";
import { TraceModules } from "../shared/trace-modules";
import {
  AutoTreatStruct, AutomaticTreatmentData, Category, CategoryService, EventColors, EventCounterList, EventCounterServiceBase,
  EventDateTimeFilterValues, EventFilter, EventMessage, EventMessageType, EventService, EventSound, EventSoundServiceBase,
  EventStates, EventSubscription, MultiMonitorServiceBase, NavbarCommunicationService, StatusBarState, SystemInfo, GmsMessageData, GmsSelectionType, gmsNoSelectionMessageType, ResoundCategory, FilesService, SystemsServiceBase,
  AppRightsService,
  Operation,
  ApplicationRight
} from "@gms-flex/services";
import { CookieService } from 'ngx-cookie-service';
import { Lamp } from "../shared/data.model";
import { ConfirmationDialogResult, NavbarItem, SiActionDialogService, StatusBarItem } from "@simpl/element-ng";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EventsCommonServiceBase } from "@gms-flex/snapin-common";
import { SummaryBarSnapInServiceBase } from "../services";

const INVESTIGATIVE_MODE_ID = 'investigative';
const DEFAULT_MODE_ID = 'default';
const eventList = 'event-list';
const enum OnNewEvent {
  DoNothing = 'DoNothing',
  OpenEventList = 'OpenEventList',
  StartFastTreatment = 'StartFastTreatment',
  StartInvestigativeTreatment = 'StartInvestigativeTreatment',
  StartAssistedTreatment = 'StartAssistedTreatment'
}

const enum CloseTreatmentWhen {
  EventAcknowledged = 'EventAcknowledged',
  SourceToNormal = 'SourceToNormal',
  EventReset = 'EventReset',
  EventClosed = 'EventClosed',
  Timeout = 'Timeout'
}

const enum OnNewHigherPrioEvent {
  DoNothing = "DoNothing",
  SwitchToNew = "SwitchToNew",
  AskUser = "AskUser"
}
@Component({
  selector: "gms-summary-bar-snapin",
  templateUrl: "./summary-bar-snapin.component.html",
  styleUrl: "../gms-summary-bar-snapin.scss"
})

export class SummaryBarSnapInComponent extends SnapInBase implements OnInit, AfterViewInit, OnDestroy {
  private eventsToSuspend: any[];
  @HostListener("document:click", ["$event"]) public clickSound(event: Event): any {
    if (this.soundLocked === true && this._sound) {
      this._sound.muted = true;
      this._sound.play();
      this.soundLocked = false;
    }
  }

  @HostBinding("class.hfw-flex-container-column") public guardFrame: boolean = true;
  @HostBinding("class.hfw-flex-item-grow") public guardGrow: boolean = true;
  @HostBinding("class.main-page-primary-header") public guardBackground: boolean = true;

  @ViewChildren("lampElement") public lampElements: QueryList<ElementRef>;

  @ViewChild("systemConfiguration", { static: false }) public systemConfigurationDialog: TemplateRef<any>;

  public statusItems: StatusBarItem[] = [];
  public lampCategoryIds: number[] = [];

  public avatarDropdownOpened: boolean = false;

  public notificationNumbers: number = 0;

  public bAction: boolean = true;
  public primaryItems: Array<NavbarItem> = [];
  public extendedNavbarItems: Array<NavbarItem> = [];
  public userDropDown: Array<NavbarItem> = [];
  public helpItems: Array<NavbarItem> = [];
  public userItem: any;

  @ViewChild("audioControl", { static: false }) public audioControl: ElementRef;

  public logoUrl: string;

  public homeLink: string;

  public lamps: Lamp[] = [];

  public soundToPlay: SafeUrl;

  public mute: boolean = false;

  public showTwoRows: boolean;

  public currentFrame: string = "system-manager";

  public extendedCurrentFrame: boolean = true;

  public investigativeMode = false;

  public currentEventId: string = null;

  /* System Configuration properties */

  public systemsInfoMap: Map<string, SystemInfo> = new Map<string, SystemInfo>();

  public endpoint: string;

  public csid: string;

  public userDefinedName: string;

  public name: string;

  public showSuccess: boolean = false;

  public downloadEnabled: boolean;

  public webHelp: string;

  public settings: AppSettings;

  public showTermsAndConditions: boolean;

  public modalRef: BsModalRef;

  public isError: boolean = false;

  public statusMessage: string;

  public disableUserDefinedNameBox: boolean = false;

  /*Mindsphere backlink properties */
  public backLinkUrl: string;
  public labels: Map<string, string> = new Map<string, string>();
  public enableNotifications: boolean = true;
  public timer: string = "";
  public enableCollapsing: boolean = true;
  public statusBarState: StatusBarState = StatusBarState.Expanded;
  public statusBarStateEnum: any = StatusBarState;
  public showStatusBarStateSelector: boolean = true;
  public _eventFilter: EventFilter = new EventFilter(false);
  public frameInfo: FrameInfo = null;
  public isMobile: boolean;
  public isHandleSummaryBar: boolean;

  /* binding to Resound button for UL/ULC*/
  public isULResoundVisible: boolean = false;
  public categoryResound: string[] = [];

  /* System Configuration properties */
  private _settingsId: string = "SummaryBar_CollapseSettings";
  private subscriptions: Subscription[] = [];
  private lampSubscription: Subscription;
  private lampButtonSubscriptions: Subscription[] = [];
  private eventSoundSubscription: Subscription;
  private eventCategorySubscription: Subscription;
  private soundLocked: boolean = true;
  private lampsMap: Map<number, Lamp> = new Map<number, Lamp>();
  private lampBlinkOn: boolean = false;
  private currentSafeSound: SafeUrl;
  private currentSound: string;
  private savedSound: string;
  private savedSafeSound: SafeUrl;
  private savedSoundToPlay: SafeUrl;
  private firstSound: boolean = true;
  private visibleCategoryLamps: number[] = [];
  private defaultCategoryLamp: number = 0;
  private totalCategories: number = 0;
  private muteSoundFor: string = "EntireSession";
  private IsCollapsed: boolean = false;
  private hiddenEvents: boolean = false;
  private _enableCategories: string[] = [];
  private _sound: HTMLAudioElement = new Audio();
  private currentResound: string;
  private categorySounds: Map<string, string> = new Map<string, string>();
  private ackSound: boolean = false;
  private totalCount: number = 0;
  private _eventsWithTimer: any[] = [];
  private serverOffset: number = 0;
  private interval: any;
  private earliestEvent: any;
  private disableEventGrouping: boolean = false;
  private unprocessedCount: number = 0;
  private resoundCategory: any = undefined;
  private eventSubscription: EventSubscription;
  private timerEventSubscription: EventSubscription;
  private atsCd_Obs: any = undefined;
  private assistedTreatmentRights = true;
  private appRightsAT: ApplicationRight;
  private readonly ATRightsId = 74;
  private readonly ATShowRights = 2368;
  private readonly disableCommands = false;

  /*resound UL/ULC*/
  private categoriesMap: Map<number, string> = new Map<number, string>();

  /*System Configuration*/
  private corporateInformation: any;
  private muteSoundTimer: any;
  private canBeChangedByOutside = true;
  private domainName: string;
  private pathName: string;
  private availableModes: string[];

  /*Assisted Treatment*/
  private currentATS : AutoTreatStruct = undefined;

  public currentModeId: string;
  public isInInvestigativeMode = false;
  public isInAssistedMode = false;

  /* Flag indicating if buzzer button is visible*/
  public buzzerVisible: boolean

  /**
   * Constructor
   * @param {DomSanitizer} sanitizer
   * @param {TraceService} traceService
   * @param {EventCounterServiceBase} eventCounterService
   * @param {CategoryService} categoryService
   * @param {EventSoundServiceBase} eventSoundService
   * @param {MessageBroker} messageBroker
   * @param {activatedRoute} activatedRoute
   */
  public constructor(
    private sanitizer: DomSanitizer,
    private productService: ProductService,
    private traceService: TraceService,
    private eventCounterService: EventCounterServiceBase,
    private categoryService: CategoryService,
    private eventSoundService: EventSoundServiceBase,
    private eventService: EventService,
    private notificationService: NotificationServiceBase,
    messageBroker: IHfwMessage,
    activatedRoute: ActivatedRoute,
    private snapinConfig: ISnapInConfig,
    private renderer: Renderer2,
    private appSettingsService: AppSettingsService,
    private settingsService: SettingsServiceBase,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private navBarService: NavbarCommunicationService,
    private translateService: TranslateService,
    private appContextService: AppContextService,
    private cookieService: CookieService,
    private platformLocation: PlatformLocation,
    private multiMonitorService: MultiMonitorServiceBase,
    private eventsCommonService: EventsCommonServiceBase,
    private filesService: FilesService,
    private readonly siModal: SiActionDialogService,
    private systemsservice: SystemsServiceBase,
    private mobileNavigationService: MobileNavigationService,
    private readonly appRightsService: AppRightsService,
    private readonly summaryBarSnapInService: SummaryBarSnapInServiceBase,
    private changeDetector: ChangeDetectorRef) {
    super(messageBroker, activatedRoute);
  }

  public ngOnInit(): void {
    // Save flag indicating if buzzer button is visible
    // In BX is always be hidden
    this.buzzerVisible = this.summaryBarSnapInService.buzzerVisible();
    this.changeDetector.detectChanges();

    // used for keep aligned the cookies based on the couple domain + path
    // that could change based on the URL
    this.pathName = this.cookieService.get(SessionCookieStorage.PathName) === '' ? '/' : this.cookieService.get(SessionCookieStorage.PathName);
    this.domainName = this.cookieService.get(SessionCookieStorage.DomainName);

    this.checkMode();

    this.subscriptions.push(
      this.getATAppRights().subscribe(res => {
        this.assistedTreatmentRights = res;
      }));

    // Auto close assisted treatment when going into mobile view
    this.isMobile = this.mobileNavigationService.mobileOnlyVisibilityLast;

    this.subscriptions.push(this.mobileNavigationService.mobileOnlyVisibility$.subscribe((isVisible: boolean) => {
      this.isMobile = isVisible;
      if (this.isMobile && this.isInAssistedMode) {
        this.autoCloseAssistedTreatment(this.eventsCommonService.treatedEvent);
      }
      this.handleMobileStatusBar();
    }));

    this.subscriptions.push(this.mobileNavigationService.handleSummaryBar$.subscribe(handleSummaryBar => {
      this.isHandleSummaryBar = handleSummaryBar;
      this.handleMobileStatusBar();
    }));
    this.subscriptions.push(this.navBarService.getStatusBarState().subscribe(state => {
      if (state) {
        this.statusBarState = state;
      }
    }));
    this.eventService.addConsumer();

    this.subscribeToAutomaticTreatment();

    this.productService.getProductSettings().subscribe((product: ProductInfo) => {
      this.logoUrl = product.navigationBarLogo;
      this.corporateInformation = product.corporateLink;
    });
    this.settings = this.appSettingsService.getAppSettingsValue();
    this.webHelp = document.baseURI + "webhelp";

    // load notification sound
    this._sound.src = "@gms-flex/services/assets/notification.wav";
    this._sound.load();

    this.userDropDown.push(
      { title: "-" }
    );

    this.categoryService.getCategories().subscribe(response => this.onGetCategories(response),
      error => this.onGetCategoriesError(error));

    this.eventSubscription = this.eventService.createEventSubscription(this._eventFilter);
    const timerEventFilter: EventFilter = new EventFilter(false);
    timerEventFilter.states = ["UnprocessedWithTimer", "ReadyToBeResetWithTimer"];
    this.timerEventSubscription = this.eventService.createEventSubscription(timerEventFilter, true);

    this.subscriptions.push(this.eventSubscription.filter.subscribe(eventFilter => {
      this._eventFilter = eventFilter;
      if (eventFilter.hiddenEvents !== undefined) {
        if (this.hiddenEvents !== eventFilter.hiddenEvents) {
          this.refreshEventSubscription(eventFilter.hiddenEvents);
        }
        this.hiddenEvents = eventFilter.hiddenEvents;
      }
      if (!eventFilter.empty) {
        if (this.investigativeMode) {
          this.leaveTreatment();
        } else if (this.isInAssistedMode) {
          this.autoCloseAssistedTreatment(this.eventsCommonService.treatedEvent);
        } else {
          this.openEventList();
        }
      }
    }));

    this.subscriptions.push(this.timerEventSubscription.events.subscribe(values => this.onTimerNotification(values)));

    this.subscriptions.push(this.eventSubscription.events.subscribe(values => this.onEventsNotification(values)));

    this.ngZone.runOutsideAngular(() => {
      this.lampSubscription = this.eventService.getBlinker().subscribe(value => {
        this.lampBlinkOn = value;
        this.cd.detectChanges();
      });
    });

    this.subscriptions.push(this.appContextService.defaultCulture.subscribe((defaultCulture: string) => {
      if (defaultCulture !== null) {
        this.translateService.setDefaultLang(defaultCulture);
      } else {
        this.traceService.warn(TraceModules.summaryBar, 'No default Culture for appContextService');
        this.translateService.setDefaultLang(this.translateService.getBrowserLang());
      }
    }));

    this.subscriptions.push(this.appContextService.userCulture.subscribe((userCulture: string) => {
      if (userCulture !== null) {
        this.translateService.use(userCulture).subscribe((res: any) => {
          this.traceService.info(TraceModules.summaryBar, 'use  user Culture');
        });
      } else {
        this.traceService.warn(TraceModules.summaryBar, 'No user Culture for appContextService');
      }
    }));

    // Get translations for the labels
    this.setTranslation();

    this.setEnableCategories();

    this.availableModes = this.snapinConfig.getAvailableModes();

    this.onGetCurrentModeAndWorkArea();
    // Skip other instruction in case buzzer button is not visible
    if (!this.buzzerVisible) {
      return;
    }
    this.initSoundmanage();
    this.privateManageBuzzerStateForElectron();
  }

  public ngAfterViewInit(): void {

    this.handleMobileStatusBar();

    this.subscriptions.push(
      this.lampElements.changes.subscribe((elements: ElementRef[]) => {
        this.lampButtonSubscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });

        this.ngZone.runOutsideAngular(() => {
          elements.forEach(element => {
            this.lampButtonSubscriptions.push(
              fromEvent(element.nativeElement, "mouseenter").subscribe((event: MouseEvent) => {
                this.setLampGlow(event);
              })
            );

            this.lampButtonSubscriptions.push(
              fromEvent(element.nativeElement, "mouseleave").subscribe((event: MouseEvent) => {
                this.resetLampGlow(event);
              })
            );
          });
        });
      })
    );

  }

  public ngOnDestroy(): void {
    this.eventService.removeConsumer();
    if (this.lampSubscription != undefined) {
      this.lampSubscription.unsubscribe();
    }

    this.eventCounterService.unSubscribeEventCounters();
    this.eventSoundService.unSubscribeEventSound();
    this.subscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    this.lampButtonSubscriptions.forEach((subscription: Subscription) => { if (subscription != null) { subscription.unsubscribe(); } });
    this.traceService.info(TraceModules.summaryBar, "Component destroyed.");
    this.eventSoundSubscription.unsubscribe();
  }

  public onGetCurrentWorkAreaFrameInfo(info) {
    this.frameInfo = info;
  }
  /**
   * Handles the mobile status bar by adding or removing the 'mobile' class
   * to the '.gms-summary-bar' element based on the current device type.
   * This method is responsible for adjusting the appearance of the status
   * bar to ensure optimal display on mobile devices to create space available for the
   * collapsible right-side panel button.
   */
  public handleMobileStatusBar() {
    const summaryBarElement = document.querySelector('.gms-summary-bar');
    if (summaryBarElement) {
      this.isMobile && !this.isHandleSummaryBar ? summaryBarElement.classList.add('mobile') : summaryBarElement.classList.remove('mobile');
    }
  }

  public onGetCurrentMode(mode: any) {
    this.currentEventId = mode.relatedValue;
    this.investigativeMode = (mode.id === INVESTIGATIVE_MODE_ID);
  }

  public leaveTreatment(): void {
    if (this.currentEventId != null) {
      this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, this.frameInfo.id).subscribe((modeChanged: boolean) => {
        this.traceService.debug(TraceModules.summaryBar, 'changeMode() completed. result: %s', modeChanged);
        this.openEventList();
      });
    }

    // Send Close Assisted Treatment Command
    this.eventService.eventCommandById(this.currentEventId, 'suspend', 'investigativetreatment');
  }

  public lampClick(categoryId: number): void {
    const categories: number[] = [];
    categories.push(categoryId);
    this._eventFilter = new EventFilter(false); // to remove any other existing filter
    this._eventFilter.categories = categories;
    this.switchToEventsList(this._eventFilter);
  }

  public lampIsDisabled(lamp: Lamp): boolean {
    if (lamp.eventCounter != null) {
      return (lamp.eventCounter.TotalSubsequentGrouping === 0 ? true : false);
    }
    return null;
  }

  public getLampCursor(lamp: Lamp): string {
    if (lamp.eventCounter != null) {
      return (lamp.eventCounter.TotalSubsequentGrouping === 0 ? "default" : "pointer");
    }
    return "pointer";

  }

  public setLampGlow($event: MouseEvent): void {
    this.renderer.setStyle($event.target, "box-shadow", "0 0 4px 0.5px #AAAAAA");
  }

  public resetLampGlow($event: MouseEvent): void {
    this.renderer.removeStyle($event.target, "box-shadow");
  }

  public clickMuteButton($event: any): void {
    // for UL/ULC while resound button is visible we can't stop sound
    if (this.isULResoundVisible) {
      return;
    }

    this.muteUnmuteSound();
  }


  public muteUnmuteSound(): void {

    this._sound.muted = true;
    if (!this.multiMonitorService.runsInElectron) {
      this.cookiesChanges(SessionCookieStorage.False);
    }
    if (!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager()) {
      this._sound.play();
    }
    if (this.mute) {
      let newSound: boolean = false;
      if (this.soundToPlay && (this.soundToPlay !== this.currentSafeSound)) {
        newSound = true;
      }
      this.soundToPlay = this.currentSafeSound;
      if (this.firstSound) {
        this.cd.detectChanges();
        this.firstSound = false;
      }
      if (!newSound && this.currentSound) {
        if (!this.multiMonitorService.runsInElectron) {
          this.cookiesChanges(SessionCookieStorage.False);
        }
        if (!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager()) {
          this.audioControl.nativeElement.play();
        }
      }
      this.mute = false;
    }
    else {
      if (!this.multiMonitorService.runsInElectron) {
        this.cookiesChanges(SessionCookieStorage.True);
      }
      if (!this.multiMonitorService.runsInElectron || this.multiMonitorService.isMainManager()) {
        this.audioControl.nativeElement.pause();
        this.audioControl.nativeElement.currentTime = 0;
      }
      this.mute = true;
    }

    this.setSynchronizeUiState();
  }

  public subscribeBuzzerState(): void {
    this.subscriptions.push(this.navBarService.getBuzzerState().subscribe(buzzer => {
      if (this.mute !== !buzzer) {
        this.muteUnmuteSound();
      }
    }));
  }

  public getLampStyle(lamp: Lamp): SafeStyle {
    // Note:
    // The color values are safe! They are retrieved via secure Web API connection.
    // GMS web services itself reads them from alarm orchestrator and gets them as a .net color object from the DB.
    return this.sanitizer.bypassSecurityTrustStyle(lamp.getStyle(this.lampBlinkOn));
  }

  public getTextColorStyle(lamp: Lamp): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(lamp.getTextColorStyle(this.lampBlinkOn));
  }

  public switchFrame(frame: string): void {
    this.switchToNextFrame(frame).subscribe((frameChanged: boolean) => {
      this.traceService.info(TraceModules.summaryBar, "switchToNextFrame completed. Result: %s", frameChanged);
    });
  }

  public timerAction(): void {
    if (this._eventsWithTimer.length > 0) {
      this._eventsWithTimer.sort((a, b) => a.originalCreationTime - b.originalCreationTime);
      const filter: EventFilter = {
        empty: false,
        states: ["UnprocessedWithTimer", "ReadyToBeResetWithTimer"]
      };
      this.eventService.setEventsFilter(filter);
    }
  }

  /**
   * click on Resound button
   * @param $event
   */
  public clickResetSoundButton($event: any): void {
    this.eventSoundService.resetResoundTimer();
    this.categoryResound.shift();
    this.currentResound = this.categorySounds.get(this.categoryResound[0]);
    if (this.categoryResound.length === 0) {
      this.isULResoundVisible = false;
      this.audioControl.nativeElement.pause();
      this.audioControl.nativeElement.currentTime = 0;
      this.soundToPlay = this.savedSoundToPlay;
      this.currentSafeSound = this.savedSafeSound;
      this.currentSound = this.savedSound;
      this.resoundCategory = undefined;
    } else {
      this.onResound();
    }
  }

  private setEnableCategories(): void {
    this.subscriptions.push(this.notificationService.subscribeConfigurations().subscribe(res => {
      const configuration = res.get("newEvents");

      if (configuration && configuration.getCustomData()[0]) {
        this._enableCategories = [];
        configuration.getCustomData().forEach(d => {
          if ((d.override === false && configuration.getSound() === true) || (d.override === true && d.data[2].value === true)) {
            this._enableCategories.push(d.id);
          }
        });
      }
    }));
  }
  private initSoundmanage(): void {
    this.soundSubscription();

    this.audioControl.nativeElement.loop = true;

    // this.userItem = { 'title': this.rightItems[0].title };

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      this.muteUnmuteSound();
    }

    this.eventService.serverClientTimeDiff().then(res => {
      this.serverOffset = res;
    });
  }

  private onGetCurrentModeAndWorkArea(): void {
    if (this.availableModes != null) {
      this.subscriptions.push(this.messageBroker.getCurrentWorkAreaFrameInfo().subscribe(info => {
        this.onGetCurrentWorkAreaFrameInfo(info);
      }));
      this.subscriptions.push(this.messageBroker.getCurrentMode().subscribe(mode => {
        this.onGetCurrentMode(mode);
      }));
    }
  }

  privateManageBuzzerStateForElectron(): void {
    if (!this.multiMonitorService?.runsInElectron) {
      const source = interval(1000);
      this.subscriptions.push(source.subscribe((_value: any) => this.sendMuteSound()));
    } else {
      this.subscribeBuzzerState();
    }
  }

  private setSynchronizeUiState(): void {
    if (this.multiMonitorService.runsInElectron) {
      this.multiMonitorService.synchronizeUiState({
        sendToItself: false,
        state: { buzzerEnabled: !this.mute }
      });
    }
  }

  private getATAppRights(): Observable<boolean> {
    this.appRightsAT = this.appRightsService.getAppRights(this.ATRightsId);
    if (this.appRightsAT != null) {
      const showRightAT: Operation[] = this.appRightsAT.Operations.filter(f => f.Id === this.ATShowRights);
      return (showRightAT.length > 0) ? observableOf(true) : observableOf(false);
    } else {
      return observableOf(false);
    }
  }

  private setTranslation(): void {
    try {
        this.translateService.get([
          "EVENTS-AT-FAILURE-DLG-TITLE", "EVENTS-AT-FAILURE-DLG-MESSAGE", "EVENTS-AT-FAILURE-DLG-CONFIRM", "EVENTS-ALL-OK",
          "EVENTS-TIMER-TEXT", "EVENTS-TIMER-ACTION", "EVENTS-TIMER-EXPIRED", "EVENTS-TIMER-ESCALATED", "EVENTS-DIALOG-TITLE", "EVENTS-DIALOG-TEXT"])
          .subscribe(strings => {
        this.labels.set("timer-text", strings["EVENTS-TIMER-TEXT"]);
        this.labels.set("timer-action", strings["EVENTS-TIMER-ACTION"]);
        this.labels.set("timer-expired", strings["EVENTS-TIMER-EXPIRED"]);
        this.labels.set("timer-escalated", strings["EVENTS-TIMER-ESCALATED"]);
        this.labels.set("dialog-title", strings["EVENTS-DIALOG-TITLE"]);
        this.labels.set("dialog-text", strings["EVENTS-DIALOG-TEXT"]);
        this.labels.set("dialog-exit", strings["MODAL-BTN-CONFIRM"]);
        this.labels.set("dialog-cancel", strings["MODAL-BTN-CANCEL"]);
        this.labels.set("at-dialog-title", strings["EVENTS-AT-FAILURE-DLG-TITLE"]);
        this.labels.set("at-dialog-message", strings["EVENTS-AT-FAILURE-DLG-MESSAGE"]);
        this.labels.set("at-dialog-confirm", strings["EVENTS-AT-FAILURE-DLG-CONFIRM"]);
        this.labels.set("at-dialog-cancel", strings["MODAL-BTN-CANCEL"]);
        this.labels.set("allOk", strings["EVENTS-ALL-OK"]);
      })
    } catch (error) {
      this.traceService.error(TraceModules.summaryBar, "Translation strings are not set due to an error: %s", error);
    }
  }

  private updateEventsWithTimer(events: any): void {
    events.forEach(event => {
      if (this.isTimerRunning(event)) {
        if (event.originalState != "Closed" &&
          event.originalState !== "WaitingOPCompletion" &&
          event.originalState !== "ReadyToBeClosed") {
          this.addOrUpdateEventWithTimer(event);
        }
        else {
          this.removeEventWithTimer(event);
        }
      }
      else {
        this.removeEventWithTimer(event);
      }
    });

    this.getEarliestTimer();
  }

  /**
   * Switch to the event-list
   * filter events-list
   * Check if is running in electron
   *
   * @param eventFilter
   */
  private switchToEventsList(eventFilter: EventFilter): void {
    if (this.multiMonitorService.runsInElectron && !this.multiMonitorService.isMainManager() && this.multiMonitorService.isManagerWithEvent()) {
      // In electron with detached event manager
      const evtMessage: EventMessage = {
        type: EventMessageType.EventFiltering,
        data: eventFilter
      };
      this.multiMonitorService.sendEvent(evtMessage);
    } else {
      // web app or electron with the events list
      this.eventService.setEventsFilter(eventFilter);
    }
  }

  private isTimerRunning(event: any): boolean {
    return Date.parse(event.timerUtc) > 0;
  }

  private addOrUpdateEventWithTimer(event: any): void {
    const index: number = this._eventsWithTimer.findIndex(e => e.eventId == event.eventId);
    if (index < 0) {
      this._eventsWithTimer.push(event);
    }
    else {
      this._eventsWithTimer[index] = event;
    }
  }

  private removeEventWithTimer(event: any): void {
    const toRemove: number = this._eventsWithTimer.findIndex(e => e.eventId === event.eventId);
    if (toRemove >= 0) {
      this._eventsWithTimer.splice(toRemove, 1);
    }
  }
  private onEventsNotification(events: any): void {
    this.updateEventsWithTimer(events);
    let currentATS_stateId = this.currentATS?.event?.stateId;

    // set category sounds
    events.forEach(evt => {
      if (currentATS_stateId === EventStates.Closed && this.currentATS?.event?.id === evt.id) {
        this.dismissAtDlg();
      }
      this.categorySounds.set(this.categoriesMap.get(evt.categoryId), evt.sound);
    })

    // automatic exit from investigative and assisted treatment
    if (!isNullOrUndefined(this.eventsCommonService.treatedEvent)) {
      const currTreatedEvt: any = events.find(evt => evt.id === this.eventsCommonService.treatedEvent.id);
      if (!isNullOrUndefined(currTreatedEvt) || !isNullOrUndefined(currTreatedEvt.automaticTreatmentData)) {
        switch (currTreatedEvt.automaticTreatmentData.CloseTreatmentWhen) {
          case CloseTreatmentWhen.EventAcknowledged:
            if (currTreatedEvt.stateId !== this.eventsCommonService.treatedEvent.stateId &&
              this.eventsCommonService.treatedEvent.stateId === EventStates.Unprocessed) {
              if (this.isInInvestigativeMode) {
                this.eventsCommonService.exitFromInvestigativeTreatment(currTreatedEvt);
              } else if (this.isInAssistedMode) {
                this.autoCloseAssistedTreatment(currTreatedEvt);
              }
              this.eventsCommonService.treatedEvent = undefined;
            }
            break;
          case CloseTreatmentWhen.EventClosed:
            if (currTreatedEvt.stateId !== this.eventsCommonService.treatedEvent.stateId &&
              currTreatedEvt.stateId === EventStates.Closed) {
              if (this.isInInvestigativeMode) {
                this.eventsCommonService.exitFromInvestigativeTreatment(currTreatedEvt);
              } else if (this.isInAssistedMode) {
                this.autoCloseAssistedTreatment(currTreatedEvt);
              }
              this.eventsCommonService.treatedEvent = undefined;
            }
            break;
          case CloseTreatmentWhen.EventReset:
            if (currTreatedEvt.stateId !== this.eventsCommonService.treatedEvent.stateId &&
              (this.eventsCommonService.treatedEvent.stateId === EventStates.ReadyToBeReset ||
                // single event
                currTreatedEvt.stateId === EventStates.WaitingOPCompletion ||
                currTreatedEvt.stateId === EventStates.ReadyToBeClosed ||
              // if you reset an event, the event closes, with a closed state
              currTreatedEvt.stateId === EventStates.Closed)) {
              if (this.isInInvestigativeMode) {
                this.eventsCommonService.exitFromInvestigativeTreatment(currTreatedEvt);
              } else if (this.isInAssistedMode) {
                this.autoCloseAssistedTreatment(currTreatedEvt);
              }
              this.eventsCommonService.treatedEvent = undefined;
            }
            break;
          case CloseTreatmentWhen.SourceToNormal:
            if (currTreatedEvt.srcState === "Quiet" &&
              this.eventsCommonService.treatedEvent.srcState === "Active") {
              if (this.isInInvestigativeMode) {
                this.eventsCommonService.exitFromInvestigativeTreatment(currTreatedEvt);
              } else if (this.isInAssistedMode) {
                this.autoCloseAssistedTreatment(currTreatedEvt);
              }
              this.eventsCommonService.treatedEvent = undefined;
            }
            break;
          case CloseTreatmentWhen.Timeout:
            this.ngZone.runOutsideAngular(
              () => setTimeout(
                () => {
                  if (this.isInInvestigativeMode) {
                    this.eventsCommonService.exitFromInvestigativeTreatment(currTreatedEvt);
                  } else if (this.isInAssistedMode) {
                    this.autoCloseAssistedTreatment(currTreatedEvt);
                  }
                  this.eventsCommonService.treatedEvent = undefined;
                },
                currTreatedEvt.automaticTreatmentData.Timeout * 1000
              )
            );
            break;
          default:
            break;
        }
      }
    }
  }

  private dismissAtDlg(): void {
    this.atsCd_Obs?.unsubscribe();
    this.atsCd_Obs = undefined;
    this.currentATS = null;
  }

  private onTimerNotification(events: any): void {
    this.updateEventsWithTimer(events);
  }

  private getEarliestTimer(): void {
    let earliestTimer: number = 0;
    let earliestExpiration: number = 0;
    const now: number = new Date().getTime() + this.serverOffset;

    this._eventsWithTimer.forEach(event => {
      if (event.timerUtc) {
        const expiration: number = Date.parse(event.timerUtc);
        const timer: number = Math.floor((expiration - now) / 1000);

        if (expiration > 0 && timer > 0 && (earliestTimer === 0 || timer <= earliestTimer)) {
          earliestTimer = timer;
          earliestExpiration = expiration;
          this.earliestEvent = event;
          this.cd.detectChanges();
        }
      }
    });

    clearInterval(this.interval);
    this.ngZone.runOutsideAngular(() => {
      if (earliestTimer > 0) {
        this.interval = setInterval(() => {
          this.timer = this.calculateTimer(earliestExpiration);
          this.cd.detectChanges();
        }, 1000);
      }
      else {
        if (this._eventsWithTimer.length === 0) {
          this.timer = "";
          this.earliestEvent = undefined;
        }
        else {
          this.timer = "Timer expired";
          this.earliestEvent = this._eventsWithTimer[0];
        }
      }
    });
  }

  private calculateTimer(expiration: number): string {
    const now: number = new Date().getTime() + this.serverOffset;
    const timer: number = Math.floor((expiration - now) / 1000);
    if (timer > 60) {
      const minutes: string = String(Math.floor(timer / 60)).padStart(2, "0");
      const seconds: string = String(timer % 60).padStart(2, "0");
      return minutes + ":" + seconds;
    }
    else if (timer <= 60 && timer > 0) {
      return timer + " Seconds";
    }
    else if (timer <= 0) {
      this.getEarliestTimer();
      return "Timer expired";
    }
  }

  private loadSettings(): void {
    this.settingsService.getSettings(this._settingsId).subscribe(
      val => this.onGetSettings(val),
      err => this.onGetSettingsError(err)
    );
  }

  private onGetSettings(settings: string): void {
    if (settings != null) {
      this.statusBarState = <StatusBarState>settings;
    }
    this.navBarService.setStatusBarState(this.statusBarState);
  }

  private onGetSettingsError(error: any): void {
    this.traceService.error(TraceModules.summaryBar, "onGetSettingsError() error: %s", error.toString());
  }

  private getHldlConfigs(): void {
    let hldlConfig: any = undefined;
    hldlConfig = this.snapinConfig.getSnapInHldlConfig(this.fullId, this.location);
    if (hldlConfig != undefined) {
      this.navBarService.setHldlConfig(hldlConfig).subscribe(res => {
        if (res) {
          this.traceService.info(TraceModules.summaryBar, "Sending hldl config to navigation bar.");
        }
      });
      this.enableNotifications = hldlConfig.EnableNotificationsForNewEvents;
      this.enableCollapsing = hldlConfig.EnableCollapsing;
      this.muteSoundFor = hldlConfig.CanMuteSoundFor;
      this.IsCollapsed = hldlConfig.IsCollapsed;
      this.ackSound = hldlConfig.AudioAlertForAckedEvents;

      if (this.enableCollapsing) {
        if (this.IsCollapsed) {
          this.statusBarState = StatusBarState.Collapsed;
        } else {
          this.statusBarState = StatusBarState.Expanded;
        }
        this.loadSettings();

      }
      else {
        this.showStatusBarStateSelector = false;
      }
      if (hldlConfig.CategoryLamps && hldlConfig.CategoryLamps.Visible) {
        this.eventService.visibleCategoryLamps = this.visibleCategoryLamps = hldlConfig.CategoryLamps.Visible;
        this.eventService.defaultCategoryLamp = this.defaultCategoryLamp =
          hldlConfig.CategoryLamps.Default !== null && this.visibleCategoryLamps.length < this.totalCategories &&
            this.visibleCategoryLamps.includes(hldlConfig.CategoryLamps.Default) ? hldlConfig.CategoryLamps.Default : 0;
      }
    }

    // Read event-list hldl settings
    const fullSnapInID = new FullSnapInId(eventList, "el");
    const fullPaneInID = new FullPaneId(eventList, "el-pane");
    const eventListHldlConfig = this.snapinConfig.getSnapInHldlConfig(fullSnapInID, fullPaneInID);

    if (eventListHldlConfig != undefined) {
      this.disableEventGrouping = eventListHldlConfig.disableGroupEvents;
    }
  }

  /* System Configuration methods */

  private soundSubscription(): void {
    this.eventSoundSubscription = this.eventSoundService.eventSoundNotification().subscribe(eventSound => {
      this.onEventSoundChange(eventSound);
    },
      error => {
        if (this.eventSoundSubscription !== undefined) {
          this.eventSoundSubscription.unsubscribe();
        }

        this.audioControl.nativeElement.pause();
        this.audioControl.nativeElement.currentTime = 0;
        this.soundSubscription();
      });
  }

  private onGetCategories(cats: Category[]): void {
    this.traceService.info(TraceModules.summaryBar, "onGetCategories() called.");

    this.totalCategories = cats.length;

    // For UL get categories name to be showing
    cats.forEach((value, index) => {
      this.categoriesMap.set(value.id, value.descriptor);
    });

    this.getHldlConfigs();
    this.eventService.setEnableNotifications(this.enableNotifications);

    cats.forEach((value, index) => {
      if (this.visibleCategoryLamps.length === 0 || this.visibleCategoryLamps.includes(value.id)) {
        const color: string[] = value.colors?.get(EventColors.ButtonGradientDark).split(",");
        const item: StatusBarItem = {
          title: value.descriptor,
          color: value.severity ? undefined : "#" + this.exaConverter(color[0]) + this.exaConverter(color[1]) + this.exaConverter(color[2]),
          value: 0,
          status: value.severity
        };
        const lamp: Lamp = new Lamp(value.id, value.descriptor, value.colors);

        if (value.colors) {
          this.traceService.info(TraceModules.summaryBar, "Lamp: " + value.id + " with text color " + value.colors[EventColors.TextButtonNormal]);
        } else { this.traceService.info(TraceModules.summaryBar, "Lamp: " + value.id + " with text color " + undefined); }
        this.lamps.push(lamp);
        this.statusItems.push(item);
        this.lampCategoryIds.push(value.id);
        this.lampsMap.set(value.id, lamp);
      }
    });

    this.counterSubscription();
    this.eventCounterService.subscribeEventCounters();
  }

  private exaConverter(valueDec: string): string {
    return Number(valueDec).toString(16).padStart(2,'0');
  }

  private refreshEventSubscription(hiddenEvents: boolean): void {
    this.eventCounterService.unSubscribeEventCounters();
    this.eventCounterService.subscribeEventCounters(hiddenEvents);
  }

  private counterSubscription(): void {
    this.eventCategorySubscription = this.eventCounterService.eventCountersNotification().subscribe(
      values => this.onEventCountersNotifications(values),
      error => {
        if (this.eventCategorySubscription !== undefined) {
          this.eventCategorySubscription.unsubscribe();
        }
        if (this.lampsMap !== undefined) {
          this.lampsMap.forEach(lamp => {
            lamp.eventCounter = undefined;
          });
        }
        this.counterSubscription();
      });
  }

  private onGetCategoriesError(error: any): void {
    this.traceService.error(TraceModules.summaryBar, "onGetCategoriesError() error: %s", error.toString());
  }

  private onEventCountersNotifications(data: EventCounterList): void {
    let totalCount: number = 0;
    let unprocessedCount: number = 0;
    let totalSubsequentGrouping: number = 0;
    let unprocessedSubsequentGrouping: number = 0;
    let count: number = 0;

    data.EventCategoryCounters.forEach(value => {
      if (this.lampsMap.has(value.CategoryId) && (this.visibleCategoryLamps.length === 0 || this.visibleCategoryLamps.includes(value.CategoryId))) {
        this.lampsMap.get(value.CategoryId).eventCounter = jQuery.extend(true, {}, value);
      }
      else {
        totalCount += value.TotalCount;
        unprocessedCount += value.UnprocessedCount;
        totalSubsequentGrouping += value.TotalSubsequentGrouping;
        unprocessedSubsequentGrouping += value.UnprocessedSubsequentGrouping;
      }
      if (this.lampsMap.has(value.CategoryId)) {
        count += value.UnprocessedCount;
      }
      this.lampCategoryIds.forEach((id, index) => {
        if (id === value.CategoryId) {
          const totSubsequentGrouping: number = this.disableEventGrouping ? value.TotalCount : value.TotalSubsequentGrouping;
          const totUnprocessed: number = this.disableEventGrouping ? value.UnprocessedCount : value.UnprocessedSubsequentGrouping;
          this.setStatusItem(index, totSubsequentGrouping, totUnprocessed, id);
        }
      });
    });
    this.setDefaultLamp(totalCount, unprocessedCount, totalSubsequentGrouping, unprocessedSubsequentGrouping);

    this.checkBuzzerCount();

    if (this.muteSoundFor === "CurrentUnprocessedEvents" && this.mute && count > this.unprocessedCount) {
      this.muteUnmuteSound();
    }
    this.unprocessedCount = count;

    // stop sound if all unprocessed events are acked
    if (count === 0) {
      this.audioControl.nativeElement.pause();
      this.audioControl.nativeElement.currentTime = 0;
      this.soundToPlay = undefined;
      this.currentSound = undefined;
      this.currentSafeSound = undefined;

      if (!isNullOrUndefined(this.resoundCategory)) {
        this.manageResoundDataUL(this.resoundCategory);
      }
    }
  }

  private setStatusItem(index: number, totSubsequentGrouping: number, totUnprocessed: number, id: number): void {
    this.statusItems[index].value = (totSubsequentGrouping > 0) ? totUnprocessed.toString() + "/" + totSubsequentGrouping.toString() : 0;
    this.statusItems[index].blink = (totUnprocessed > 0);
    if ((totSubsequentGrouping + totUnprocessed) > 0) {
      this.statusItems[index].action = () => this.lampClick(id);
    } else {
      this.statusItems[index].action = null;
    }
  }

  private checkBuzzerCount(): void {
    if (this.ackSound !== undefined && this.ackSound === true) {
      this.eventCounterService.getEventCountersAll().toPromise().then(res => {
        let unprocessed: number = 0;
        let total: number = 0;
        res.EventCategoryCounters.forEach(c => {
          if (this._enableCategories.find(x => parseInt(x, 10) === c.CategoryId)) {
            unprocessed += c.UnprocessedCount;
            total += c.TotalCount;
          }
        });
        if (unprocessed === 0 && total >= 0 && total > this.totalCount) {
          this._sound.muted = false;
          this.cookiesChanges(SessionCookieStorage.False);
          this._sound.play().catch(err => {
            this.traceService.error(err);
          });
        }
        this.totalCount = total;
      });
    }
  }

  private setDefaultLamp(totalCount: number, unprocessedCount: number, totalSubsequentGrouping: number, unprocessedSubsequentGrouping: number): void {
    if (this.visibleCategoryLamps.length > 0 &&
      this.visibleCategoryLamps.length < this.totalCategories &&
      this.defaultCategoryLamp > 0) {
      const defaultLamp: Lamp = this.lampsMap.get(this.defaultCategoryLamp);
      defaultLamp.eventCounter.TotalCount += totalCount;
      defaultLamp.eventCounter.UnprocessedCount += unprocessedCount;
      defaultLamp.eventCounter.TotalSubsequentGrouping += totalSubsequentGrouping;
      defaultLamp.eventCounter.UnprocessedSubsequentGrouping += unprocessedSubsequentGrouping;
    }
  }

  private openEventList(): void {
    this.switchFrame(eventList);
  }

  private blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  private onResound(): void {
    this.systemsservice.getSystems().subscribe(sys => {
      this.filesService.getFile(sys[0].Id, this.currentResound).subscribe(res => {
        this.blobToBase64(res).then((sound: string) => {
          this.currentSound = sound;
          this.currentSafeSound = this.sanitizer.bypassSecurityTrustUrl(sound);

          const newSound: boolean = (this.soundToPlay !== this.currentSafeSound);
          this.soundToPlay = this.currentSafeSound;
          if (newSound && this.currentSound) {
            this.cookiesChanges(SessionCookieStorage.False);
            this.mute = false;
            const playpromise: Promise<any> = this.audioControl.nativeElement.play();
            this.audioControl.nativeElement.loop = true;
            if (playpromise !== undefined) {
              playpromise.catch(err => {
                this.traceService.error(err);
                if (err.code === 0) {
                  this.cookiesChanges(SessionCookieStorage.True);
                  this.audioControl.nativeElement.pause();
                  this.audioControl.nativeElement.currentTime = 0;
                  this.mute = true;
                }
              });
            }
          }
        });
      });
    });
  }

  private onEventSoundChange(eventSound: EventSound): void {
    if (eventSound) {
      this.currentSound = eventSound.sound;
      this.currentSafeSound = this.sanitizer.bypassSecurityTrustUrl(eventSound.sound);

      if (eventSound.sound === undefined) {
        // enable resound if nothing is currently playing
        if (eventSound.resoundData) {
          this.manageResoundDataUL(eventSound.resoundData);
        }
      } else {
        // disable resound if a new unprocessed events arrives
        if (this.isULResoundVisible === true) {
          this.isULResoundVisible = false;
          this.currentResound = undefined;
          this.categoryResound = [];
          this.audioControl.nativeElement.pause();
          this.audioControl.nativeElement.currentTime = 0;
        }

        if (this.mute === false && eventSound.visibility !== 1) {
          const newSound: boolean = (this.soundToPlay !== this.currentSafeSound);
          this.soundToPlay = this.currentSafeSound;
          if (newSound && this.currentSound) {
            this.cookiesChanges(SessionCookieStorage.False);
            const playpromise: Promise<any> = this.audioControl.nativeElement.play();
            if (playpromise !== undefined) {
              playpromise.catch(err => {
                this.traceService.error(err);
                if (err.code === 0) {
                  this.cookiesChanges(SessionCookieStorage.True);
                  this.audioControl.nativeElement.pause();
                  this.audioControl.nativeElement.currentTime = 0;
                  this.mute = true;
                }
              });
            }
          }
        }
      }
    }
  }

  /**
   * UL/ULS resound category management
   * @param resoundCategory
   */
  private manageResoundDataUL(resoundCategory: ResoundCategory[]): void {
    this.resoundCategory = resoundCategory;
    if (this.unprocessedCount === 0 && resoundCategory.length > 0) {
      this.isULResoundVisible = true;
      this.currentResound = this.categorySounds.get(this.categoriesMap.get(resoundCategory[0].Category));
      this.mute = false;

      // Saving unprocessed sound to play it after all resound alarms are toggledby the user
      this.savedSound = this.currentSound;
      this.savedSafeSound = this.currentSafeSound;
      this.savedSoundToPlay = this.soundToPlay;

      this.onResound();
      resoundCategory.forEach(resoundCat => {
        const toInsert = this.categoriesMap.get(resoundCat.Category);
        if (this.categoryResound.findIndex(c => c === toInsert) === -1) {
          this.categoryResound.push(this.categoriesMap.get(resoundCat.Category));
        }
      });
    }
  }

  /**
   * Send the mute sound to cookie service
   */
  private sendMuteSound(): void {
    if (this.canBeChangedByOutside &&
      this.cookieService.check(SessionCookieStorage.Mute) && sessionStorage.getItem(SessionCookieStorage.Mute) && this.cookieService.get(SessionCookieStorage.Mute) !== sessionStorage.getItem(SessionCookieStorage.Mute)) {
      this.muteUnmuteSound();
    }
  }

  /**
   * Atomic code for align sessionstorage and cookies
   */
  private cookiesChanges(muteBooleanString: string): void {
    this.canBeChangedByOutside = false;
    sessionStorage.setItem(SessionCookieStorage.Mute, muteBooleanString);
    if ((!this.cookieService.check(SessionCookieStorage.Mute)) ||
      ((this.cookieService.check(SessionCookieStorage.Mute)) &&
        this.cookieService.get(SessionCookieStorage.Mute) !== sessionStorage.getItem(SessionCookieStorage.Mute))) {
      this.cookieService.set(SessionCookieStorage.Mute, muteBooleanString, { 'path': this.pathName, 'domain': this.domainName });
    }
    this.canBeChangedByOutside = true;
  }

  /**
   * Start logic for Automatic Treatment
   */
  private subscribeToAutomaticTreatment(): void {
    this.subscriptions.push(this.eventService.automaticTreatmentEventObs.subscribe(ats => {
      this.traceService.info(TraceModules.summaryBar, 'subscribe to AutomaticTreatment: ' + ats.event?.automaticTreatmentData?.OnNewEvent);

      this.ngZone.run(() => {
        if (ats.isHigherPrio) {
          this.manageNewHigherPrioEvent(ats);
        } else if (ats.event.automaticTreatmentData.OnNewEvent === OnNewEvent.StartInvestigativeTreatment) {
          this.sendNewEvent(ats);
        } else {
          // do nothing
          // this.sendNewEvent(val);
          this.traceService.debug(TraceModules.summaryBar, 'subscribeToAutomaticTreatment do not start automaticTreatmentEvent');
        }
      });
    },
    error => this.traceService.warn(TraceModules.summaryBar, 'subscribeToAutomaticTreatment has failed: error -> %s', error)));
  }

  private manageNewHigherPrioEvent(ats: AutoTreatStruct): void {
    if (ats.event.automaticTreatmentData.OnNewHigherPrioEvent == OnNewHigherPrioEvent.AskUser && (ats.isEventSelectedInEL || this.isInInvestigativeMode)) {
      if(!isNullOrUndefined(this.eventsCommonService?.treatedEvent)) {
        this.eventsToSuspend = [this.eventsCommonService.treatedEvent];
      }
      // Show Dialog and ask user
      this.siModal.showConfirmationDialog(this.labels.get("dialog-text"), this.labels.get("dialog-title"), this.labels.get("dialog-exit"), this.labels.get("dialog-cancel")).subscribe(confirmation => {
        switch (confirmation) {
          case ConfirmationDialogResult.Confirm:
            this.sendNewEvent(ats);
            break;
          case ConfirmationDialogResult.Decline:
          default:
            this.traceService.info(TraceModules.summaryBar, 'Dialog is declined!');
            break;
        }
      });
    } else if (ats.event.automaticTreatmentData.OnNewHigherPrioEvent == OnNewHigherPrioEvent.SwitchToNew) {
      if(!isNullOrUndefined(this.eventsCommonService?.treatedEvent)) {
        this.eventsToSuspend = [this.eventsCommonService.treatedEvent];
      }
      // When we are in AssistedTreatment, we need first to suspend it.
      if (this.isInAssistedMode) {
        // Send a command to suspend the assisted treatment for the specified event
        this.eventService.eventCommand([this.eventsCommonService.treatedEvent], 'suspend', 'assistedtreatment');

        // Subscribe to the mode change after suspending the assisted treatment
        this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, 'event-list')
        .subscribe((modeChanged: boolean) => {
          // Log the completion of mode change
          this.traceService.debug(TraceModules.summaryBar, 'changeMode() completed. result: %s', modeChanged);

          // Close the assisted treatment by sending an empty array to the event info
          this.eventsCommonService.autoAssistedEvents.next([]);

          // Reset treated event information
          this.eventsCommonService.treatedEvent = undefined;

          // It's FUNDAMENTAL this instruction is executed once we are sure the mode changed.
          // Send message to switch to EL and select event
          this.sendNewEvent(ats);
        });
      } else {
        // Unselect previous event (important in case of subsequents)
        if(!isNullOrUndefined(this.eventsCommonService?.getCachedSelectedEvents()[0])) {
          this.eventService.eventCommand([this.eventsCommonService.getCachedSelectedEvents()[0]], 'suspend');
        }
        this.sendNewEvent(ats);
      }
    } else if (!ats.isEventSelectedInEL) {
      // Do nothing: send message to switch to EL
      this.sendNewEvent(ats);
    }
  }

  private sendNewEvent(ats: AutoTreatStruct): void {
    switch (ats.event.automaticTreatmentData.OnNewEvent) {
      case OnNewEvent.OpenEventList:
        // Send message to switch to EL
        if (this.isInInvestigativeMode) {
          this.leaveInvestigativeAndSwitch(ats.event.automaticTreatmentData.OnNewEvent);
        }
        else { // switch El
          this.openEventList();
        }
        break;
      case OnNewEvent.StartFastTreatment:
        // send message to switch to EL, select event and launch AT
        if (this.isInInvestigativeMode) {
          // investigative mode, close investigative, switch El and select event
          this.leaveInvestigativeAndSwitch(ats.event.automaticTreatmentData.OnNewEvent, ats);
        }
        else { // switch El and select event
          if (ats.isEventSelectedInEL && ats.event.automaticTreatmentData.OnNewHigherPrioEvent === OnNewHigherPrioEvent.DoNothing) {
            this.openEventList();
          } else if (!ats.isEventSelectedInEL || ats.event.automaticTreatmentData.OnNewHigherPrioEvent !== OnNewHigherPrioEvent.DoNothing) {
            this.switchFrameAndSelectEvent(ats);
          }
        }
        break;
      case OnNewEvent.StartInvestigativeTreatment:
        if (this.isInInvestigativeMode) {
          if (ats.isHigherPrio &&
            (ats.event.automaticTreatmentData.OnNewHigherPrioEvent === OnNewHigherPrioEvent.SwitchToNew ||
              ats.event.automaticTreatmentData.OnNewHigherPrioEvent === OnNewHigherPrioEvent.AskUser)) {
            this.eventsCommonService.exitFromInvestigativeTreatment(this.eventsCommonService.treatedEvent);
            // this.leaveInvestigativeAndSwitch(ats.event.automaticTreatmentData.OnNewEvent, ats);
          } else if (ats.event.automaticTreatmentData.OnNewHigherPrioEvent === OnNewHigherPrioEvent.DoNothing) {
            return;
          }
        }
        this.eventsCommonService.goToInvestigativeTreatment(ats.event);
        this.eventsCommonService.treatedEvent = JSON.parse(JSON.stringify(ats.event));
        break;
      case OnNewEvent.StartAssistedTreatment:
        // Check if the device is not mobile
        if (!this.isMobile) {
          // If assisted mode is active
          if (this.isInAssistedMode) {
            // Determine the action based on the priority of the new event
            const higherPrioEvent = ats.event.automaticTreatmentData?.OnNewHigherPrioEvent;
            if (ats.isHigherPrio && (higherPrioEvent === OnNewHigherPrioEvent.SwitchToNew || higherPrioEvent === OnNewHigherPrioEvent.AskUser)) {
              // Close the existing assisted treatment if the new event is of higher priority
              this.autoCloseAssistedTreatment(this.eventsCommonService.treatedEvent);
            } else if (higherPrioEvent === OnNewHigherPrioEvent.DoNothing) {
              // If the new event doesn't require any action, return
              return;
            }
          }

          // If running in Electron and is the main manager
          if (this.multiMonitorService.runsInElectron && this.multiMonitorService.isMainManager() && !this.multiMonitorService.isManagerWithEvent()) {
                break;
          } else {
            // Open assisted treatment if not running in Electron or not the main manager
            if (this.assistedTreatmentRights && !isNullOrUndefined(ats.event.oPId)) {
              this.autoOpenAssistedTreatment(ats);
            } else {
              // Fallback to the investigative treatment if the event doesn't have a valid opID associated
              if (this.isInInvestigativeMode) {
                this.eventsCommonService.exitFromInvestigativeTreatment(this.eventsCommonService.treatedEvent);
              }
              this.eventsCommonService.goToInvestigativeTreatment(ats.event);
              this.eventsCommonService.treatedEvent = JSON.parse(JSON.stringify(ats.event));
            }
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * Automatically closes the assisted treatment for the specified event.
   * @param event - The event for which the assisted treatment should be closed.
   */
  private autoCloseAssistedTreatment(event: any): void {
    // Send a command to suspend the assisted treatment for the specified event
    this.eventService.eventCommand([event], 'suspend', 'assistedtreatment');

    this.eventsToSuspend = [event];

    // Subscribe to the mode change after suspending the assisted treatment
    this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, 'event-list')
      .subscribe((modeChanged: boolean) => {
        // Log the completion of mode change
        this.traceService.debug(TraceModules.summaryBar, 'changeMode() completed. result: %s', modeChanged);

        // Close the assisted treatment by sending an empty array to the event info
        this.eventsCommonService.autoAssistedEvents.next([]);

        // Reset treated event information
        this.eventsCommonService.treatedEvent = undefined;
      });
  }

  /**
   * Automatically opens the assisted treatment based on the provided assisted treatment settings.
   * @param ats - The assisted treatment settings.
   */
  private autoOpenAssistedTreatment(ats: AutoTreatStruct) {

    const eventState = ats.event.stateId;
    // Retrieve the message for event selection
    const message = this.setEventSelectionMsg(ats);

    // If no message is available, return
    if (!message) {
      return;
    }

    if (!this._eventFilter.empty) {
      this._eventFilter = {
        disciplines: [],
        categories: [],
        states: [],
        srcState: [],
        srcAlias: '',
        srcDescriptor: '',
        srcName: '',
        srcSystem: [],
        creationDateTime: EventDateTimeFilterValues.None,
        empty: true
      };
      this.eventService.setEventsFilter(this._eventFilter);
    }

    // Switch to the next frame with the event selection message.
    // We always try to switch frame even if we are in event-list. We are aware this is actually a "fake" switch frame.
    this.switchToNextFrame(eventList, message).subscribe((frameChanged: boolean) => {
      // If the event has been closed in the meanwhile, we don't need to trigger the automatic assisted treatment.
      if (eventState == EventStates.Closed && !ats.event.closedForFilter) {
        return;
      }

      // Ensure to close all pending IT first
      if (this.isInInvestigativeMode) {
        this.eventsCommonService.exitFromInvestigativeTreatment(ats.event);
      }

      // Timeout needed to manage the transition when a frame is switched (from System to Events).
      const timeout = frameChanged ? 2000 : 0;
      setTimeout(() => {

      // Do not trigger AssistedTreatment if the event has been closed in the meanwhile
      if (ats.event.stateId === EventStates.Closed && !ats.event.closedForFilter) {
        return;
      }

      // Call the assisted treatment service to navigate to the assisted treatment
      this.eventsCommonService.goToAssistedTreatment([ats.event]).subscribe(res => {

        if (this.eventsToSuspend?.length > 0) {
          this.eventService.eventCommand(this.eventsToSuspend, 'suspend');
          this.eventsToSuspend = [];
        }

        // If the navigation is successful or there is no WSI error
        if (!res || res.name !== 'WsiError') {
          // Handle successful assisted treatment
          this.traceService.info(TraceModules.summaryBar, 'Accessing AT: call to goToAssistedTreatment returned: %s', res === null ? 'null' : res.name);
          this.handleSuccessfulAssistedTreatment(message, ats);

          // Needed to ensure to suspend if there is any event with set inProcessBy and avoid misalignments
          if (this.eventsCommonService.getCachedSelectedEvents()?.length > 0 &&
            this.eventsCommonService.getCachedSelectedEvents()[0]?.id !== ats.event.id
          ) {
            this.eventService.eventCommand([this.eventsCommonService.getCachedSelectedEvents()[0]], 'suspend');
          }

        }
        // If there is a WSI error
        else if (!isNullOrUndefined(res.name) && (res.name === 'WsiError')) {
          // Handle failed assisted treatment
          this.handleFailedAssistedTreatment(ats, res);
        }
      });
      }, timeout);
    });
  }

  private handleSuccessfulAssistedTreatment(message: any, ats: AutoTreatStruct) {
    if (!isNullOrUndefined(this.atsCd_Obs)) {
      this.dismissAtDlg();
    }
    this.currentATS = ats;
    // Change the mode to assisted treatment mode
    this.messageBroker.changeMode({ id: 'assisted', relatedValue: null }, eventList, message).subscribe((modeChanged: boolean) => {
      // Log the completion of mode change
      this.traceService.debug(TraceModules.summaryBar, 'changeMode() completed. result: %s', modeChanged);
      // Trigger the opening of the assisted treatment view
      this.eventsCommonService.autoAssistedEvents.next([ats.event]);
      // Store the treated event for reference
      this.eventsCommonService.treatedEvent = JSON.parse(JSON.stringify(ats.event));
    });
  }

  /**
   * Handles the failed assisted treatment navigation by logging the error and showing an error modal.
   * @param ats - The assisted treatment settings.
   * @param message - The message for event selection.
   */
  private handleFailedAssistedTreatment(ats: any, res: any) {
    this.ngZone.run(() => {
      // Log the failure to open assisted treatment
      this.traceService.info(TraceModules.summaryBar, 'Failed to open assisted treatment for event ' + ats.event, ' reason: ', res);
      if (this.isInAssistedMode) {
        return;
      } else if (!isNullOrUndefined(this.atsCd_Obs)) {
        this.currentATS = ats;
        return
      }

      this.traceService.info(TraceModules.summaryBar, 'handleFailedAT ---> show Error Dialog');
      this.currentATS = ats;
      this.openATErrorModal(ats);
    });
  }

  /**
   * Opens the error modal for assisted treatment.
   * @param ats - The structure containing auto-treatment settings.
   */
  public openATErrorModal(ats: AutoTreatStruct): void {
    setTimeout(() => {
      // Show a confirmation dialog with error message and options
      this.atsCd_Obs = this.siModal.showConfirmationDialog(
        this.translateService.instant("EVENTS-AT-FAILURE-DLG-MESSAGE").replace('{{otherClient}}', ats.event.getAssistedTreatmentInProcessBy()),
        this.translateService.instant("EVENTS-AT-FAILURE-DLG-TITLE"),
        this.translateService.instant("EVENTS-AT-FAILURE-DLG-CONFIRM"),
        this.translateService.instant("MODAL-BTN-CANCEL")
      ).subscribe(confirmation => {
        // Handle the confirmation result
        this.handleConfirmation(confirmation, ats);
      });
    }, 500);
  }

  private assistedTreatmentInProcessBy(inProcessBy: string): string {
    const allInProcessBy = inProcessBy.split('\\\\');
    let result = allInProcessBy.find(s => s.startsWith('3#'));
    return result?.substring(2, result.length);
  }

  /**
   * Handles the confirmation result of the error modal.
   * @param confirmation - The result of the confirmation dialog.
   * @param ats - The structure containing auto-treatment settings.
   */
  private handleConfirmation(confirmation: ConfirmationDialogResult, ats: AutoTreatStruct): void {
    this.traceService.debug(TraceModules.summaryBar, 'ATErrorModal displayed successfully');

    // Based on the confirmation result, take appropriate actions
    switch (confirmation) {
      case ConfirmationDialogResult.Confirm:
        // Handle the 'Confirm'
        this.handleConfirmationConfirm(ats);
        this.dismissAtDlg();
        break;
      case ConfirmationDialogResult.Decline:
        // Handle the case where user declines the confirmation
        this.dismissAtDlg();
        break;
      default:
        // Handle other cases (if required in the future)
        break;
    }
  }

  /**
   * Handles the confirmation of 'Confirm' from the error modal.
   * @param ats - The structure containing auto-treatment settings.
   */
  private handleConfirmationConfirm(ats: AutoTreatStruct): void {
    // If in investigative mode and auto-treatment data is available
    if (this.isInInvestigativeMode && ats.event.automaticTreatmentData) {
      const onNewHigherPrioEvent = ats.event.automaticTreatmentData.OnNewHigherPrioEvent;
      // If the event is of higher priority and requires action based on auto-treatment data
      if (ats.isHigherPrio && (onNewHigherPrioEvent === OnNewHigherPrioEvent.SwitchToNew || onNewHigherPrioEvent === OnNewHigherPrioEvent.AskUser)) {
        // Exit from the current investigative treatment
        this.eventsCommonService.exitFromInvestigativeTreatment(ats.event);
      } else if (onNewHigherPrioEvent === OnNewHigherPrioEvent.DoNothing) {
        // If no action is needed based on auto-treatment data, return
        return;
      }
    }
    // Navigate to the investigative treatment for the event and store treated event information
    this.eventsCommonService.goToInvestigativeTreatment(this.currentATS.event);
    this.eventsCommonService.treatedEvent = JSON.parse(JSON.stringify(this.currentATS.event));
    this.atsCd_Obs.unsubscribe();
    this.atsCd_Obs = undefined;
  }


  /**
   * Switch to EL frame and select event that I passed
   */
  private switchFrameAndSelectEvent(ats: AutoTreatStruct): void {
    const message = this.setEventSelectionMsg(ats);
    if (message) {
      this.messageBroker.switchToNextFrame(eventList, message).pipe(take(1)).subscribe((frameChanged: boolean) => {
        this.traceService.debug(TraceModules.summaryBar, 'sendMessage() completed. result: %s', frameChanged);
      });
    }
  }

  private setEventSelectionMsg(ats: AutoTreatStruct): MessageParameters | null {
    if (!ats.event?.id) {
      return null;
    }
    const messageBody: GmsMessageData = new GmsMessageData([]);
    const types = [];
    const fullQParamId = new FullQParamId(eventList, 'EventQParamService', 'primary');
    const isGroupedEvent = ats.event?.groupedEvents?.length > 0;
    const qParam: QParam = {
      name: fullQParamId.fullId(),
      // Grouped events query has to contain "*" at the end
      value: ats.event?.id + (isGroupedEvent ? '*' : '')
    };

    const message: MessageParameters = {
      messageBody,
      qParam,
      types
    };
    return message;
  }

  private checkMode(): void {
    this.subscriptions.push(
      this.messageBroker.getCurrentMode().subscribe(mode => {
        this.currentModeId = mode.id;
        this.isInInvestigativeMode = this.currentModeId === INVESTIGATIVE_MODE_ID;
        this.isInAssistedMode = this.currentModeId === 'assisted';

        if (this.isInInvestigativeMode || this.isInAssistedMode) {
          this.statusItems.forEach(lamp => { lamp.action = null; });
        }
        else {
          this.lampCategoryIds.forEach((id, index) => { this.statusItems[index].action = () => this.lampClick(id);
          });
        }
      })
    );
  }

  private leaveInvestigativeAndSwitch(typeOfNewEvent: OnNewEvent, ats?: AutoTreatStruct): void {
    // Send message to switch to EL
    if (this.isInInvestigativeMode) {
      // investigative mode, close investigative, switch El
      this.messageBroker.changeMode({ id: DEFAULT_MODE_ID, relatedValue: null }, eventList).toPromise().then(modeSwitched => {
        switch (typeOfNewEvent) {
          case OnNewEvent.OpenEventList:
            this.openEventList();
            break;
          case OnNewEvent.StartFastTreatment:
            this.switchFrameAndSelectEvent(ats);
            break;
          default:
            break;
        }
      });

      // Send Close Investigative Treatment Command
      this.eventService.eventCommandById(this.currentEventId, 'suspend', 'investigativetreatment');
    }
  }

}


