import { Injectable } from '@angular/core';
import { FullSnapInId, IPreselectionService } from '@gms-flex/core';
import { ApplicationRight, AppRightsService } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { Observable, of as observableOf, of } from 'rxjs';

import { TraceChannel } from '../shared/trace-modules';
export const traceModule: string = TraceChannel.RootServices;

@Injectable()
export class SchedulePreselectService implements IPreselectionService {

  public typeId = 'ScheduleSnapInType';
  public preselectionReply = true;
  public preselectionReplyTime = 50; // ms

  public scheduleAppRight: ApplicationRight;

  constructor(private readonly traceService: TraceService, private readonly appRightService: AppRightsService) {
    if (this.traceService) {
      this.traceService.info(traceModule, 'SchedulePreselectService created');
    }
  }

  public receivePreSelection(messageTypes: string[], messageBody: any, fullId: FullSnapInId): Observable<boolean> {
    this.traceService.info(traceModule, 'receivePreSelection() called from HFW:\nmessageTypes=%s; messageBody=%s; fullId=%s',
      messageTypes.join('-'), JSON.stringify(messageBody), fullId.fullId());
    let result = false;
    result = this.canShowSnapin();
    return of(result);
  }

  private canShowSnapin(): boolean {
    const scheduleSnapinId = 46;
    const schedShowOptId = 1472; // Show Operation ID from WSI

    if (isNullOrUndefined(this.scheduleAppRight)) {
      this.scheduleAppRight = this.appRightService.getAppRights(scheduleSnapinId);
      this.traceService.info(traceModule, 'Fetched Application Rights for schedule');
    } else {
      this.traceService.info(traceModule, 'App rights is already fetched in previous schedule preselection');
    }
    // Case 1: Show is false and Configure is true, then schedule snap in is not visible.
    // Case 2: Show and configure is false
    const result = !isNullOrUndefined(this.scheduleAppRight) &&
    this.scheduleAppRight.Operations.filter(appRight => appRight.Id === schedShowOptId).length > 0;
    return result;
  }
}
