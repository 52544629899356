import { Injectable } from '@angular/core';
import {
  BrowserObject,
  DpIdentifier,
  LocalTextGroupEntry,
  PropertyDetails,
  PropertyInfo,
  PropertyServiceBase,
  SystemBrowserServiceBase,
  TablesServiceBase,
  TraceModules
} from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { asyncScheduler, combineLatest, concatMap, map, Observable, observeOn, of, switchMap, throwError, zip } from 'rxjs';

import { EnumLabel } from '../../bx-services/point/point-proxy.model';
import { PointService } from '../../bx-services/point/point.service';
import { ObjectTypeMapperService } from '../shared/object-type-mapper.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { PropertyMapperBxToGmsService } from './property-mapper-bx-to-gms.service';

@Injectable()
export class PropertyBxSubstituteService extends PropertyServiceBase {

  public constructor(private readonly traceService: TraceService,
    private readonly systemBrowser: SystemBrowserServiceBase,
    private readonly propertyMapper: PropertyMapperBxToGmsService,
    private readonly pointService: PointService,
    private readonly objectTypeMapper: ObjectTypeMapperService,
    private readonly tablesServiceBase: TablesServiceBase) {
    super();
    this.traceService.info(TraceModules.property, 'PropertyBxSubstituteService created.');
  }

  /**
   * Retrieves property names of an object
   *
   * @param objectOrPropertyId The ObjectId OR the Object.PropertyId.
   * Note, that all property names are returned no matter if the ObjectId or the Object.PropertyId is handed over.
   * Also important to note: the reply contains a field ObjectId. The content of this files matches the parameter handed over on the request.
   * @returns
   *
   * @memberOf PropertyService
   */
  public readPropertyNames(objectOrPropertyId: string | null): Observable<PropertyInfo<string>> {
    if (objectOrPropertyId == null) {
      return null!;
    }
    this.traceService.debug(TraceModules.property, 'PropertyBxSubstituteService.readPropertyNames() called; objectOrPropertyId: %s', objectOrPropertyId);

    const propInfo$ = this.createPropertyInfoForNames(objectOrPropertyId);
    return propInfo$.pipe(
      observeOn(asyncScheduler)
    );
  }

  /**
   * Retrieves properties (meta data) without any runtime values for an object or property
   * See WSI API specification (Property Service) for details.
   *
   * @param objectOrPropertyId The ObjectId OR PropertyId
   * @param requestType See WSI documentation.
   * requestType = 1: returns attributes only; the parameter "readAllProperties" has no effect for this request type;
   * the attributes are always returned no matter if an ObjectId or PropertyId is handed over.
   * requestType = 2: returns full property information without attributes,
   * If an ObjectId and the parameter "readAllProperties" equals false, the property information of the "Main Property" is returned!
   * If an ObjectId and the parameter "readAllProperties" equals true, the property information of all properties is returned!
   * If a PropertyId and the parameter "readAllProperties" equals false, the property information of the specified PropertyId is returned!
   * If a PropertyId and the parameter "readAllProperties" equals true, the property information of all properties of the object is returned!
   * requestType = 3: returns full property information and always attributes; otherwise the same specification as for requestType 2
   * @param readAllProperties, applies only for requestType 2 and 3;
   * @returns
   *
   * @memberOf PropertyService
   */
  public readProperties(objectOrPropertyId: string, requestType: number, readAllProperties: boolean,
    booleansAsNumericText?: boolean, bitsInReverseOrder?: boolean): Observable<PropertyInfo<PropertyDetails>[]> {
    if ((objectOrPropertyId == null) || (requestType < 1) || (requestType > 3)) {
      this.traceService.error(TraceModules.property, 'PropertyBxSubstituteService.readProperties() called with invalid arguments');
      return throwError(() => new Error('PropertyBxSubstituteService.readProperties() called with invalid arguments'));
    }
    this.traceService.info(TraceModules.property, 'PropertyBxSubstituteService.readProperties() called: objectOrPropertyId: %s', objectOrPropertyId);

    const propInfo$ = this.createPropertyInfo(objectOrPropertyId, requestType);
    return propInfo$.pipe(
      map(result => [result]),
      observeOn(asyncScheduler)
    );
  }

  /**
   * Retrieves properties (meta data) without any runtime values for multiple objects OR properties.
   * This is the bulk version of "readProperties"
   * See WSI API specification (Property Service) for details.
   *
   * @param objectOrPropertyIds ObjectIds OR Property Ids
   * @param requestType See WSI documentation.
   * requestType = 1: returns attributes only; the parameter "readAllProperties" has no effect for this request type;
   * the attributes are always returned no matter if an ObjectId or PropertyId is handed over.
   * requestType = 2: returns full property information without attributes,
   * If an ObjectId and the parameter "readAllProperties" equals false, the property information of the "Main Property" is returned!
   * If an ObjectId and the parameter "readAllProperties" equals true, the property information of all properties is returned!
   * If a PropertyId and the parameter "readAllProperties" equals false, the property information of the specified PropertyId is returned!
   * If a PropertyId and the parameter "readAllProperties" equals true, the property information of all properties of the object is returned!
   * requestType = 3: returns full property information and always attributes; otherwise the same specification as for requestType 2
   * @param readAllProperties, applies only for requestType 2 and 3;
   * @returns
   *
   * @memberOf PropertyService
   */
  public readPropertiesMulti(objectOrPropertyIds: string[], requestType: number, readAllProperties: boolean,
    booleansAsNumericText?: boolean, bitsInReverseOrder?: boolean): Observable<PropertyInfo<PropertyDetails>[]> {

    return this.readPropertiesMultiSameCycle(objectOrPropertyIds, requestType, readAllProperties, booleansAsNumericText, bitsInReverseOrder).pipe(
      observeOn(asyncScheduler)
    );
  }

  public readPropertyImage(propertyId: string): Observable<string> {
    if (propertyId == null) {
      return throwError(() => new Error('PropertyBxSubstituteService.readPropertyImage() called with invalid arguments'));
    }
    this.traceService.debug(TraceModules.property, 'PropertyBxSubstituteService.readPropertyImage() called; propertyId: %s', propertyId);
    return throwError(() => new Error('PropertyBxSubstituteService.readPropertyImage(): Not Implemented!'));
  }

  public checkIfPropertyExists(dpId: DpIdentifier): Observable<boolean> {
    return this.readPropertiesMultiSameCycle([dpId.objectOrPropertyId], 3, true).pipe(
      map(propertyInfo => {
        return (this.isPoint(propertyInfo[0]) && this.isProperty(propertyInfo[0].Properties, dpId));
      })
    );
  }

  public isBulkable(objects: BrowserObject[]): Observable<boolean> {
    return this.readPropertiesMulti(objects.map(obj => obj.ObjectId), 3, true).pipe(switchMap(([first, ...others]) => {

      if (!others.length) {
        return of(true);
      }

      const firstDpId = new DpIdentifier(first.ObjectId);

      const firstTextTables = combineLatest(first.Properties.map(
        property => {
          if (!property.TextTable) {
            return of<LocalTextGroupEntry[]>([]);
          } else {
            return this.tablesServiceBase.getTextAndColorForTextGroupEntries(firstDpId.systemName, property.TextTable)
          }
        }
      ));
      const firstPointValue = this.pointService.getPointById(firstDpId.systemName, firstDpId.objectIdWoSystem);
      
      const othersTextTables = combineLatest(others.map(other => {
        const otherDpId = new DpIdentifier(other.ObjectId);
        return combineLatest(other.Properties.map(
          property => {
            if (!property.TextTable) {
              return of<LocalTextGroupEntry[]>([]);
            } else {
              return this.tablesServiceBase.getTextAndColorForTextGroupEntries(otherDpId.systemName, property.TextTable)
            }
          })
        );
      }));

      const otherPointValues = combineLatest(others.map(other => {
        const otherDpId = new DpIdentifier(other.ObjectId);
        return this.pointService.getPointById(otherDpId.systemName, otherDpId.objectIdWoSystem);
      }));

      // index check property type, text table, name
      return combineLatest([firstTextTables, firstPointValue, othersTextTables, otherPointValues]).pipe(
        map(([firstTextTablesRes, firstPointValueRes, othersTextTablesRes, otherPointValuesRes]) =>
          others.every(
            (other, otherIndex) => first.Properties.length === other.Properties.length && first.Properties.every(
              (prop, firstPropIndex) =>
                prop.Type === other.Properties[firstPropIndex].Type
                && this.compareTextTable(firstTextTablesRes[firstPropIndex], othersTextTablesRes[otherIndex][firstPropIndex])
                && this.compareType(firstPointValueRes.attributes.enum, otherPointValuesRes[otherIndex].attributes.enum)
                && firstPointValueRes.attributes.source.type === otherPointValuesRes[otherIndex].attributes.source.type
            )
          )
        )
      );
    }));
  }

  private readPropertiesMultiSameCycle(objectOrPropertyIds: string[], requestType: number, readAllProperties: boolean,
    booleansAsNumericText?: boolean, bitsInReverseOrder?: boolean): Observable<PropertyInfo<PropertyDetails>[]> {
    if ((objectOrPropertyIds == null) || (objectOrPropertyIds.length === 0) || (requestType < 1) || (requestType > 3)) {
      this.traceService.error(TraceModules.property, 'PropertyBxSubstituteService.readPropertiesMulti() called with invalid arguments');
      return throwError(() => new Error('PropertyBxSubstituteService.readProperties() called with invalid arguments'));
    }
    this.traceReadPropertiesMulti(objectOrPropertyIds);

    const propInfos$: Observable<PropertyInfo<PropertyDetails>>[] = [];
    objectOrPropertyIds.forEach(objId => {
      propInfos$.push(this.createPropertyInfo(objId, requestType));
    });
    this.traceService.info(TraceModules.property, `PropertyBxSubstituteService.readPropertiesMulti() returns: properties count: ${propInfos$.length}`);

    return zip(propInfos$).pipe(
      map(result => {
        return result;
      })
    );
  }

  private compareTextTable(textTable: LocalTextGroupEntry[], other: LocalTextGroupEntry[]): boolean {
    if (textTable.length !== other.length) {
      return false;
    }

    return textTable.every((entry, index) => entry.Text === other[index].Text && entry.Value === other[index].Value && entry.Color === other[index].Color);
  }

  private compareType(type: Record<string, EnumLabel>, other: Record<string, EnumLabel>): boolean {
    if (type === other) {
      return true;
    }

    if (!type || !other) {
      return false;
    }

    const entries = Object.entries(type);
    const otherEntries = Object.entries(other);

    if (entries.length !== otherEntries.length) {
      return false;
    }

    return entries.every(([key, value]) => otherEntries.some(([otherKey, otherValue]) => key === otherKey && value.label === otherValue.label));
  }

  private isPoint(propertyInfo: PropertyInfo<PropertyDetails>): boolean {
    return this.objectTypeMapper.checkIfPoint(propertyInfo.Attributes.TypeId);
  }

  private isPoint2(browserObject: BrowserObject): boolean {
    return this.objectTypeMapper.checkIfPoint(browserObject.Attributes.TypeId);
  }

  private isProperty(propertyDetails: PropertyDetails[], dpId: DpIdentifier): boolean {
    if (dpId.isProperty) {
      return propertyDetails.map(prp => prp.PropertyName).includes(dpId.propertName);
    } else {
      return true;
    }
  }

  private createPropertyInfoForNames(objectOrPropertyId: string): Observable<PropertyInfo<string>> {
    const dpId = new DpIdentifier(objectOrPropertyId);
    return (this.systemBrowser as SystemBrowserBxSubstituteService).resolveObjects(dpId.systemName, dpId.objectIdWoSystem).pipe(
      map(page => {
        if (page.Total === 0) {
          /* eslint-disable @typescript-eslint/naming-convention */
          const propInfo: PropertyInfo<string> = {
            ErrorCode: 1,
            ObjectId: objectOrPropertyId,
            Attributes: undefined,
            Properties: [],
            FunctionProperties: []
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return propInfo;
        } else {
          /* eslint-disable @typescript-eslint/naming-convention */
          const propInfo: PropertyInfo<string> = {
            ErrorCode: 0,
            ObjectId: objectOrPropertyId,
            Attributes: page.Nodes[0].Attributes,
            Properties: (this.isPoint2(page.Nodes[0])) ? this.propertyMapper.getPropertyNamesForPoint() : [],
            FunctionProperties: []
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return propInfo;
        }
      })
    );
  }

  private createPropertyInfo(objectOrPropertyId: string, requestType: number): Observable<PropertyInfo<PropertyDetails>> {
    const dpId = new DpIdentifier(objectOrPropertyId);
    return (this.systemBrowser as SystemBrowserBxSubstituteService).resolveObjects(dpId.systemName, dpId.objectIdWoSystem).pipe(
      concatMap(page => {
        if (page.Total === 0) {
          /* eslint-disable @typescript-eslint/naming-convention */
          const propInfo: PropertyInfo<PropertyDetails> = {
            ErrorCode: 1,
            ObjectId: objectOrPropertyId,
            Attributes: undefined,
            Properties: [],
            FunctionProperties: []
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return of(propInfo);
        }
        if (this.isPoint2(page.Nodes[0]) && (requestType !== 1)) {
          return this.pointService.getPointById(dpId.systemName, dpId.objectIdWoSystem).pipe(
            map(pointBx => {
              /* eslint-disable @typescript-eslint/naming-convention */
              const propInfo: PropertyInfo<PropertyDetails> = {
                ErrorCode: 0,
                ObjectId: objectOrPropertyId,
                Attributes: (requestType === 3) ? page.Nodes[0].Attributes : undefined,
                Properties: [],
                FunctionProperties: []
              };
              /* eslint-enable @typescript-eslint/naming-convention */
              if (this.isPoint2(page.Nodes[0])) {
                propInfo.Properties = this.propertyMapper.createPropertyDetails(pointBx.id, pointBx.attributes);
              }
              return propInfo;
            })
          );
        } else {
          /* eslint-disable @typescript-eslint/naming-convention */
          const propInfo: PropertyInfo<PropertyDetails> = {
            ErrorCode: 0,
            ObjectId: objectOrPropertyId,
            Attributes: (requestType === 1 || requestType === 3) ? page.Nodes[0].Attributes : undefined,
            Properties: [],
            FunctionProperties: []
          };
          /* eslint-enable @typescript-eslint/naming-convention */
          return of(propInfo);
        }
      })
    );
  }

  private traceReadPropertiesMulti(objectOrPropertyIds: string[]): void {
    this.traceService.info(TraceModules.property,
      'PropertyBxSubstituteService.readPropertiesMulti() called: number of objectOrPropertyIds: %s', objectOrPropertyIds.length);
    if (this.traceService.isDebugEnabled(TraceModules.property)) {
      this.traceService.debug(TraceModules.property,
        'PropertyBxSubstituteService.readPropertiesMulti(): for objectOrPropertyIds:\n%s', objectOrPropertyIds.join('\n'));
    }
  }
}
