import { Injectable } from "@angular/core";
import { CnsLabel, CnsLabelEn, Event } from "@gms-flex/services";
import { EventInfoSettingsServiceBase } from "@gms-flex/snapin-common";

@Injectable()
export class EventInfoSettingsBxSubstituteService extends EventInfoSettingsServiceBase {
  private readonly currCnsLabel: CnsLabel = null;

  private readonly showNotes: boolean = false;
  private readonly showAckCommand: boolean = false;

  private readonly srcNameDescription: string = '';

  public constructor() {
    super();
  }

  public isNotesVisible(): boolean {
    return this.showNotes;
  }

  public isAckCommandVisible(): boolean {
    return this.showAckCommand;
  }

  public getEventInfoSettings(): any {
    return {
      showInProcessBy: false,
      showDesignation: false,
      showEventId: false,
      showDiscipline: false,
      showSystemId: false,
      showBuildingName: true,
      showPartitionName: true,
      showMsgText: true,
      showCategory: true,
      showSystemName: false
    }
  }
  public sourceNameDescription(event: Event): string[] {
    let srcNameDescription: string[] = [];

    if (this.currCnsLabel != null) {
      switch (this.currCnsLabel.cnsLabel) {
        case CnsLabelEn.Description:
          srcNameDescription = [event.srcDescriptor];
          break;
        case CnsLabelEn.DescriptionAndAlias:
          srcNameDescription = [event.srcDescriptor, event.srcAlias];
          break;
        case CnsLabelEn.DescriptionAndName:
          srcNameDescription = [event.srcDescriptor, event.srcName];
          break;
        case CnsLabelEn.Name:
          srcNameDescription = [event.srcName];
          break;
        case CnsLabelEn.NameAndAlias:
          srcNameDescription = [event.srcName, event.srcAlias];
          break;
        case CnsLabelEn.NameAndDescription:
          srcNameDescription = [event.srcName, event.srcDescriptor];
          break;
        default:
          break;
      }
    }
    return srcNameDescription;
  }

  public getSourceProperty(): string {
    if (this.srcNameDescription !== undefined) {
      return this.srcNameDescription;
    }
  }
}
