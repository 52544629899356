import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, AbstractControlOptions, FormControl, FormGroup, Validators } from "@angular/forms";
import { AlarmConfigAttributes, AnalogPointConfiguration } from "@gms-flex/services";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";

import { formatStringOrNumber } from "../../../core/shared/string-utils";

export interface AnalogPointAlarmData {
  analogPointAlarmConfiguration: {
    highLimit: number | string;
    lowLimit: number | string;
  }
}

@Component({
  selector: 'point-alarm-analog',
  templateUrl: './point-alarm-analog.component.html',
  styleUrl: './point-alarm-analog.component.scss'
})
export class PointAlarmAnalogComponent implements OnInit, OnDestroy {
  @Input() public pointAlarmConfig: AlarmConfigAttributes;
  @Input() public unit: string;
  @Input() public translatedLabels: Map<string, string>;
  @Output() public readonly newAnalogValueEvent = new EventEmitter<AnalogPointAlarmData>();

  public analogForm: FormGroup<{ analogMinValueSelection: FormControl<string | number | null>; analogMaxValueSelection: FormControl<string | number | null> }>;
  public isLowLimitValid = true;
  public isHighLimitValid = true;

  private alarmData: AnalogPointAlarmData;
  private readonly formOptions: AbstractControlOptions = { validators: this.minLessThanMaxValidator };
  private formChangeSubscription: Subscription;

  private readonly debounceTime = 100;

  public ngOnInit(): void {
    this.alarmData = this.pointAlarmConfig.configuration ? this.getAlarmDataObj() : this.getNoAlarmDataObj();
    this.analogForm = new FormGroup(
      {
        analogMinValueSelection: new FormControl(this.alarmData.analogPointAlarmConfiguration?.lowLimit ?? '', Validators.required),
        analogMaxValueSelection: new FormControl(this.alarmData.analogPointAlarmConfiguration?.highLimit ?? '', Validators.required)
      },
      this.formOptions
    );
    this.formChangeSubscription = this.analogForm.valueChanges.pipe(debounceTime(this.debounceTime), distinctUntilChanged()).subscribe((val: any) => {
      if (this.analogForm.errors || ((this.analogForm.touched || this.analogForm.dirty) && !this.analogForm.valid)) {
        if (this.analogForm?.errors?.emptyLowLimit || this.analogForm?.errors?.emptyHighLimit) {
          this.isLowLimitValid = !this.analogForm.errors.emptyLowLimit;
          this.isHighLimitValid = !this.analogForm.errors.emptyHighLimit;
        } else if (val.analogMaxValueSelection <= val.analogMinValueSelection) {
          this.isLowLimitValid = false;
          this.isHighLimitValid = false;
        }
      } else {
        this.isLowLimitValid = true;
        this.isHighLimitValid = true;
      }
      this.emitData(val);
    });

  }

  public ngOnDestroy(): void {
    this.formChangeSubscription?.unsubscribe();
  }

  private getAlarmDataObj(): AnalogPointAlarmData {
    return this.pointAlarmConfig.configuration as AnalogPointConfiguration;
  }

  private getNoAlarmDataObj(): AnalogPointAlarmData {
    return {
      analogPointAlarmConfiguration: {
        lowLimit: '',
        highLimit: ''
      }
    };
  }

  /** Form validator */
  private minLessThanMaxValidator(abstractControl: AbstractControl): { [key: string]: boolean } | null {
    const analogMinControl = abstractControl.get('analogMinValueSelection');
    const analogMaxControl = abstractControl.get('analogMaxValueSelection');

    if (analogMinControl?.pristine && analogMaxControl?.pristine) {
      return null;
    }

    const analogMinControlValEmpty = analogMinControl?.value === null || analogMinControl?.value.toString().length === 0;
    const analogMaxControlValEmpty = analogMaxControl?.value === null || analogMaxControl?.value.toString().length === 0;
    if (analogMinControlValEmpty || analogMaxControlValEmpty) {
      return {
        emptyLowLimit: analogMinControlValEmpty,
        emptyHighLimit: analogMaxControlValEmpty
      };
    }

    if (analogMinControl?.value >= analogMaxControl?.value) {
      return { minMoreThanMax: true };
    }

    return null;
  }

  private emitData(value: Partial<{ analogMinValueSelection: string | number; analogMaxValueSelection: string | number }>): void {
    const minValSelectionEmpty = value.analogMinValueSelection === null || value?.analogMinValueSelection?.toString().length === 0;
    const maxValSelectionEmpty = value.analogMaxValueSelection === null || value?.analogMaxValueSelection?.toString().length === 0;

    if (!minValSelectionEmpty && !maxValSelectionEmpty) {
      this.newAnalogValueEvent.emit(
        { analogPointAlarmConfiguration: { highLimit: value.analogMaxValueSelection as number, lowLimit: value.analogMinValueSelection as number } });
    }
  }

  private emitDataNew(value: Partial<{ analogMinValueSelection: string | number; analogMaxValueSelection: string | number }>): void {
    const lowLimit = formatStringOrNumber(value.analogMinValueSelection);
    const highLimit = formatStringOrNumber(value.analogMaxValueSelection);

    if (lowLimit && highLimit) {
      this.newAnalogValueEvent.emit({ analogPointAlarmConfiguration: { lowLimit: lowLimit, highLimit: highLimit } });
    }
  }
}
