import { TraceModules } from '@gms-flex/services';
import { TraceService } from "@gms-flex/services-common";

// Check if the given string value represents a number.
// For example both '123' and '  123' return '123'.
export const isNumericString = (value: string | undefined | null): boolean => {
  return !value ? false : !Number.isNaN(Number(value)) && value.trim() !== '';
}

// Check if the given string value represents a finite number and return the string itself or the corresponding valid string.
// For example '123', '  123 ', '123.000', '123.' return '123'.
// If the given string
// - is null, undefined, empty or contains 'Infinity' => returns undefined
// - is NaN or does not contain a numeric string => traces a warning and returns undefined
export const formatNumericString = (value: string | undefined | null, traceService?: TraceService): string | undefined => {
  if (!value || value.trim() === '' || value.toLowerCase().includes('infinity')) {
    return undefined;
  }

  if (value.startsWith('.')) {
    value = '0' + value;
  }

  if (!isNumericString(value)) {
    traceService?.warn(TraceModules.utilities, `Expected numeric value, found '${value}'.`);
    return undefined;
  }

  const number = parseFloat(value);
  return number.toString();
}

// Check if the given string or number value represents a finite number and return the corresponding valid number or undefined.
// If the given value is null or undefined, returns undefined.
// If the given value is a string, calls formatNumericString, then parseFloat on the valid string.
// If the given value is a number, checks for value not to be NaN or not a finite number.
export const formatStringOrNumber = (value: string | number | undefined | null, traceService?: TraceService): number | undefined => {
  
  if (!value) {
    // value is null, undefined or NaN
    return undefined;
  }

  if (typeof value === 'string') {
    const numberStr = formatNumericString(value, traceService);
    if (numberStr) {
      return parseFloat(numberStr);
    }
    return undefined;
  }

  if (!Number.isFinite(value)) {
    return undefined;
  }

  return value;
}
