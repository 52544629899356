import { Injectable } from '@angular/core';
import {
  Customer,
  Partition,
  PartitionsDetailsData,
  Permission,
  SiAODSService,
  SiCookiesService,
  SiProfileService,
  Subscription,
  SubscriptionsService
} from '@building-x/common-ui-ng';
// eslint-disable-next-line @typescript-eslint/naming-convention
import _ from 'lodash';
import { BehaviorSubject, catchError, concatMap, map, Observable, of, zip } from 'rxjs';

import { ContextService } from '../bx-gms-mapper/state/context.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectorStateService {

  public allCustomers: Customer[] = [];
  public customerPartitions: Partition[] = [];
  public selectedCustomer: Customer;
  public selectedCustomerDropdown: Customer;
  public sharedPartitionCustomers: Customer[] = [];
  public isCustomerSelectorActive = false;
  public customerSubscription: Subscription[] = [];
  public allPermissions: Permission;
  public isAllPartitionsLoaded = true;
  public selectedCustomerPartitions: Partition[] = [];

  private readonly allPermissionsSubject = new BehaviorSubject<any>(null);

  constructor(
    private readonly siProfileService: SiProfileService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly aodsService: SiAODSService,
    private readonly cookieService: SiCookiesService,
    private readonly contextService: ContextService
  ) {
  }

  public init(): void {
    // const selectedCustomerId = this.cookieService.getCookie('selectedCustomerId');
    const selectedCustomerId = localStorage.getItem('selectedCustomerId');

    this.siProfileService.getCustomers().subscribe(customers => {
      // TODO: check the horizon getCustomers() method!
      this.allCustomers = (customers as any).data;
      const foundCust = this.allCustomers.find(cust => cust.id === selectedCustomerId);
      this.selectedCustomer = foundCust ?? this.allCustomers[0];
      this.selectedCustomerDropdown = this.selectedCustomer;
      this.getSelectedCustomerData(this.selectedCustomer.id).subscribe(data => {
        this.setCustomerData(data);
        // const savedSelectedPartitions = this.cookieService.getCookie('selectedPartitions');
        const savedSelectedPartitions = localStorage.getItem('selectedPartitions');
        if (savedSelectedPartitions?.length > 0) {
          const persistedPartitionIds = savedSelectedPartitions.split(',');
          const foundPartitions: Partition[] = [];
          persistedPartitionIds.forEach(partId => {
            const foundPart = this.customerPartitions.find(part => part.id === partId);
            if (foundPart) {
              this.selectedCustomerPartitions.push(foundPart);
              foundPartitions.push(foundPart);
            }
          });
          this.contextService.selectedPartitions = foundPartitions;
        }
      });
    });
  }

  public initAsync(): Observable<boolean> {
    // const selectedCustomerId = this.cookieService.getCookie('selectedCustomerId');
    const selectedCustomerId = localStorage.getItem('selectedCustomerId');

    return this.siProfileService.getCustomers().pipe(
      concatMap((customers: Customer[]) => {
        // TODO: check the horizon getCustomers() method!
        this.allCustomers = (customers as any).data;
        const foundCust = this.allCustomers.find(cust => cust.id === selectedCustomerId);
        this.selectedCustomer = foundCust ?? this.allCustomers[0];
        this.selectedCustomerDropdown = this.selectedCustomer;
        this.contextService.selectedCustomer = this.selectedCustomer as any;
        return this.getSelectedCustomerData(this.selectedCustomer.id).pipe(
          map(data => {
            this.setCustomerData(data);
            // const savedSelectedPartitions = this.cookieService.getCookie('selectedPartitions');
            const savedSelectedPartitions = localStorage.getItem('selectedPartitions');
            if (savedSelectedPartitions?.length > 0) {
              let persistedPartitionIds = savedSelectedPartitions.split(',');

              // make partition ids distinct
              const partitionMap = new Map<string, string>();
              for (const item of persistedPartitionIds) {
                partitionMap.set(item, item);
              }
              persistedPartitionIds = [];
              partitionMap.forEach((value, key) => persistedPartitionIds.push(value));

              const foundPartitions: Partition[] = [];
              persistedPartitionIds.forEach(partId => {
                const foundPart = this.customerPartitions.find(part => part.id === partId);
                if (foundPart) {
                  this.selectedCustomerPartitions.push(foundPart);
                  foundPartitions.push(foundPart);
                }
              });
              this.contextService.selectedPartitions = foundPartitions;
            }
            return true;
          })
        );
      })
    );
  }

  public onCustomerChange(event): void {
    this.selectedCustomerDropdown = event;
    // this.selectedCustomer = event;
    this.getSelectedCustomerData(event.id).subscribe(data => {
      this.setCustomerData(data);
    }, () => {
      this.isAllPartitionsLoaded = true;
      this.customerPartitions = [];
      this.allPermissions = null;
    });
  }

  public onSelectedCustomerPartitions(event): void {
    const selectedPartitions: Partition[] = event.partitions;
    const partMap = new Map<string, Partition>();
    for (const item of selectedPartitions) {
      partMap.set(item.id, item);
    }

    // make partitions distinct
    this.selectedCustomerPartitions = [];
    partMap.forEach((value, key) => this.selectedCustomerPartitions.push(value));
    this.selectedCustomer = event.customer;
    this.contextService.selectedCustomer = _.cloneDeep(this.selectedCustomer) as any;
    this.contextService.selectedPartitions = _.cloneDeep(this.selectedCustomerPartitions);

    const cookieExpiry = new Date();
    cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
    this.cookieService.setCookie('selectedCustomerId', this.selectedCustomer.id, '', cookieExpiry.toUTCString());
    this.cookieService.setCookie('selectedPartitions', this.selectedCustomerPartitions.map(partition => partition.id).join(), '', cookieExpiry.toUTCString());
    localStorage.setItem('selectedCustomerId', this.selectedCustomer.id);
    localStorage.setItem('selectedPartitions', this.selectedCustomerPartitions.map(partition => partition.id).join());
  }

  /* Returns all customer permissions  */
  public getAllPermissions(): Observable<any> {
    return this.allPermissionsSubject.asObservable();
  }

  // Can be used for future improvements
  public updateCustomerData(data): void {
    this.setCustomerData(data);
  }

  private setCustomerData(data): void {
    this.customerPartitions = data[0] || [];
    this.sharedPartitionCustomers = data[3];
    this.getPartitionSubscription(data[1] || []);
    this.allPermissions = data?.[2]?.length ? data[2][0] : null;
    this.allPermissionsSubject.next(this.allPermissions);
    this.isAllPartitionsLoaded = true;
  }

  private getPartitionSubscription(subscriptions: Subscription[]): void {
    this.customerSubscription = subscriptions;
    this.customerPartitions?.forEach((partition: Partition) => {
      const selectedSubscription = subscriptions?.find(
        (sub: any) =>
          sub.id === partition.relationships?.backedBySubscription?.data?.id ||
          partition.relationships?.sharedThroughSubscription?.data?.id
      );
      partition.subscriptionName =
        selectedSubscription?.attributes?.name || '';
    });
  }

  // copied from horizon as is. do better typed return values!!
  private getSelectedCustomerData(customerId: string): Observable<any> {
    return new Observable(observer => {
      const getPermissions = this.aodsService
        .getPermissionsByCustomerId(customerId)
        .pipe(catchError(() => of([{
          scopeType: 'customer',
          targetId: customerId,
          permissions: [],
          partitions: []
        }])));

      const getPartitions = this.subscriptionsService
        .getPartitions(customerId)
        .pipe(catchError(() => of([])));

      const getSubscription = this.subscriptionsService
        .getSubscriptions(customerId)
        .pipe(catchError(() => of([])));

      zip(getPartitions, getSubscription, getPermissions).subscribe(data => {
        observer.next([((data[0]) as PartitionsDetailsData)?.data, data[1], data[2], ((data[0]) as PartitionsDetailsData)?.included || []]);
        observer.complete();
      }, () => {
        observer.next([[], [], null, []]);
        observer.complete();
      });
    });
  }
}
