import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { catchError, map, Observable, of, tap } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { CalendarStatus } from "./calendar-proxy.model";
import { calendarBACnetDescription } from "./models/calendarBACnetDescription";
import { calendarBACnetDescriptionUpdatable } from "./models/calendarBACnetDescriptionUpdatable";
import { calendarCollectionElement } from "./models/calendarCollectionElement";
import { ontology } from "./models/ontology";
import { ScheduleProxyService } from "./schedule-proxy.service";

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  
  public constructor(
    private readonly traceService: TraceService,
    private readonly scheduleProxyService: ScheduleProxyService) {

    this.traceService.info(TraceModules.bxServicesCalendars, 'CalendarService created.');
  }

  /*
  get all the calendars associated with partition with selected entity
  **/
  public getCalendars(partitionId: string, entityId: string, calendarName?: string, status?: CalendarStatus): Observable<calendarCollectionElement[]> {
    this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendars() called: partitionId: ${partitionId}, entityId: ${entityId}`);
    return this.scheduleProxyService.getCalendars(partitionId, entityId).pipe(
      map(result => this.filterCalendars(result, calendarName)),
      tap(calendars => {
        this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendars() returned: no of calendars: ${calendars.length} from backend
           partitionId=${partitionId}, calendarName=${calendarName}`);
      }),
      catchError(error => {
        this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendars(): Error: ${entityId}, error=${error}`);
        return of([]);
      })
    );
  }

  /*
  read individual calendar per partition
  **/
  public getCalendar(partitionId: string, calendarId: string): Observable<calendarBACnetDescription> {
    this.traceService.debug(TraceModules.bxServicesCalendars, `CalendarService.getCalendar() called: partitionId: ${partitionId},
      calendarId: ${calendarId}`);

    return this.scheduleProxyService.getCalendar(partitionId, calendarId);
  }

  public saveCalendar(partitionId: string, calendarId: string, calendar: calendarBACnetDescription): Observable<boolean> {
    const calendarToBeSaved: calendarBACnetDescriptionUpdatable = {
      attributes: {
        dateList: calendar.attributes.dateList
      }
    }
    return this.scheduleProxyService.updateCalendar(partitionId, calendarId, calendarToBeSaved).pipe(
      map(() => { return true }),
      catchError(error => {
        this.traceService.warn(TraceModules.bxServicesCalendars, `CalendarService.saveCalendar(): error in update calendar: ${calendarId}, error=${error}`);
        return of(false);
      })
    )
  }

  private filterCalendars(calendars: calendarCollectionElement[], name?: string): calendarCollectionElement[] {
    if (name) {
      const found = this.findCalendar(calendars, name);
      return found ? [found] : [];
    } else {
      return calendars;
    }
  }

  private findCalendar(calendarData: calendarCollectionElement[], name: string): calendarCollectionElement | undefined {
    return calendarData.find(item => item.name === name);
  }
}
