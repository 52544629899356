import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, EMPTY, expand, from, map, mergeMap, Observable, reduce } from 'rxjs';
import { DeviceStatsGroupListResponse } from 'src/app/bx-gms-mapper/api/models/device.model';
import { TraceModules } from 'src/app/core/shared/trace-modules';
import { environment } from 'src/environments/environment';

import { HttpUtilityService } from '../shared/http-utility.service';
import { DeviceFeature, DevicesResponse, DevicesResponsePaged, SingleDeviceResponse } from './device-proxy.model';

const dmUrl = `${environment.bxPlatform.deviceVerticalApiUrl}/v2/partitions`

@Injectable({
  providedIn: 'root'
})
export class DeviceProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly utilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.bxServicesDevices, 'DeviceProxyService created.');
  }

  public getDevices(partitionId: string): Observable<DevicesResponse> {
    this.traceService.debug(TraceModules.bxServicesDevices, `DeviceProxyService.getDevices() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100); // max possible page size is 100
    params = params.set('include', 'hasFeatures.DeviceInfo');

    return this.httpClient.get<DevicesResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<DevicesResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body.data ?? [], included: response.body.included ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data], included: [...accumulator.included, ...current.included] })),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDevices()')));
  }

  public getDevicesOfLocation(partitionId: string, locationId: string): Observable<DevicesResponse> {
    this.traceService.debug(TraceModules.bxServicesDevices,
      `DeviceProxyService.getDevicesOfLocation() called: partitionId: ${partitionId}, locationId: ${locationId}`);
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100); // max possible page size is 100
    params = params.set('filter[hasLocation.id]', locationId);
    params = params.set('include', 'hasFeatures.DeviceInfo');
    params = params.append('include', 'hasFeatures.Tunnel');
    params = params.append('include', 'hasFeatures.Connectivity');
    // params = params.append('include', 'hasFeatures.Ba_DeviceInfo');

    return this.httpClient.get<DevicesResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<DevicesResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body.data ?? [], included: response.body.included ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data], included: [...accumulator.included, ...current.included] })),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDevicesOfLocation()')));
  }

  public getDeviceById(partitionId: string, deviceId: string): Observable<SingleDeviceResponse> {
    this.traceService.debug(TraceModules.bxServicesDevices, `DeviceProxyService.getDeviceById() called: partitionId: ${partitionId}, deviceId: ${deviceId}`);
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices/${deviceId}`;
    // TODO: get the features when available by DV

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.utilityService.extractData(response, 'getDeviceById()')),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDeviceById()')));
  }

  public getDeviceFeatureById(partitionId: string, deviceId: string): Observable<{ data: DeviceFeature[] }> {
    this.traceService.debug(TraceModules.bxServicesDevices,
      `DeviceProxyService.getDeviceFeatureById() called: partitionId: ${partitionId}, deviceId: ${deviceId}`);
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices/${deviceId}/features`;

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.utilityService.extractData(response, 'getDeviceFeatureById()')),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDeviceFeatureById()')));
  }

  public getDevicesBehindGateway(partitionId: string, gatewayId: string): Observable<DevicesResponse> {
    this.traceService.debug(TraceModules.bxServicesDevices,
      `DeviceProxyService.getDevicesBehindGateway() called: partitionId: ${partitionId}, gatewayId: ${gatewayId}`);
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices/${gatewayId}/devices`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', 100);
    params = params.set('include', 'hasFeatures.DeviceInfo');
    // params = params.append('include', 'hasFeatures.Tunnel');
    // params = params.append('include', 'hasFeatures.Connectivity');
    // params = params.append('include', 'hasFeatures.Ba_DeviceInfo');

    return this.httpClient.get<DevicesResponsePaged>(url, { headers, params, observe: 'response' }).pipe(
      expand(response => {
        if (response.body?.meta?.page?.cursor) {
          params = params.set('page[after]', response.body?.meta?.page?.cursor.trim());
          return this.httpClient.get<DevicesResponsePaged>(url, { headers, observe: 'response', params });
        } else {
          return EMPTY;
        }
      }),
      map(response => ({ data: response.body.data ?? [], included: response.body.included ?? [] })),
      reduce((accumulator, current) => ({ data: [...accumulator.data, ...current.data], included: [...accumulator.included, ...current.included] })),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDevicesBehindGateway()')));
  }

  public getDevicesBehindGatewayPaged(partitionId: string, gatewayId: string, pageSize: number): Observable<DevicesResponsePaged> {
    this.traceService.debug(TraceModules.bxServicesDevices,
      `DeviceProxyService.getDevicesBehindGatewayPaged() called: partitionId: ${partitionId}, gatewayId: ${gatewayId}`);
    if (pageSize > 100) {
      pageSize = 100; // max allowed size
    }
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    const url = `${dmUrl}/${partitionId}/devices/${gatewayId}/devices`;
    let params: HttpParams = new HttpParams();
    params = params.set('page[limit]', pageSize);
    params = params.set('include', 'hasFeatures.DeviceInfo');

    return this.httpClient.get(url, { headers, params, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.utilityService.extractData(response, 'getDevicesBehindGatewayPaged()')),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDevicesBehindGatewayPaged()')));
  }

  public getDeviceStatsForBuildings(partitionIds: string[]): Observable<DeviceStatsGroupListResponse[]> {
    const headers: HttpHeaders = this.utilityService.httpGetDefaultHeader();
    let params: HttpParams = new HttpParams();
    params = params.set('groupBy', 'locationId');
    params = params.set('count[fwUpdates][hasFeatures.FirmwareUpdate.canStartUpdate]', 'true');
    params = params.append('count[offline][hasFeatures.Connectivity.status]', 'offline');
    params = params.append('count[numDisconnected][hasFeatures.Connectivity.status]', 'disconnected');

    return from(partitionIds).pipe(
      mergeMap(partitionId => {
        const deviceVerticalUrlForDeviceStats = `${dmUrl}/${partitionId}/device-stats`;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return this.httpClient.get<DeviceStatsGroupListResponse>(deviceVerticalUrlForDeviceStats, { headers, params, observe: 'response' })
      }),
      reduce((accumulator, current) => accumulator.concat(current.body), []),
      catchError((response: HttpResponse<any>) => this.utilityService.handleError(response, 'getDeviceStatsForBuildings()')));
  }

}
