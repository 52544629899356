import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import {
  AlarmConfigBx, AlarmConfigurationServiceBase, AnalogPointConfiguration,
  BinaryPointConfiguration, MultiStatePointConfiguration, PropertyDetails
} from '@gms-flex/services';
import { TranslateService } from "@ngx-translate/core";
import { SiToastNotificationService } from "@simpl/element-ng";
import { map, Subscription } from "rxjs";
import { DccDataType } from "src/app/bx-gms-mapper/properties/point-type.service";
import { PointValueMapperService } from "src/app/bx-gms-mapper/properties/property-mappers/point-value-mapper.service";
import { EventCategoryBx } from "src/app/bx-services/alarm/events-proxy.model";
import { PointBxWithValue } from "src/app/bx-services/point/point.model";

@Component({
  selector: "event-settings",
  templateUrl: "./event-settings.component.html",
  styleUrl: "./event-settings.component.scss"
})
export class EventSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public point: PointBxWithValue;
  @Input() public location: string;

  public canSaveAlarmData = false;
  public translatedLabels: Map<string, string> = new Map<string, string>();
  public pointAlarmConfig: AlarmConfigBx;
  public propertyDetails: PropertyDetails;
  public isLoading = true;
  public hasAlarmConfiguration = false;

  public readonly analogTypes: string[] = [DccDataType.ExtendedReal, DccDataType.ExtendedInt, DccDataType.BasicFloat, DccDataType.BasicInt];
  public readonly binaryTypes: string[] = [DccDataType.BasicBool, DccDataType.ExtendedBool]
  public readonly multistateTypes: string[] = [DccDataType.ExtendedEnum];

  private readonly noAlarmDataDefaultCategory = EventCategoryBx.Medium;
  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly alarmAlarmConfigurationServiceBase: AlarmConfigurationServiceBase,
    private readonly pointValueMapperService: PointValueMapperService,
    private readonly toastNotificationService: SiToastNotificationService,
    @Inject(TranslateService) private readonly translateService: TranslateService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.point.firstChange &&
      changes.point.currentValue.id !== changes.point?.previousValue?.id
    ) {
      this.isLoading = true;
      this.point = changes.point.currentValue;
      this.getNewPointData();
    }
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.translateService
        .get([
          "ALARM_CONFIGURATION.ALARM_FORM.APPLY",
          "ALARM_CONFIGURATION.ALARM_FORM.EVENT_MESSAGE",
          "ALARM_CONFIGURATION.ALARM_FORM.EMPTY_STATE_HEADING",
          "ALARM_CONFIGURATION.ALARM_FORM.ENABLED",
          "ALARM_CONFIGURATION.ALARM_FORM.ERROR_MESSAGE",
          "ALARM_CONFIGURATION.ALARM_FORM.CATEGORY",
          "ALARM_CONFIGURATION.ALARM_FORM.LOW_LIMIT",
          "ALARM_CONFIGURATION.ALARM_FORM.HIGH_LIMIT",
          "ALARM_CONFIGURATION.ALARM_FORM.INVALID_LOW_HIGH_LIMIT",
          "ALARM_CONFIGURATION.ALARM_FORM.VALUE",
          "ALARM_CONFIGURATION.ALARM_FORM.SELECT_PLACEHOLDER",
          "ALARM_CONFIGURATION.ALARM_FORM.SUCCESS_MESSAGE_CREATED",
          "ALARM_CONFIGURATION.ALARM_FORM.SUCCESS_MESSAGE_UPDATED",
          "BASIC_TEXTS.ERROR",
          "BASIC_TEXTS.SUCCESS"
        ])
        .subscribe(values => this.onTraslateStrings(values))
    );

    this.getNewPointData();
  }

  public ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s?.unsubscribe());
  }

  public toggleAlarm(): void {
    this.pointAlarmConfig.attributes.enabled = !this.pointAlarmConfig.attributes.enabled;
    this.canSaveAlarmData = this.canSaveConfiguration();
  }

  public onCategorySelect(category: EventCategoryBx): void {
    this.pointAlarmConfig.attributes.category = category;
    this.canSaveAlarmData = this.canSaveConfiguration();
  }

  public onComment(text: string): void {
    this.pointAlarmConfig.attributes.message = text;
    this.canSaveAlarmData = this.canSaveConfiguration();
  }

  public onSettingChange(configuration: AnalogPointConfiguration | BinaryPointConfiguration | MultiStatePointConfiguration): void {
    this.pointAlarmConfig.attributes.configuration = configuration;
    this.canSaveAlarmData = this.canSaveConfiguration();
  }

  public onSave(): void {
    if (!this.pointAlarmConfig) {
      return;
    }

    // check if analog data limit are valid
    const analogConfiguration = this.pointAlarmConfig.attributes.configuration as AnalogPointConfiguration;
    if (analogConfiguration.analogPointAlarmConfiguration && !this.checkValidLimit(analogConfiguration)) {
      this.toastNotificationService.queueToastNotification(
        'danger',
        this.translatedLabels.get("error")!,
        this.translatedLabels.get("limit-error")!
      )
      return;
    }
    this.sendAlarmConfigurationData();
  }

  private getNewPointData(): void {

    this.canSaveAlarmData = false;
    this.propertyDetails = this.pointValueMapperService.createPointProperty(this.point.id, this.point.attributes, 0);

    this.subscriptions.push(
      this.alarmAlarmConfigurationServiceBase.getAlarmConfiguration(
        this.location,
        this.point.id
      ).pipe(map((alarmConfig: any) => alarmConfig.data[0]))
        .subscribe((alarmConfig: AlarmConfigBx) => {
          if (alarmConfig) {
            this.pointAlarmConfig = alarmConfig;
            this.hasAlarmConfiguration = true;
          } else {
            this.pointAlarmConfig = this.getEmptyAlarmConfig();
            this.hasAlarmConfiguration = false;
          }
          this.isLoading = false;
        }));
  }

  private sendAlarmConfigurationData(): void {
    if (this.pointAlarmConfig.id) {
      this.updateAlarmConfiguration();
    } else {
      this.createAlarmConfiguration();
    }
  }

  private createAlarmConfiguration(): void {
    this.subscriptions.push(
      this.alarmAlarmConfigurationServiceBase.createAlarmConfiguration(this.location, this.point.id, this.pointAlarmConfig.attributes)
        .pipe(map((alarmConfigApiResponse: any) => alarmConfigApiResponse.data))
        .subscribe({
          next: (alarmConfigApiResponse: AlarmConfigBx) => {
            this.pointAlarmConfig = alarmConfigApiResponse;
            this.toastNotificationService.queueToastNotification(
              'success',
              this.translatedLabels.get("success")!,
              this.translatedLabels.get("success-message-created")!
            );
          },
          error: () =>
            this.toastNotificationService.queueToastNotification(
              'danger',
              this.translatedLabels.get("error")!,
              this.translatedLabels.get("error-message")!
            )
        })
    );
  }

  private updateAlarmConfiguration(): void {
    this.subscriptions.push(
      this.alarmAlarmConfigurationServiceBase.updateAlarmConfiguration(this.location, this.point.id, this.pointAlarmConfig)
        .pipe(map((alarmConfigApiResponse: any) => alarmConfigApiResponse.data))
        .subscribe({
          next: (alarmConfigApiResponse: AlarmConfigBx) => {
            this.pointAlarmConfig = alarmConfigApiResponse;
            this.toastNotificationService.queueToastNotification(
              'success',
              this.translatedLabels.get("success")!,
              this.translatedLabels.get("success-message-updated")!
            );
          },
          error: () =>
            this.toastNotificationService.queueToastNotification(
              'danger',
              this.translatedLabels.get("error")!,
              this.translatedLabels.get("error-message")!
            )
        })
    );
  }

  private checkValidLimit(configuration: AnalogPointConfiguration): boolean {
    return configuration.analogPointAlarmConfiguration.lowLimit >= configuration.analogPointAlarmConfiguration.highLimit ? false : true;
  }

  private getEmptyAlarmConfig(): AlarmConfigBx {
    return {
      type: '',
      id: '',
      attributes: {
        enabled: false,
        category: this.noAlarmDataDefaultCategory,
        message: "",
        createdAt: "",
        lastUpdatedAt: "",
        configuration: undefined
      },
      relationships: { points: { data: { id: '', type: '' } } }
    };
  }

  private canSaveConfiguration(): boolean {
    return this.pointAlarmConfig.attributes.configuration ? true : false;
  }

  private onTraslateStrings(strings: string[]): void {
    this.translatedLabels.set("enabled", strings["ALARM_CONFIGURATION.ALARM_FORM.ENABLED"]);
    this.translatedLabels.set("apply", strings["ALARM_CONFIGURATION.ALARM_FORM.APPLY"]);
    this.translatedLabels.set("message", strings["ALARM_CONFIGURATION.ALARM_FORM.EVENT_MESSAGE"]);
    this.translatedLabels.set("empty-state-heading", strings["ALARM_CONFIGURATION.ALARM_FORM.EMPTY_STATE_HEADING"]);
    this.translatedLabels.set("error-message", strings["ALARM_CONFIGURATION.ALARM_FORM.ERROR_MESSAGE"]);
    this.translatedLabels.set("category", strings["ALARM_CONFIGURATION.ALARM_FORM.CATEGORY"]);
    this.translatedLabels.set("low", strings["ALARM_CONFIGURATION.ALARM_FORM.LOW_LIMIT"]);
    this.translatedLabels.set("high", strings["ALARM_CONFIGURATION.ALARM_FORM.HIGH_LIMIT"]);
    this.translatedLabels.set("limit-error", strings["ALARM_CONFIGURATION.ALARM_FORM.INVALID_LOW_HIGH_LIMIT"]);
    this.translatedLabels.set("value", strings["ALARM_CONFIGURATION.ALARM_FORM.VALUE"]);
    this.translatedLabels.set("select-placeholder", strings["ALARM_CONFIGURATION.ALARM_FORM.SELECT_PLACEHOLDER"]);
    this.translatedLabels.set("success-message-created", strings["ALARM_CONFIGURATION.ALARM_FORM.SUCCESS_MESSAGE_CREATED"]);
    this.translatedLabels.set("success-message-updated", strings["ALARM_CONFIGURATION.ALARM_FORM.SUCCESS_MESSAGE_UPDATED"]);
    this.translatedLabels.set("error", strings["BASIC_TEXTS.ERROR"]);
    this.translatedLabels.set("success", strings["BASIC_TEXTS.SUCCESS"]);
  }
}
