import { Injectable } from "@angular/core";
import { TraceService } from "@gms-flex/services-common";
import { catchError, map, Observable, of, tap } from "rxjs";
import { TraceModules } from "src/app/core/shared/trace-modules";

import { scheduleBACnetDescription } from "./models/scheduleBACnetDescription";
import { scheduleCollectionElement } from "./models/scheduleCollectionElement";
import { ScheduleStatus } from "./schedule-proxy.model";
import { ScheduleProxyService } from "./schedule-proxy.service";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly scheduleProxyService: ScheduleProxyService) {

    this.traceService.info(TraceModules.bxServicesSchedules, 'ScheduleService created.');
  }

  public getSchedules(partitionId: string, entityId: string, scheduleName?: string, status?: ScheduleStatus): Observable<scheduleCollectionElement[]> {
    this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() called: partitionId: ${partitionId}, entityId: ${entityId}`);
    return this.scheduleProxyService.getSchedules(partitionId, entityId)
      .pipe(
        map(result => this.filterSchedules(result, scheduleName)),
        tap(schedules => {
          this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules() returned: no of schedules: ${schedules.length} from backend
        partitionId=${partitionId}, scheduleName=${scheduleName}`);
        }),
        catchError(error => {
          this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedules(): Error: ${entityId}, error=${error}`);
          return of([]);
        })
      );
  }

  public getSchedule(partitionId: string, scheduleId: string): Observable<scheduleBACnetDescription> {
    this.traceService.debug(TraceModules.bxServicesSchedules, `ScheduleService.getSchedule() called: partitionId: ${partitionId},
    scheduleId: ${scheduleId}`);
    
    return this.scheduleProxyService.getSchedule(partitionId, scheduleId);
  }

  private filterSchedules(schedules: scheduleCollectionElement[], name?: string): scheduleCollectionElement[] {
    if (name) {
      const found = this.findSchedule(schedules, name);
      return found ? [found] : [];
    } else {
      return schedules;
    }
  }

  private findSchedule(scheduleData: scheduleCollectionElement[], name: string): scheduleCollectionElement | undefined {
    return scheduleData.find(item => item.type === 'ScheduleCollectionElement');
  }
}
