import { Injectable } from "@angular/core";
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from "@gms-flex/services";
import { isNullOrUndefined } from "@siemens/ngx-datatable";
import { PointValue } from "src/app/bx-services/point/point-proxy.model";

import { DccDataType } from "../point-type.service";
import { PropertyMapper } from "./property-mapper";

export const commandedWithPrioPropertyName = 'CommandedWithPrio';

@Injectable({
  providedIn: 'root'
})
export class CmdWithPrioMapperService extends PropertyMapper {

  /* eslint-disable @typescript-eslint/no-useless-constructor */
  constructor() {
    super();
  }
  /* eslint-enable @typescript-eslint/no-useless-constructor */

  public createIsCommandedWithPriority(order: number): PropertyDetails {
    const propDetails: PropertyDetails = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PropertyName: commandedWithPrioPropertyName,
      Descriptor: 'Commanded with priority',
      DisplayType: 0,
      IsArray: false,
      Order: order,
      Resolution: undefined,
      Type: DccDataType.BasicBool,
      Value: undefined,
      Min: undefined,
      Max: undefined,
      UnitDescriptor: undefined,
      UnitId: undefined,
      Usage: 3, // Show only in basic and extended properties (Bit0: Show in basic properties, Bit1: Show in extended properties)
      TextTable: undefined,
      PropertyType: 0 // Type = 0,Indexed = 1,Functions = 2
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return propDetails;
  }

  public createCommandedWithPriorityValue(subGms: SubscriptionGmsVal, pointValue: PointValue): ValueDetails {
    // TODO: finalize quality bits
    // let displayValue = ((!isNullOrUndefined(pointValue?.targetValue)) && (pointValue?.targetValue !== '')) ? 'true' : 'false';

    let displayValue = '#NaN';
    if (!isNullOrUndefined(pointValue)) {
      if (this.showDisplayValue(pointValue.qualityOfValue)) {
        displayValue = ((!isNullOrUndefined(pointValue?.targetValue)) && (pointValue?.targetValue !== '')) ? 'true' : 'false';
      }

      // TODO: properly display quality state!
      if (pointValue.qualityOfValue === 2) {
        displayValue = '#COM';
      }
      if (pointValue.qualityOfValue === 3) {
        displayValue = '#ENG';
      }
    }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      DataType: DccDataType.BasicBool,
      ErrorCode: subGms.errorCode,
      SubscriptionKey: subGms.key,
      IsArray: false,
      Value: {
        Value: displayValue,
        DisplayValue: displayValue,
        Timestamp: pointValue?.createdAt,
        QualityGood: this.showDisplayValue(pointValue?.qualityOfValue),
        // QualityGood: pointValue?.qualityOfValue ? (pointValue.qualityOfValue === 0) : true,
        Quality: '0'
      }
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}
