import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlarmConfigAttributes, AlarmConfigBx, TraceModules } from "@gms-flex/services"
import { TraceService } from "@gms-flex/services-common";
import { catchError, map, Observable } from "rxjs";

import { environment } from "../../../environments/environment";
import { HttpUtilityService } from "../shared/http-utility.service";

const pointUrl = `${environment.bxPlatform.pointVerticalApiUrl}/v3/partitions`

@Injectable({
  providedIn: 'root'
})
export class AlarmConfigurationProxyService {
  public constructor(
    private readonly traceService: TraceService,
    private readonly httpClient: HttpClient,
    private readonly httpUtilityService: HttpUtilityService) {

    this.traceService.info(TraceModules.events, 'AlarmConfigurationProxyService created.');
  }

  public getAlarmConfigurations(partitionId: string, pointId: string): Observable<AlarmConfigBx[]> {
    this.traceService.debug(TraceModules.events, `AlarmConfigurationProxyService.getAlarmConfigurations() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrl}/${partitionId}/points/${pointId}/alarm-configurations`;

    return this.httpClient.get(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'getAlarmConfigurations()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'getAlarmConfigurations()')));
  } 

  public updateAlarmConfigurations(
    partitionId: string,
    pointId: string,
    updatedConfiguration: AlarmConfigBx
  ): Observable<AlarmConfigBx> {
    this.traceService.debug(TraceModules.events, `AlarmConfigurationProxyService.updateAlarmConfigurations() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrl}/${partitionId}/points/${pointId}/alarm-configurations/${updatedConfiguration.id}`;

    const body = { data: updatedConfiguration };

    return this.httpClient.patch(url, body, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'updateAlarmConfigurations()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'updateAlarmConfigurations()')));
  }

  public deleteAlarmConfigurations(partitionId: string, pointId: string, configurationId: string): Observable<boolean> {
    this.traceService.debug(TraceModules.events, `AlarmConfigurationProxyService.deleteAlarmConfigurations() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrl}/${partitionId}/points/${pointId}/alarm-configurations/${configurationId}`;

    return this.httpClient.delete(url, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => { return response.ok; }),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'deleteAlarmConfigurations()')));
  } 

  public createAlarmConfigurations(
    partitionId: string,
    pointId: string,
    configurationAttributes: AlarmConfigAttributes
  ): Observable<AlarmConfigBx> {
    this.traceService.debug(TraceModules.events, `AlarmConfigurationProxyService.createAlarmConfigurations() called: partitionId: ${partitionId}`);
    const headers: HttpHeaders = this.httpUtilityService.httpGetDefaultHeader();
    const url = `${pointUrl}/${partitionId}/points/${pointId}/alarm-configurations`;

    const body = { data: { type: 'AlarmConfiguration', attributes: configurationAttributes } };

    return this.httpClient.post(url, body, { headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => this.httpUtilityService.extractData(response, 'createAlarmConfigurations()')),
      catchError((response: HttpResponse<any>) => this.httpUtilityService.handleError(response, 'createAlarmConfigurations()')));
  }
}
