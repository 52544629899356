import { Injectable } from "@angular/core";
import { TraceModules } from '@gms-flex/services';
import { TraceService } from "@gms-flex/services-common";
import { PointAttributes } from "src/app/bx-services/point/point-proxy.model";

import { isNumericString } from "../../core/shared/string-utils";

export const enum DccDataType {
  BasicBool = 'BasicBool',
  BasicFloat = 'BasicFloat',
  BasicInt = 'BasicInt',
  BasicUint = 'BasicUint',
  BasicString = 'BasicString',
  ExtendedReal = 'ExtendedReal',
  ExtendedBool = 'ExtendedBool',
  ExtendedInt = 'ExtendedInt',
  ExtendedEnum = 'ExtendedEnum',
  ExtendedBitString = 'ExtendedBitString'
}

/* eslint-disable id-blacklist */
export const enum PointDataType {
  Number = 'number',
  Boolean = 'boolean',
  Integer = 'integer',
  String = 'string'
}
/* eslint-enable id-blacklist */

export const pointUnitUnitless = 'qudt:UNITLESS'

@Injectable({
  providedIn: 'root'
})

export class PointTypeService {

  constructor(private readonly traceService: TraceService) { 
    this.traceService.info(TraceModules.property, 'PointTypeService created.');
  }

  public getDccType(pointAttributes: PointAttributes): DccDataType {

    switch (pointAttributes?.dataType) {
      case PointDataType.Number: {
        return this.handlePointDataTypeNumber(pointAttributes);
      }
      case PointDataType.Integer: {
        return this.handlePointDataTypeInteger(pointAttributes);
      }
      case PointDataType.Boolean: {
        return this.handlePointDataTypeBoolean(pointAttributes);
      }
      case PointDataType.String: {
        return this.handlePointDataTypeString();
      }
      case undefined: {
        // Not an error to trace: pointAttributes equal to undefined is a valid option to indicate that the point has no attributes
        return DccDataType.BasicString;
      }
      default: {
        this.traceService.error(TraceModules.tables, `PointTypeService.getDccType(): Point data type not handled: ${pointAttributes?.dataType}`);
        return DccDataType.BasicString;
      }
    }
  }

  private handlePointDataTypeNumber(pointAttributes: PointAttributes): DccDataType {
    if (this.pointHasAttributes(pointAttributes)) {
      return DccDataType.ExtendedReal;
    }
    return DccDataType.BasicFloat;
  }

  private handlePointDataTypeInteger(pointAttributes: PointAttributes): DccDataType {
    if (this.pointIsEnum(pointAttributes)) {
      return DccDataType.ExtendedEnum;
    }
    if (this.pointHasAttributes(pointAttributes)) {
      return DccDataType.ExtendedInt;
    }
    return DccDataType.BasicInt;
  }

  private handlePointDataTypeBoolean(pointAttributes: PointAttributes): DccDataType {
    if (this.pointIsEnum(pointAttributes)) {
      return DccDataType.ExtendedBool;
    }
    return DccDataType.BasicBool;
  }

  private handlePointDataTypeString(): DccDataType {
    return DccDataType.BasicString;
  }

  private pointHasAttributes(pointAttributes: PointAttributes): boolean {
    return (pointAttributes?.unit !== undefined && pointAttributes?.unit !== pointUnitUnitless) || // point has unit
      isNumericString(pointAttributes?.minimum) || // point has minimum
      isNumericString(pointAttributes?.maximum) // point has maximum
  }

  private pointIsEnum(pointAttributes: PointAttributes): boolean {
    return pointAttributes?.enum !== undefined;
  }
}
