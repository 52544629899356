import { NgModule } from '@angular/core';
import { IPreselectionService, IStorageService } from '@gms-flex/core';

import { CBmsSchedulePreselectService } from './cbms-schedule-preselect.service';
import { CBmsScheduleStorageService } from './cbms-schedule-storage.service';

@NgModule({
  providers: [{ provide: IStorageService, useClass: CBmsScheduleStorageService, multi: true },
    { provide: IPreselectionService, useClass: CBmsSchedulePreselectService, multi: true }]
})
export class CBmsScheduleRootServicesModule {}
