import { Injectable } from '@angular/core';
import { ValidateOpResponse, ValidationCommandOpRepresentation, ValidationCredentialRepresentation, ValidationEditOpRepresentation } from '@gms-flex/services';
import {
  TraceModules,
  TraceService
} from '@gms-flex/services-common';
import { asapScheduler, Observable, of, scheduled, throwError } from 'rxjs';

@Injectable()
export class ValidationHelperBxSubstituteService {

  public constructor(
    public readonly traceService: TraceService
  ) {
    this.traceService.info(TraceModules.utilities, 'ValidationHelperBxSubstituteService created.');
  }

  public canAccountsUseValidation(userName: string, superName: string): Observable<Map<string, boolean>> {
    const map: Map<string, boolean> = new Map<string, boolean>();
    map.set(userName, false);
    map.set(superName, false);
    return of(map);
  }

  public getCanUseValidation(userName: string): Observable<boolean> {
    return of(false);
  }

  public getCommandValidationOperation(validateCommandOpRepresentation: ValidationCommandOpRepresentation): Observable<ValidateOpResponse> {
    /* eslint-disable @typescript-eslint/naming-convention */
    const validateOpRes: ValidateOpResponse = {
      CommentRule: 'Optional',
      IncrVersion: false,
      IsFourEyesEnabled: false,
      Log: false,
      PredefinedComments: [],
      ReAuthentication: 'NoNeed'
    };
    /* eslint-enable @typescript-eslint/naming-convention */
    // return of(validateOpRes);
    return scheduled([validateOpRes], asapScheduler);
  }

  public getEditValidationOperation(validationEditOpRepresentation: ValidationEditOpRepresentation): Observable<ValidateOpResponse> {
    /* eslint-disable @typescript-eslint/naming-convention */
    const validateOpRes: ValidateOpResponse = {
      CommentRule: 'Optional',
      IncrVersion: false,
      IsFourEyesEnabled: false,
      Log: false,
      PredefinedComments: [],
      ReAuthentication: 'NoNeed'
    };
      /* eslint-enable @typescript-eslint/naming-convention */
      // return of(validateOpRes);
    return scheduled([validateOpRes], asapScheduler);
  }

  public validateCredential(validationCredentialRepresentation: ValidationCredentialRepresentation): Observable<any> {
    return throwError(() => new Error('ValidationHelperBxSubstituteService.validateCredential(): Not Implemented!'));
  }
}
