import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlarmConfigAttributes, MultiStatePointConfiguration } from "@gms-flex/services";
import { SelectOption } from "@simpl/element-ng";
import { PointBxWithValue } from "src/app/bx-services/point/point.model";

import { PointTypeService } from "../../../bx-gms-mapper/properties/point-type.service";
import { getStatesOptions } from "../shared/point-helper";

@Component({
  selector: "point-alarm-multistate",
  templateUrl: "./point-alarm-multistate.component.html",
  styleUrl: "./point-alarm-multistate.component.scss"
})
export class PointAlarmMultistateComponent implements OnInit {
  @Input() public pointAlarmConfig: AlarmConfigAttributes;
  @Input() public point: PointBxWithValue;
  @Input() public translatedLabels: Map<string, string>;
  @Output() public readonly newMultistateEvent = new EventEmitter<MultiStatePointConfiguration>();

  public value: string[];
  public optionsList: SelectOption[] = [];
  public alarmData: MultiStatePointConfiguration;

  constructor(private readonly pointTypeService: PointTypeService) { }

  public ngOnInit(): void {
    this.alarmData = (this.pointAlarmConfig.configuration as MultiStatePointConfiguration) ?? undefined;
    this.value = this.alarmData?.multiStatePointAlarmConfiguration.alarmValues;
    this.optionsList = getStatesOptions(this.pointTypeService, this.point);
  }

  public onSelect(values: any): void {
    const valueToEmit: MultiStatePointConfiguration = {
      multiStatePointAlarmConfiguration: {
        alarmValues: values
      }
    }
    this.newMultistateEvent.emit(valueToEmit);
  }
}
