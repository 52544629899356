import { Injectable } from '@angular/core';
import { PropertyDetails, SubscriptionGmsVal, ValueDetails } from '@gms-flex/services';

import { PointAttributes, PointValue } from '../../bx-services/point/point-proxy.model';
import { BacCurrentPrioMapperService } from './property-mappers/bac-current-prio-mapper.service';
import { BacPrioArrayMapperService, bacPrioArrayPropertyName } from './property-mappers/bac-prio-array-mapper.service';
import { BacStatusFlagsMapperService, bacStatusPropertyName } from './property-mappers/bac-status-flags-mapper.service';
import { CmdWithPrioMapperService, commandedWithPrioPropertyName } from './property-mappers/cmd-with-prio-mapper.service';
import { pointPropertyName, PointValueMapperService } from './property-mappers/point-value-mapper.service';
import { aggregatedSummaryPropertyName, SummaryStatusMapperService } from './property-mappers/summary-status-mapper.service';

const displayCommandedWithPrioProperty = false;

@Injectable({
  providedIn: 'root'
})
export class PropertyMapperBxToGmsService {
  private readonly optionalPropertyNames: Map<string, string> = new Map<string, string>();

  constructor(
    private readonly pointValueService: PointValueMapperService,
    private readonly bacStatusFlagsService: BacStatusFlagsMapperService,
    private readonly bacPrioArrayService: BacPrioArrayMapperService,
    private readonly bacCurrentPrioService: BacCurrentPrioMapperService,
    private readonly cmdWithPrioService: CmdWithPrioMapperService,
    private readonly summaryStatusService: SummaryStatusMapperService) {

    this.optionalPropertyNames.set(bacStatusPropertyName, bacStatusPropertyName);
    this.optionalPropertyNames.set(bacPrioArrayPropertyName, bacPrioArrayPropertyName);
  }

  public isOptionalProperty(propertyName: string): boolean {
    return this.optionalPropertyNames.has(propertyName);
  }

  public getPropertyNamesForPoint(): string[] {
    return [
      pointPropertyName,
      commandedWithPrioPropertyName,
      bacStatusPropertyName,
      bacPrioArrayPropertyName,
      aggregatedSummaryPropertyName // valid property because textual viewer and system browser expect it to be existing.
    ];
  }

  public createPropertyDetails(pointId: string, pointAttributes: PointAttributes): PropertyDetails[] {
    const properties: PropertyDetails[] = [];
    let order = 0;
    properties.push(this.createPointValueProperty(pointId, pointAttributes, order++));
    properties.push(this.createBacStatusProperty(order++));
    properties.push(this.createBacCurrentPrioProperty(order++));
    properties.push(this.createBacPrioArrayProperty(pointAttributes, order++));
    properties.push(this.createAggregatedSummaryProperty(order++));
    if (displayCommandedWithPrioProperty) {
      properties.push(this.createIsCommandedWithPriorityProperty(order++));
    }
    return properties;
  }

  public createPointValueProperty(pointId: string, pointAttributes: PointAttributes, order: number): PropertyDetails {
    return this.pointValueService.createPointProperty(pointId, pointAttributes, order);
  }

  public createPointValue(subGms: SubscriptionGmsVal, pointId: string, pointValue: PointValue, pointAttributes: PointAttributes): ValueDetails {
    return this.pointValueService.createValue(subGms, pointId, pointValue, pointAttributes);
  }

  public createIsCommandedWithPriorityProperty(order: number): PropertyDetails {
    return this.cmdWithPrioService.createIsCommandedWithPriority(order);
  }

  public createCommandedWithPriorityValue(subGms: SubscriptionGmsVal, pointValue: PointValue): ValueDetails {
    return this.cmdWithPrioService.createCommandedWithPriorityValue(subGms, pointValue);
  }

  public createBacStatusProperty(order: number): PropertyDetails {
    return this.bacStatusFlagsService.createBacStatus(order);
  }

  public createBacStatusValue(subGms: SubscriptionGmsVal, pointValue: PointValue, hide: boolean = false): ValueDetails | undefined {
    return this.bacStatusFlagsService.createBacStatusValue(subGms, pointValue, hide);
  }

  public createBacPrioArrayProperty(pointAttributes: PointAttributes, order: number): PropertyDetails {
    return this.bacPrioArrayService.createBacPrioArray(pointAttributes, order);
  }

  public createBacCurrentPrioProperty(order: number): PropertyDetails {
    return this.bacCurrentPrioService.createCurrentPrio(order);
  }

  public createBacCurrentPrioValue(subGms: SubscriptionGmsVal, pointValue: PointValue, hide: boolean = false): ValueDetails | undefined {
    return this.bacCurrentPrioService.createBacCurrentPrioValue(subGms, pointValue, hide);
  }

  public createBacPrioArrayValue(
    subGms: SubscriptionGmsVal, pointValue: PointValue, pointAttributes: PointAttributes, hide: boolean = false): ValueDetails | undefined {
    return this.bacPrioArrayService.createBacPrioArrayValue(subGms, pointValue, pointAttributes, hide);
  }

  public createAggregatedSummaryProperty(order: number): PropertyDetails {
    return this.summaryStatusService.createAggregatedSummary(order);
  }

  public createAggregatedSummaryValue(
    subGms: SubscriptionGmsVal, pointValue: PointValue, pointAttributes: PointAttributes, hide: boolean = false): ValueDetails | undefined {
    return this.summaryStatusService.createAggregatedSummaryValue(subGms, pointValue, pointAttributes, hide);
  }
}
