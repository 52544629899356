import { Injectable } from '@angular/core';
import { FullSnapInId, IStorageService } from '@gms-flex/core';
import { TraceService } from '@gms-flex/services-common';

import { traceModule } from './schedule-preselect.service';
import { ISingleInstanceStoreMap, SingleInstanceStore } from './single-instance-store';

@Injectable()
export class ScheduleStorageService implements IStorageService {

  public storesMap: ISingleInstanceStoreMap = {};

  public value = 0;

  public typeId = 'ScheduleSnapInType';

  private readonly state: Map<string, any> = new Map<string, any>();

  private readonly dirtyState: Map<string, boolean> = new Map<string, boolean>();

  /*
   * Stores predefined message types.
   */
  private readonly _predefinedTypes: any[] = [
    { 'displayName': '43' }, // Graphics
    { 'displayName': '50' }, // Reports
    { 'displayName': '56' }, // Application
    { 'displayName': '73' } // Documents

  ];

  constructor(private readonly traceService: TraceService) {
    this.traceService.info(traceModule, 'ScheduleStorageService created.');
  }

  public getStore(sender: FullSnapInId): SingleInstanceStore {
    if (sender != null) {
      const senderId: string = sender.fullId();
      if (this.storesMap[senderId] == null) {
        this.storesMap[senderId] = new SingleInstanceStore();
      }
      return this.storesMap[senderId];
    }
    return null;
  }

  public getPredefinedTypes(): any[] {
    return this._predefinedTypes;
  }

  public count(): number {
    return this.value++;
  }

  public getState(fullId: FullSnapInId): any {
    // read saved data
    this.traceService.info(traceModule, 'ScheduleStorageService getState: ', fullId.fullId());
    if (fullId != null) {
      if (this.state.has(fullId.fullId())) {
        this.traceService.info(traceModule, this.state.get(fullId.fullId()));
        return this.state.get(fullId.fullId());
      }
    }
  }

  public setState(fullId: FullSnapInId, state: any): void {
    // save values
    if (state != null) {
      this.traceService.info(traceModule, 'ScheduleStorageService setState: ', fullId.fullId(), state);
      this.state.set(fullId.fullId(), state);
    }
  }

  public clearState(fullId: FullSnapInId): void {
    // reset values
    this.traceService.info(traceModule, 'ScheduleStorageService clearState: ', fullId.fullId());
    if (fullId != null) {
      if (this.state.has(fullId.fullId())) {
        this.state.delete(fullId.fullId());
      }
    }
  }

  public getDirtyState(fullId: FullSnapInId): boolean {
    this.traceService.info(traceModule, 'ScheduleStorageService getDirtyState: ', fullId.fullId());
    if (fullId && this.dirtyState.has(fullId.fullId())) {
      return this.dirtyState.get(fullId.fullId());
    }
  }

  public setDirtyState(fullId: FullSnapInId, state: boolean): void {
    if (state != null) {
      this.traceService.info(traceModule, 'ScheduleStorageService setDirtyState: ', fullId.fullId(), state);
      this.dirtyState.set(fullId.fullId(), state);
    }
  }
}
