import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';
import { catchError, concatMap, map, Observable, of, share, tap } from 'rxjs';
import { TraceModules } from 'src/app/core/shared/trace-modules';

import { EntityType } from '../shared/base.model';
import { useCache, useMulticast } from '../shared/http-utility.service';
import { PointBx, PointBxValue, PointBxValueV2, PointGroupType, PointSourceType, PointValuesBulkResponse } from './point-proxy.model';
import { PointProxyService } from './point-proxy.service';
import { PointBxWithValue } from './point.model';

@Injectable({
  providedIn: 'root'
})
export class PointService {
  private readonly pointsPerPartitionAndParentEntity: Map<string, Map<string, PointBx[]>> = new Map<string, Map<string, PointBx[]>>();
  private readonly pointsWithValPerPartitionAndId: Map<string, Map<string, PointBxWithValue>> = new Map<string, Map<string, PointBxWithValue>>();
  private readonly pointsPerPartitionAndId: Map<string, Map<string, PointBx>> = new Map<string, Map<string, PointBx>>();
  private readonly multiCastPgObs: Map<string, Observable<PointBx[]>> = new Map<string, Observable<PointBx[]>>();

  public constructor(
    private readonly traceService: TraceService,
    private readonly pointProxy: PointProxyService) {

    this.traceService.info(TraceModules.bxServicesPoints, 'PointService created.');
  }

  public getPoints(partitionId: string, pgId: string, pgType?: PointGroupType, pointName?: string): Observable<PointBx[]> {
    if ((useCache) && (this.pointsPerPartitionAndParentEntity.has(partitionId) && (this.pointsPerPartitionAndParentEntity.get(partitionId).has(pgId)))) {
      const points = this.pointsPerPartitionAndParentEntity.get(partitionId).get(pgId);
      const filtered = this.filterPoints(points, pointName);
      this.traceService.debug(TraceModules.bxServicesPoints, `PointService.getPoints() returned: no of points: ${filtered.length} from cache
        partitionId=${partitionId}, pgId=${pgId}, pointName=${pointName}`);
      return of(filtered);
    } else {
      // using multicast to support parallel calls to read children (points only) of the tree.
      if ((useMulticast) && (this.multiCastPgObs.has(pgId))) {
        return this.multiCastPgObs.get(pgId);
      }
      const pgObs: Observable<PointBx[]> = this.pointProxy.getPoints(partitionId, pgId, pgType).pipe(
        map(result => {
          // TODO: this workaround must be implemented properly!!!
          this.replaceSeparators(result);
          return result;
        }),
        tap(result => {
          if (this.pointsPerPartitionAndParentEntity.has(partitionId) === false) {
            this.pointsPerPartitionAndParentEntity.set(partitionId, new Map<string, PointBx[]>());
          }
          this.pointsPerPartitionAndParentEntity.get(partitionId).set(pgId, result);

          if (this.pointsPerPartitionAndId.has(partitionId) === false) {
            this.pointsPerPartitionAndId.set(partitionId, new Map<string, PointBx>());
          }
          result.forEach(point => this.pointsPerPartitionAndId.get(partitionId).set(point.id, point));
        }),
        map(result => this.filterPoints(result, pointName)),
        tap(points => {
          this.traceService.debug(TraceModules.bxServicesPoints, `PointService.getPoints() returned: no of points: ${points.length} from backend
            partitionId=${partitionId}, pgId=${pgId}, pointName=${pointName}`);
        })
      );
      if (useMulticast) {
        const multicastObs = pgObs.pipe(share());
        this.multiCastPgObs.set(pgId, multicastObs);
        return multicastObs;
      } else {
        return pgObs;
      }
    }
  }

  public getPointById(partitionId: string, pointId: string): Observable<PointBx | undefined> {
    if ((useCache) && (this.pointsPerPartitionAndId.has(partitionId) && (this.pointsPerPartitionAndId.get(partitionId).has(pointId)))) {
      return of(this.pointsPerPartitionAndId.get(partitionId).get(pointId));
    } else {
      // Note: Currently only this API supports reading single point definitions
      return this.pointProxy.getPointAndValueById(partitionId, pointId).pipe(
        map(pointV2 => {
          // TODO: this workaround must be implemented properly!!!
          this.replaceSeparatorsV2(pointV2);
          return new PointBxWithValue(pointV2);
        }),
        tap(pointWithVal => {
          if (this.pointsPerPartitionAndId.has(partitionId) === false) {
            this.pointsPerPartitionAndId.set(partitionId, new Map<string, PointBxWithValue>());
          }
          this.pointsPerPartitionAndId.get(partitionId).set(pointId, pointWithVal);
        }),
        catchError(error => {
          // No error shall be thrown, instead the result shall be empty
          this.traceService.warn(TraceModules.bxServicesPoints, `PointService.getPointById(): Point not found: ${pointId}, error=${error}`);
          if (this.pointsPerPartitionAndId.has(partitionId) === false) {
            this.pointsPerPartitionAndId.set(partitionId, new Map<string, PointBxWithValue>());
          }
          this.pointsPerPartitionAndId.get(partitionId).set(pointId, undefined);
          return of(undefined);
        })
      );
    }
  }

  public getPointAndValueById(partitionId: string, pointId: string, updateCache: boolean = false): Observable<PointBxWithValue | undefined> {
    if (((updateCache === false) && (useCache) &&
    (this.pointsWithValPerPartitionAndId.has(partitionId) && (this.pointsWithValPerPartitionAndId.get(partitionId).has(pointId))))) {
      return of(this.pointsWithValPerPartitionAndId.get(partitionId).get(pointId));
    } else {
      return this.pointProxy.getPointAndValueById(partitionId, pointId).pipe(
        map(pointV2 => {
          // TODO: this workaround must be implemented properly!!!
          this.replaceSeparatorsV2(pointV2);
          return new PointBxWithValue(pointV2);
        }),
        tap(pointWithVal => {
          if (this.pointsWithValPerPartitionAndId.has(partitionId) === false) {
            this.pointsWithValPerPartitionAndId.set(partitionId, new Map<string, PointBxWithValue>());
          }
          this.pointsWithValPerPartitionAndId.get(partitionId).set(pointId, pointWithVal);
        }),
        catchError(error => {
          // No error shall be thrown, instead the result shall be empty
          this.traceService.warn(TraceModules.bxServicesPoints, `PointService.getPointAndValueById(): Point not found: ${pointId}, error=${error}`);
          if (this.pointsWithValPerPartitionAndId.has(partitionId) === false) {
            this.pointsWithValPerPartitionAndId.set(partitionId, new Map<string, PointBxWithValue>());
          }
          this.pointsWithValPerPartitionAndId.get(partitionId).set(pointId, undefined);
          return of(undefined);
        })
      );
    }
  }

  public getPointValuesBulk(partitionId: string, pointIds: string[]): Observable<PointBxValue[]> {
    return this.pointProxy.getPointValuesBulk(partitionId, pointIds).pipe(
      map(response => this.mapBulkValues(pointIds, response))
    );
  }

  public getPointValues(partitionId: string, pgId: string, pgType?: PointGroupType): Observable<PointBxValue[]> {
    return this.pointProxy.getPointValues(partitionId, pgId, pgType);
  }

  public updatePointValue(partitionId: string, pointId: string, value: string | null): Observable<boolean> {
    return this.getPointById(partitionId, pointId).pipe(
      concatMap(pointBx => this.updatePointValue2(partitionId, pointId, pointBx.attributes.source.type, value))
    );
  }

  public updatePointValue2(partitionId: string, pointId: string, pointSourceType: PointSourceType, value: string | null): Observable<boolean> {
    return this.pointProxy.updatePointValue(partitionId, pointId, pointSourceType, value);
  }

  private mapBulkValues(pointIds: string[], bulkResponse: PointValuesBulkResponse): PointBxValue[] {
    const responseIds: Map<string, string> = new Map<string, string>();
    bulkResponse.lastValues?.forEach(val => responseIds.set(val.id, val.id));

    const missingIds: Map<string, string> = new Map<string, string>();
    pointIds.forEach(reqId => {
      if (!responseIds.has(reqId)) {
        missingIds.set(reqId, reqId);
      }
    });

    const errIds: Map<string, string> = new Map<string, string>();
    bulkResponse.errors?.forEach(errId => errIds.set(errId.id, errId.id));

    const responseValues: PointBxValue[] = [];
    bulkResponse.lastValues?.forEach(val => responseValues.push({ id: val.id, type: EntityType.Point, attributes: { lastValue: val } }));
    missingIds.forEach(id => responseValues.push({ id, type: EntityType.Point, attributes: { lastValue: null } }));

    const responseValuesMap: Map<string, PointBxValue> = new Map<string, PointBxValue>();
    responseValues.forEach(val => responseValuesMap.set(val.id, val));
    errIds.forEach(errId => {
      if (responseValuesMap.has(errId)) {
        responseValuesMap.get(errId).attributes = { lastValue: null };
      }
    });
    
    return responseValues;
  }

  private filterPoints(points: PointBx[], name?: string): PointBx[] {
    if (name) {
      const found = this.findPoint(points, name);
      return found ? [found] : [];
    } else {
      return points;
    }
  }

  private findPoint(pointData: PointBx[], name: string): PointBx | undefined {
    // measured time to search one item out of 10'000: 1ms (on my Lenovo P15 Gen2)
    // this if acceptable.
    return pointData.find(item => item.attributes.name === name);
  }

  private replaceSeparators(pointDef: PointBx[]): void {
    pointDef.forEach(ptDef => {
      ptDef.attributes.name = ptDef.attributes.name.replaceAll('.', '\'');
      ptDef.attributes.description = ptDef.attributes.description?.replaceAll('.', '\'');
    });
  }

  private replaceSeparatorsV2(pointV2: PointBxValueV2): void {
    pointV2.name = pointV2.name.replaceAll('.', '\'');
    pointV2.description = pointV2.description?.replaceAll('.', '\'');
  }

}
