import { PortalModule } from '@angular/cdk/portal';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, LOCALE_ID, NgModule, NgZone, Optional } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AppConfigService, AuthenticationService, OAuthService, SiAODSModule, SiAppDataService, SiAppListService, SiLoginAuthModule, SiMeasurementUnitsModule,
  SiPermissionService, SiProfileService,
  SiqudtUnitConversionPipe, SiSharedModule, SiSubscriptionsModule, SiUOModule
} from '@building-x/common-ui-ng';
import { HfwControlsModule, HfwLoginModule, NgxBootstrapModule } from '@gms-flex/controls';
import { HfwCoreModule } from '@gms-flex/core';
import { GraphicsCommonTemplateService } from '@gms-flex/graphics-viewer-root-services';
import { GmsNavbarModule } from '@gms-flex/navigation-bar';
import { GmsServicesModule, GraphicsCommonTemplateServiceBase } from '@gms-flex/services';
import { HFW_TRANSLATION_FILE_TOKEN, HfwServicesCommonModule, MultiTranslateHttpLoader, TraceService } from '@gms-flex/services-common';
import { AboutPopoverModule, EventsModule, GmsSnapInCommonModule } from '@gms-flex/snapin-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import {
  SiAccordionModule, SiComfortScoreModule, SiContentActionBarModule, SiDropdownModule, SiEmptyStateModule, SiFilteredSearchModule,
  SiIconModule, SiLoadingSpinnerModule, SiMenuLegacyModule, SiNavbarVerticalModule, SiNumberInputModule, SiPopoverModule,
  SiPromptDialogButtonsModule, SiResizeObserverModule, SiSearchBarModule, SiSelectModule, SiSidePanelModule, SiSliderModule,
  SiToastNotificationService,
  SiWizardModule
} from '@simpl/element-ng';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';
import { SimplObjectBrowserNgModule } from '@simpl/object-browser-ng';
import { CookieService } from 'ngx-cookie-service';
import { interval } from 'rxjs';
import { SessionService } from 'src/app/core/services/session.service';
import { environment } from 'src/environments/environment';

import { routing } from './app-routing.module';
import { ApplicationComponent, trcModuleNameApp } from './app.component';
import { BxGmsMapperModule } from './bx-gms-mapper/bx-gms-mapper.module';
import { ContextService } from './bx-gms-mapper/state/context.service';
import { BaseHttpRequest, OpenAPI, OpenAPIConfig } from './bx-services/schedule';
import { AngularHttpRequest } from './bx-services/schedule/core/AngularHttpRequest';
import { LoginComponent } from './core-ui/login/login.component';
// import { CustomerSelectorStateService } from './customer-selection/customer-selector-state.service';
import { GmsLocaleId, GmsLocaleIdService } from './core/services/gms-locale-id.service';
import { CustomerSelectorComponent } from './customer-selection/customer-selector.component';
import { EventSettingsComponent } from './features/event-settings/event-settings.component';
import { PointAlarmAnalogComponent } from './features/event-settings/point-alarm-analog/point-alarm-analog.component';
import { PointAlarmBinaryComponent } from './features/event-settings/point-alarm-binary/point-alarm-binary.component';
import { PointAlarmCategoryComponent } from './features/event-settings/point-alarm-category/point-alarm-category.component';
import { PointAlarmMultistateComponent } from './features/event-settings/point-alarm-multistate/point-alarm-multistate.component';
import { PointAlarmTextComponent } from './features/event-settings/point-alarm-text/point-alarm-text.component';
import { LoadingComponent } from './loading/loading.component';
import { MainComponent } from './main/main.component';
import { NotifyDialogComponent } from './notification-dialog/notify-dialog.component';
import { SytemRPComponent } from './right-panel/sytem-rp/sytem-rp.component';
import { RootServicesModule } from './root-services.module';
// import { SiAccordionModule, SiContentActionBarModule, SiEmptyStateModule, SiSidePanelService, SiSwitchModule } from '@simpl/element-ng';

export const siemensIdAuthenticationFactory = (oAuthService: OAuthService): AuthenticationService => {
  const authS = new AuthenticationService(oAuthService, environment.auth.issuer, environment.auth.msIssuer, environment.profileUrl,
    `${environment.bxPlatform.userMgmtApiUrl}/v2`);
  // authS.init(environment.auth.clientId, environment.auth.issuer, environment.auth.audience, environment.auth.siemensIdBaseUrl);
  return authS;
}

let appTickCounter = 0;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const OpenAPIconfiguration = new InjectionToken<OpenAPIConfig>('OpenAPIConfig');
// export const initContext = (customerSelectorStateService: CustomerSelectorStateService): any => (): any =>
// customerSelectorStateService.initAsync().toPromise();

// export function getAppModule(modulesArray: any): Type<any> {
@NgModule({
  imports: [
    AboutPopoverModule,
    BrowserAnimationsModule,
    BrowserModule,
    EventsModule,
    FormsModule,
    GmsNavbarModule,
    GmsServicesModule,
    GmsSnapInCommonModule.forRoot(),
    HfwControlsModule,
    HfwCoreModule,
    HfwLoginModule,
    HfwServicesCommonModule,
    HttpClientModule,
    NgxBootstrapModule,
    SiAODSModule.forRoot({ apiUrl: `${environment.bxPlatform.aodsApiUrl}/v2` }),
    // SiUserModule,
    // SiUOModule.forRoot({
    //  apiUrl: `${environment.bxPlatform.userOrganizationsApiV1Url}`, apiUrlV2: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
    // SiUOModule.forRoot({ apiUrl: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
    // SiProfileModule.forRoot({
    //   apiUrl: `${environment.bxPlatform.userOrganizationsApiV1Url}`, apiUrlV2: `${environment.bxPlatform.userMgmtApiUrl}/v2/me`
    // }),
    NgxDatatableModule,
    PortalModule,
    ReactiveFormsModule,
    RootServicesModule,
    /* eslint-disable-next-line @angular-eslint/sort-ngmodule-metadata-arrays */
    BxGmsMapperModule, // .forRoot(environment),
    SiMeasurementUnitsModule,
    SiSharedModule,
    routing,
    SiAccordionModule,
    SiComfortScoreModule,
    SiContentActionBarModule,
    SiDropdownModule,
    SiEmptyStateModule,
    SiFilteredSearchModule,
    SiIconModule,
    SiLoadingSpinnerModule,
    SiMenuLegacyModule,
    SimplObjectBrowserNgModule,
    SiNavbarVerticalModule,
    SiNumberInputModule,
    SiPopoverModule,
    SiPromptDialogButtonsModule,
    SiSearchBarModule,
    // SiSharedModule,
    SiSelectModule,
    SiSidePanelModule,
    SiSliderModule,
    NgxDatatableModule,
    SiTranslateNgxTModule,
    SiLoginAuthModule,
    SiSubscriptionsModule.forRoot({
      apiUrl: `${environment.bxPlatform.subscriptionsApiUrl}/v2`
    }),
    SiTranslateNgxTModule,
    SiUOModule.forRoot({ apiUrl: `${environment.bxPlatform.userMgmtApiUrl}/v2` }),
    SiWizardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient, trace: TraceService, prefixOptional: any[]): MultiTranslateHttpLoader =>
          new MultiTranslateHttpLoader(httpClient, trace, './i18n/', prefixOptional),
        deps: [HttpClient, TraceService, [new Optional(), HFW_TRANSLATION_FILE_TOKEN]]
      }
    })
  ],
  declarations: [
    ApplicationComponent,
    CustomerSelectorComponent,
    EventSettingsComponent,
    LoadingComponent,
    LoginComponent,
    MainComponent,
    NotifyDialogComponent,
    PointAlarmAnalogComponent,
    PointAlarmBinaryComponent,
    PointAlarmCategoryComponent,
    PointAlarmMultistateComponent,
    PointAlarmTextComponent,
    SytemRPComponent,
    SytemRPComponent
  ],
  providers: [
    AngularHttpRequest,
    AppConfigService,
    ContextService,
    { provide: 'appSettingFilePath', useValue: 'config/app-settings.json' },
    { provide: 'productSettingFilePath', useValue: 'config/product-settings.json' },
    { provide: GraphicsCommonTemplateServiceBase, useClass: GraphicsCommonTemplateService, multi: false },
    CookieService,
    SiAppDataService,
    SiAppListService,
    SiPermissionService,
    SiProfileService,
    SiqudtUnitConversionPipe,
    { provide: OpenAPI, useValue: OpenAPIconfiguration },
    { provide: BaseHttpRequest, useClass: AngularHttpRequest },
    { provide: LOCALE_ID, useClass: GmsLocaleId, deps: [GmsLocaleIdService, TraceService] },
    {
      provide: AuthenticationService,
      useFactory: siemensIdAuthenticationFactory,
      deps: [OAuthService]
    },
    
    // { provide: APP_INITIALIZER, multi: true, useFactory: initContext, deps: [CustomerSelectorStateService, AuthenticationService] },
    SiToastNotificationService
  ],
  bootstrap: [ApplicationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ApplicationModule {
  constructor(applicationRef: ApplicationRef,
    private readonly ngZone: NgZone,
    private readonly sessionService: SessionService,
    private readonly traceService: TraceService) {
    // no proper? needs to called in guards for the navigation?
    this.sessionService.initialize();
    if (traceService.isDebugEnabled(trcModuleNameApp)) {
      const originalTick: () => void = applicationRef.tick;
      applicationRef.tick = function (): any {
        const start: number = performance.now();
        /* eslint-disable-next-line prefer-rest-params*/
        const retValue: any = originalTick.apply(this, arguments);
        const end: number = performance.now();
        appTickCounter = appTickCounter + 1;
        traceService.debug(trcModuleNameApp, 'Application.tick() time: ' + (end - start));
        return retValue;
      };

      this.ngZone.runOutsideAngular(() => {
        interval(5000).subscribe(value => this.onInstrumentationTimer(value));
      });
    }
  }

  private onInstrumentationTimer(counter: number): void {
    if (this.traceService.isDebugEnabled(trcModuleNameApp)) {
      this.traceService.debug(trcModuleNameApp, 'Number of application ticks per second: ' + appTickCounter / 5);
    }
    appTickCounter = 0;
  }
}
