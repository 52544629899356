/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { OneTrustCookieTypes, Partition, SiCookiePolicyService, SiCookiesService } from '@building-x/common-ui-ng';
import { Customer } from '@building-x/common-ui-ng/uo-vertical/models/customer.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ContextService {
  constructor(private readonly cookiePolicyService: SiCookiePolicyService, private readonly cookieService: SiCookiesService) {}

  private readonly selectedCustomerBehaviorSubject$ = new BehaviorSubject<Customer>(null);
  public selectedCustomer$ = this.selectedCustomerBehaviorSubject$.asObservable();
  public set selectedCustomer(customer: Customer) {
    // Save the orgId so that pressing "Siemens" or "Operations Manager" sends user to last org visited
    // sessionStorage.setItem('custId', customer?.id);
    if (this.cookiePolicyService.permitted(OneTrustCookieTypes.Required)) {
      const cookieExpiry = new Date();
      cookieExpiry.setFullYear(cookieExpiry.getFullYear() + 1);
      this.cookieService.setCookie('custId', customer?.id, '', cookieExpiry.toUTCString());
    }
    this.selectedCustomerBehaviorSubject$.next(customer);
  }
  public get selectedCustomer(): Customer {
    return this.selectedCustomerBehaviorSubject$.value;
  }

  private readonly selectedPartitionBehaviorSubject$ = new BehaviorSubject<Partition[]>([]);
  public selectedPartitions$ = this.selectedPartitionBehaviorSubject$.asObservable();
  public set selectedPartitions(partitions: Partition[]) {
    this.selectedPartitionBehaviorSubject$.next(partitions);
  }
  public get selectedPartitions(): Partition[] {
    return this.selectedPartitionBehaviorSubject$.value;
  }

  private readonly currentPartitionBehaviorSubject$ = new BehaviorSubject<Partition>(null);
  public currentPartition$ = this.currentPartitionBehaviorSubject$.asObservable();
  public set currentPartition(partition: Partition) {
    // Save the orgId so that pressing "Siemens" or "Operations Manager" sends user to last org visited
    sessionStorage.setItem('currentPartition', partition?.id);

    this.currentPartitionBehaviorSubject$.next(partition);
  }
  public get currentPartition(): Partition {
    return this.currentPartitionBehaviorSubject$.value;
  }
}
