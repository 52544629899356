import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarServiceBase, TraceModules } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';
import { Observable } from 'rxjs';

import { calendarBACnetDescription, CalendarService, ontology } from '../../bx-services/schedule';
import { SystemBrowserMapperBxToGmsService } from '../system-browser/system-browser-mapper-bx-to-gms.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarBxSubstituteService extends CalendarServiceBase {

  /**
   * Constructor
   * @param {TraceService } traceService The trace service
   * @param {HttpClient } httpClient  The http service
   * @param {WsiEndpointService } wsiEndpoint The WSI endpoint service.
   */
  public constructor(
    public traceService: TraceService,
    protected httpClient: HttpClient,
    private readonly calendarService: CalendarService,
    private readonly systemBrowserMapper: SystemBrowserMapperBxToGmsService) {
    super();
    this.traceService.info(TraceModules.scheduler, 'scheduler service created.');
  }
  
  public getCalendar(objectId: string): Observable<calendarBACnetDescription> {
    this.traceService.info(TraceModules.scheduler, 'getCalendar() called for: ' + objectId);

    const substrings = objectId.split(':');
    return this.calendarService.getCalendar(substrings[0], substrings[1]);
  }

  public saveCalendar(calendar: calendarBACnetDescription): Observable<boolean> {
    const substrings = calendar.id.split(':');
    return this.calendarService.saveCalendar(substrings[0], substrings[1], calendar);
  }

}
