import { Injectable, NgZone } from '@angular/core';
import { ConnectionState, EventProxyServiceBase, Page, SystemBrowserServiceBase, TraceModules, ValidationInput, WSIEvent } from '@gms-flex/services';
import { isNullOrUndefined, TraceService } from '@gms-flex/services-common';
import { asapScheduler, BehaviorSubject, concatMap, map, Observable, of, scheduled, Subject, Subscription, tap, throwError, timer, zip } from 'rxjs';

import { EventBx } from '../../bx-services/alarm/events-proxy.model';
import { EventsProxyService } from '../../bx-services/alarm/events-proxy.service';
import { LocationService } from '../../bx-services/location/location.service';
import { ContextService } from '../state/context.service';
import { SystemBrowserBxSubstituteService } from '../system-browser/system-browser-bx-substitute.service';
import { EventMapperBxToGmsService, EventStateGms } from './event-mapper-bx-to-gms.service';

const pollRateEvents = 30000;
const delayEventReadingAtStartup = 100;
const disableEventsReading = false;

export interface EventResponseOfBuilding {
  partitionId: string;
  buildingId: string;
  eventResponse: EventBx[];
}

@Injectable()
export class EventBxSubstituteProxyService extends EventProxyServiceBase {

  private readonly _notifyConnectionState: Subject<ConnectionState> = new Subject<ConnectionState>();
  private readonly _eventsWsi: Subject<WSIEvent[]> = new Subject<WSIEvent[]>();
  private readonly _eventsBuildingX: BehaviorSubject<EventResponseOfBuilding[]> = new BehaviorSubject<EventResponseOfBuilding[]>([]);
  private readonly eventsPerId: Map<string, WSIEvent> = new Map<string, WSIEvent>();
  private readonly partitionIdPerEventId: Map<string, string> = new Map<string, string>();
  private timerSubscription: Subscription;
  private selectedPartitions: string[] = [];
  private readonly sourceFailedToBeResolved: Map<string, string> = new Map<string, string>();
  private subscriberCount = 0;
  private readEventsReqSubscription: Subscription[] = [];
  private resolveObjReqSubscription: Subscription[] = [];

  public constructor(
    private readonly traceService: TraceService,
    private readonly eventsProxy: EventsProxyService,
    private readonly eventMapper: EventMapperBxToGmsService,
    private readonly locationService: LocationService,
    private readonly systemBrowserOmService: SystemBrowserServiceBase,
    private readonly contextService: ContextService,
    private readonly ngZone: NgZone) {
    super();

    asapScheduler.schedule(() => {
      // No real connection state is delivered.  There is no constant streaming channel.
      this._notifyConnectionState.next(ConnectionState.Disconnected);
      this._notifyConnectionState.next(ConnectionState.Connecting);
      this._notifyConnectionState.next(ConnectionState.Connected);
    }, 0);

    this.contextService.selectedPartitions$.subscribe(partitions => {
      if (!isNullOrUndefined(partitions)) {
        this.traceService.info(TraceModules.events,
          `EventBxSubstituteProxyService: Current selected partitions: ${partitions.map(partition => partition.id).join()}`);

        const partitionsToBeDeleted: string[] = [];
        this.selectedPartitions.forEach(selectedPartition => {
          if (partitions.findIndex(newPartition => newPartition.id === selectedPartition) === -1) {
            partitionsToBeDeleted.push(selectedPartition);
          }
        });
        this.clearEventsOfPartitions(partitionsToBeDeleted);

        let delayEventReading = 100;
        if (this.selectedPartitions.length === 0) {
          // at startup, we do delay the event reading... (only POC)
          // TODO: remove? or delay dependent on the current active snapin?
          // TODO: app start up and selecting customer/partitions is separate feature.
          delayEventReading = delayEventReadingAtStartup;
        }
        this.selectedPartitions = [];
        this.selectedPartitions = partitions.map(partition => partition.id);

        this.stopTimerForSubscription();
        if (!disableEventsReading) {
          this.startTimerForSubscription(delayEventReading, true);
        }
      } else {
        this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService: Current selected partitions: No partitions selected`);
        this.clearAllEvents();
        this.selectedPartitions = [];
      }
    });
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService created.');
  }

  public getEvents(): Observable<WSIEvent[]> {
    // TODO: implement properly!
    return of([]);
  }

  public eventsNotification(): Observable<WSIEvent[]> {
    return this._eventsWsi.asObservable();
  }

  public eventsNotificationBuildingX(): Observable<EventResponseOfBuilding[]> {
    return this._eventsBuildingX.asObservable();
  }

  public notifyConnectionState(): Observable<ConnectionState> {
    return this._notifyConnectionState.asObservable();
  }

  public unsubscribeEvents(): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.unSubscribeEvents() called');

    if (this.subscriptionActive) {
      this.subscriberCount--;
    }
    return scheduled([true], asapScheduler);
  }

  // manages bulk commanding when addressing more than 1 event
  public postCommand2Events(evIds: string[], commandId: string, treatmentType?: string, validationInput?: ValidationInput): Observable<boolean> {
    return throwError(() => new Error('EventBxSubstituteProxyService.postCommand2Events(): Not Implemented!'));
  }

  public subscribeEvents(hiddenEvents = false): Observable<boolean> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.subscribeEvents() called.');

    if (this.subscriptionActive === false) {
      this.stopTimerForSubscription();
      if (!disableEventsReading) {
        this.startTimerForSubscription(delayEventReadingAtStartup, true);
      }
    }
    this.subscriberCount++;
    return scheduled([true], asapScheduler);
  }

  public serverClientTimeDiff(isoString: string): Observable<any> {
    this.traceService.info(TraceModules.events, 'EventBxSubstituteProxyService.serverClientTimeDiff() called. timestamp: %s', isoString);

    // TODO: check the semantic and the behavior in the client
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    return of({ NTPt1t0: 10, ServerDateTime: isoString, ClientDateTime: isoString });
  }

  private readAllEvents(partitionIds: string[]): Observable<EventResponseOfBuilding[]> {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService.readAllEvents() called`);

    const getAllBuilding$ = partitionIds.map(partId => this.locationService.getLocationBuilding(partId));
    return zip(getAllBuilding$).pipe(
      concatMap(buildingsPerPartition => {
        const getEventsOfBuilding$: Observable<EventBx[]>[] = [];
        const partitionsOfBuilding: string[] = [];
        const buildingIds: string[] = [];
        for (let idx = 0; idx < buildingsPerPartition.length; idx++) {
          getEventsOfBuilding$.push(...buildingsPerPartition[idx].map(building => this.eventsProxy.getEventsOfBuilding(partitionIds[idx], building.id)));
          partitionsOfBuilding.push(...buildingsPerPartition[idx].map(building => partitionIds[idx]));
          buildingIds.push(...buildingsPerPartition[idx].map(building => building.id));
        }
        return zip(getEventsOfBuilding$).pipe(
          map(eventsPerBuilding => {
            const eventResponseAll: EventResponseOfBuilding[] = [];
            for (let buildingIdx = 0; buildingIdx < eventsPerBuilding.length; buildingIdx++) {
              const eventRes: EventBx[] = (eventsPerBuilding[buildingIdx] !== undefined) ? eventsPerBuilding[buildingIdx] : [];
              eventResponseAll.push({ buildingId: buildingIds[buildingIdx], partitionId: partitionsOfBuilding[buildingIdx], eventResponse: eventRes });
            }
            return eventResponseAll;
          }),
          tap(result => {
            let eventsTotal = 0;
            result.map(eventsPerBuilding => eventsTotal += eventsPerBuilding.eventResponse.length);
            this.traceService.info(TraceModules.events,
              `EventBxSubstituteProxyService.readAllEvents() done;
                total time used: ${performance.now() - startTime} ms; for number of events: ${eventsTotal}`);
          })
        );
      })
    );
  }

  private get subscriptionActive(): boolean {
    return (this.subscriberCount > 0);
  }

  private clearAllEvents(): void {
    // TODO: Consider optimization: clearing shall consider only the added/removed partitions
    if (this.subscriptionActive) {
      const eventsToBeCleared: WSIEvent[] = [];
      this.eventsPerId.forEach((event, id) => {
        eventsToBeCleared.push(event);
      });
      // clear the counters...
      this._eventsBuildingX.next([]);
      // clear the events
      this.notifyClosedEvents(eventsToBeCleared);
    }
  }

  private clearEventsOfPartitions(partitionIds: string[]): void {

    if (this.subscriptionActive && partitionIds.length > 0) {
      const eventsToBeCleared: WSIEvent[] = [];
      this.eventsPerId.forEach((event, id) => {
        if (partitionIds.findIndex(partId => partId === this.partitionIdPerEventId.get(id)) !== -1) {
          eventsToBeCleared.push(event);
        }
      });
      // clear the counters...
      const eventsBldg = this._eventsBuildingX.getValue();
      const remainingEvtsBldg: EventResponseOfBuilding[] = [];
      eventsBldg.forEach(evtBldg => {
        if (partitionIds.findIndex(partId => partId === evtBldg.partitionId) === -1) {
          remainingEvtsBldg.push(evtBldg);
        }
      });

      this._eventsBuildingX.next(remainingEvtsBldg);
      // clear the events
      this.notifyClosedEvents(eventsToBeCleared);
    }
  }

  private onTimerSubscription(): void {
    if (this.selectedPartitions.length > 0) {
      this.readAndResolveEventsAndNotifyInBulk(this.selectedPartitions);
      this.startTimerForSubscription(pollRateEvents, false);
    }
  }

  private readAndResolveEventsAndNotifyInBulk(partitionIds: string[]): void {
    const startTime = performance.now();
    this.traceService.info(TraceModules.events, `EventBxSubstituteProxyService.readAndResolveEventsAndNotifyInBulk() called`);

    this.ngZone.runOutsideAngular(() => {
      this.readEventsReqSubscription.push(this.readAllEvents(partitionIds).subscribe(eventsPerBuilding => {
        const eventsRead = this.flattenEvents(eventsPerBuilding);
        const closedEvents = this.evaluateClosedEvents(eventsRead);
        this.notifyClosedEvents(closedEvents);
        this._eventsBuildingX.next(eventsPerBuilding);
        const eventsTotal = eventsRead.length;
        let eventsCount = 0;
        this.traceService.info(TraceModules.events,
          `EventBxSubstituteProxyService.readAndResolveEventsAndNotifyInBulk(): number of events: ${eventsTotal}; closed events: ${closedEvents.length}`);

        const wsiEventsToNotify: WSIEvent[] = [];
        const resolveEntitiesObs: Observable<Page>[] = [];
        eventsPerBuilding.forEach(eventsOfBuilding => {
          if (eventsOfBuilding.eventResponse !== undefined) {
            eventsOfBuilding.eventResponse.forEach(event => {
              let sourceId = event.source?.id;
              let sourceIsPoint = true;
              if (sourceId === undefined) {
                sourceId = event.deviceId;
                sourceIsPoint = false;
              }
              if (!this.sourceFailedToBeResolved.has(sourceId)) {
                if (sourceIsPoint) {
                  resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolvePoint(
                    sourceId,
                    eventsOfBuilding.partitionId).pipe(
                    concatMap(page => {
                      return (this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(event.siteId, eventsOfBuilding.partitionId).pipe(
                        tap(pageLocation => {
                          const wsiEvent = this.createEvent(page, sourceId, event, pageLocation);
                          wsiEventsToNotify.push(wsiEvent);
                          eventsCount++;
                          this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                        })
                      );
                    })
                  ));
                } else {
                  resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveDevice(
                    sourceId,
                    eventsOfBuilding.partitionId).pipe(
                    concatMap(page => {
                      return (this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(event.siteId, eventsOfBuilding.partitionId).pipe(
                        tap(pageLocation => {
                          const wsiEvent = this.createEvent(page, sourceId, event, pageLocation);
                          wsiEventsToNotify.push(wsiEvent);
                          eventsCount++;
                          this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                        })
                      );
                    })
                  ));
                }
              } else {
                // notify event without source info
                resolveEntitiesObs.push((this.systemBrowserOmService as SystemBrowserBxSubstituteService).resolveLocation(
                  event.siteId,
                  eventsOfBuilding.partitionId).pipe(
                  tap(pageLocation => {
                    const wsiEvent = this.createEventWoSource(event, pageLocation);
                    wsiEventsToNotify.push(wsiEvent);
                    eventsCount++;
                    this.doEventsBookkeeping(wsiEvent, eventsOfBuilding.partitionId, eventsCount, eventsTotal, startTime);
                  })
                ));
              }
            });
          }
        });

        if (resolveEntitiesObs.length > 0) {
          this.resolveObjReqSubscription.push(zip(resolveEntitiesObs).subscribe(_result => {
            this.notifyEvents(wsiEventsToNotify);
            this.traceUnresolvedSourceObjects();
          }));
        } else {
          this.notifyEvents(wsiEventsToNotify);
          this.traceUnresolvedSourceObjects();
        }
      }));
    });
  }

  private createEvent(pageSource: Page, sourceId: string, event: EventBx, pageLocation: Page): WSIEvent {
    if (pageSource.Nodes.length === 0) {
      // remember the id, do not try to resolve again later!
      this.sourceFailedToBeResolved.set(sourceId, sourceId);
    }
    const foundSource = (pageSource.Nodes.length >= 1) ? pageSource.Nodes[0] : undefined;
    const foundLocation = (pageLocation?.Nodes.length >= 1) ? pageLocation.Nodes[0] : undefined;
    return this.eventMapper.createEvent(event, foundSource, foundLocation);
  }

  private createEventWoSource(event: EventBx, pageLocation: Page): WSIEvent {
    const foundLocation = (pageLocation?.Nodes.length >= 1) ? pageLocation.Nodes[0] : undefined;
    return this.eventMapper.createEvent(event, undefined, foundLocation);
  }

  private traceUnresolvedSourceObjects(): void {
    if (this.sourceFailedToBeResolved.size > 0) {
      this.traceService.warn(TraceModules.events,
        `Events could not be resolved to source object. No of unresolved objects: ${this.sourceFailedToBeResolved.size}`);
    }
  }

  private notifyEvents(events: WSIEvent[]): void {
    if (events.length > 0) {
      this._eventsWsi.next(events);
    }
  }

  private notifyClosedEvents(events: WSIEvent[]): void {
    if (events.length > 0) {
      events.forEach(event => {
        event.State = EventStateGms.Closed;
        this.eventsPerId.delete(event.Id);
        this.partitionIdPerEventId.delete(event.Id);
      });
      this._eventsWsi.next(events);
    }
  }

  private doEventsBookkeeping(currentEvent: WSIEvent, partitionId: string, countCurrent: number, countTotal: number, startTime: number): void {
    this.eventsPerId.set(currentEvent.Id, currentEvent);
    this.partitionIdPerEventId.set(currentEvent.Id, partitionId)
    if (countCurrent >= countTotal) {
      this.traceService.info(TraceModules.events,
        `EventProxyBxSubstituteService.readAndResolveEvents() done; total time used: ${performance.now() - startTime} ms`);
    }
  }

  private evaluateClosedEvents(eventsRead: EventBx[]): WSIEvent[] {
    const eventsReadMap: Map<string, EventBx> = new Map<string, EventBx>();
    eventsRead.forEach(event => eventsReadMap.set(event.eventId, event));
    const closedEvents: WSIEvent[] = [];
    this.eventsPerId.forEach((event, id) => {
      if (!eventsReadMap.has(id)) {
        closedEvents.push(event);
      }
    });
    return closedEvents;
  }

  private flattenEvents(eventsPerBuilding: EventResponseOfBuilding[]): EventBx[] {
    return eventsPerBuilding.map(eventsOfBuilding => eventsOfBuilding.eventResponse.map(event => event)).flat();
  }

  private startTimerForSubscription(delay: number, cancelCalls: boolean): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    if (cancelCalls) {
      this.readEventsReqSubscription.forEach(sub => sub?.unsubscribe());
      this.readEventsReqSubscription = [];
      this.resolveObjReqSubscription.forEach(sub => sub?.unsubscribe());
      this.resolveObjReqSubscription = [];
    }
    this.timerSubscription = timer(delay).subscribe(count => this.onTimerSubscription());
  }

  private stopTimerForSubscription(): void {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = undefined;
    this.readEventsReqSubscription.forEach(sub => sub?.unsubscribe());
    this.readEventsReqSubscription = [];
    this.resolveObjReqSubscription.forEach(sub => sub?.unsubscribe());
    this.resolveObjReqSubscription = [];
  }
}
