import { InjectionToken } from "@angular/core";

/**
 * `APP_CONFIGURATION_TOKEN` is an Angular Injection Token used to provide application-wide configuration settings.
 * This allows for flexible dependency injection of the `AppConfiguration` object, enabling easy customization
 * and testability for different applications.
 */
export const APP_CONFIGURATION_TOKEN = new InjectionToken<AppConfiguration>('application-configuration');

/**
 * `AppConfiguration` is an interface that defines the structure of configuration settings
 * for the application. Each property controls specific features and their availability.
 */
export interface AppConfiguration {
  /**
   * `aliasSupported`:
   * Determines if aliasing is supported within the application.
   * This feature might be used to display alternative names or descriptions for entities.
   */
  aliasSupported: boolean;

  /**
   * `notificationSupported`:
   * Determines if notifications are enabled on the navigation bar.
   * When `true`, the user can receive and interact with notifications directly from the navigation bar.
   */
  notificationSupported: boolean;

  /**
   * `appSwitcherSupported`:
   * Determines if the app switcher feature is activated.
   * When `true`, users can switch between available applications using the app switcher located at the top left corner.
   */
  appSwitcherSupported: boolean;

  /**
   * `partitionSelectionSupported`:
   * Determines if the BX company switcher menu item in dropdown menu is shown.
   * When `true`, users can select and switch between different companies from a dropdown menu.
   */
  partitionSelectionSupported: boolean;

  /**
   * `displayPathWithoutSystemView`:
   * Controls whether the application displays paths without including the system view.
   * This property does exactly what its name suggests.
   */
  displayPathWithoutSystemView: boolean;
}
