import { Injectable } from '@angular/core';
import { AppRightsService } from '@gms-flex/services';
import { TraceService } from '@gms-flex/services-common';

import { SchedulePreselectService, traceModule } from '../schedule-preselect.service';

@Injectable()
export class CBmsSchedulePreselectService extends SchedulePreselectService {

  public typeId = 'CBmsScheduleSnapInType';
  constructor(traceService: TraceService, appRightService: AppRightsService) {
    if (traceService) {
      traceService.info(traceModule, 'CBMS SchedulePreselectService created');
    }
    super(traceService, appRightService);
  }
}
