/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */

/* eslint-disable */
export type sourceBase = {
    /**
     * Defines the namespace of the schedule. Schedules for devices behind gateway must use the app namespace.
     */
    namespaceType: sourceBase.namespaceType;
    /**
     * The UUID of the namespace. For the namespace-type device this is the device ID and for the namespace-type app this is the application instance ID
     */
    namespace: string;
    /**
     * The local device reference, "owning" the schedule. If the schedule is on the gateway, this is nil (empty or not present). A unique id (within this namespace) of the device hosting this schedule.
     */
    localDeviceRef: string;
};
export namespace sourceBase {
    /**
     * Defines the namespace of the schedule. Schedules for devices behind gateway must use the app namespace.
     */
    export enum namespaceType {
        DEVICE = 'device',
        APP = 'app',
    }
}

