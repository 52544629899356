import { Injectable } from '@angular/core';
import { TraceService } from '@gms-flex/services-common';

import { traceModule } from '../schedule-preselect.service';
import { ScheduleStorageService } from '../schedule-storage.service';

@Injectable()
export class CBmsScheduleStorageService extends ScheduleStorageService {

  public typeId = 'CBmsScheduleSnapInType';

  constructor(traceService: TraceService) {    
    traceService.info(traceModule, 'CBmsScheduleStorageService created.');
    super(traceService);
  }

}
